import { firestore } from "../firebase";

const fetchCollection = (collection, collectionID) => {
  if (!Array.isArray(collection)) return Promise.resolve([]);
  else
    return Promise.all(
      collection.map((id) =>
        firestore
          .collection("colecciones")
          .doc("admin")
          .collection(collectionID)
          .doc(id)
          .get()
          .then((doc) =>
            doc.exists
              ? { id: doc.id, ...doc.data(), fromCollection: collectionID }
              : null
          )
      )
    ).then((vals) => vals.filter((x) => x));
};

export default fetchCollection;
