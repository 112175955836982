import React, { useState } from "react";
import { firestore } from "../../../firebase";
import { PagesContext } from "../context";

const PagesState = ({ children }) => {
  const [
    { home, mba, cursos, diplomados, profesores, contacto, footer, eventos },
    setPage,
  ] = useState({
    home: {
      id: "",
      mbas: [],
      categorias: [],
      profesores: [],
      testimonios: [{ id: "", nombre: "", profesion: "", testimonio: "" }],
    },
    mba: {
      id: "",
      titulo: "",
      descripcion: "",
      profesores: [],
      mbas: [],
    },
    cursos: {
      id: "",
      titulo: "",
      descripcion: "",
      seccionCursos: {
        titulo: "",
        descripcion: "",
      },
      cursos: [],
      seccionTalleres: {
        titulo: "",
        descripcion: "",
      },
      talleres: [],
    },
    diplomados: {
      id: "",
      titulo: "",
      descripcion: "",
      seccionDiplomados: {
        titulo: "",
        descripcion: "",
      },
      seccionCategorias: {
        titulo: "",
        descripcion: "",
      },
      profesores: [],
    },
    profesores: {
      id: "",
    },
    contacto: {
      id: "",
      email: "",
      telefono: "",
      whatsapp: "",
      maps: "",
      link: "",
    },
    footer: {
      id: "",
      facebook: "",
      instagram: "",
      whatsapp: "",
    },
    eventos: {
      coleccion: [],
    },
  });

  const [fetchTriggered, setFetchTriggered] = useState(false);
  const [fetches, setFetches] = useState({
    home: false,
    mba: false,
    profesores: false,
    diplomados: false,
    cursos: false,
    contacto: false,
    footer: false,
    eventos: false,
  });

  const fetchPage = (page) => {
    firestore
      .collection("paginas")
      .doc(page)
      .onSnapshot((doc) => {
        setPage((state) => {
          return {
            ...state,
            [page]: { id: doc.id, ...doc.data() },
          };
        });
        setFetchTriggered(() => true);
      });

    setFetches((state) => ({
      ...state,
      [page]: true,
    }));
  };

  return (
    <PagesContext.Provider
      value={{
        fetchPage,
        home: {
          ...home,
          initialFetch: fetches.home,
        },
        mba: {
          ...mba,
          initialFetch: fetches.mba,
        },
        profesores: {
          ...profesores,
          initialFetch: fetches.profesores,
        },
        diplomados: {
          ...diplomados,
          initialFetch: fetches.diplomados,
        },
        cursos: {
          ...cursos,
          initialFetch: fetches.cursos,
        },
        contacto: {
          ...contacto,
          initialFetch: fetches.contacto,
        },
        footer: {
          ...footer,
          initialFetch: fetches.footer,
        },
        eventos: {
          ...eventos,
          initialFetch: fetches.eventos,
        },
        fetchTriggered,
        setFetchTriggered,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};

export default PagesState;
