export const mContacto = ({ id, email, telefono, whatsapp, maps, link }) => ({
  id,
  email,
  telefono,
  whatsapp,
  maps,
  link,
});

mContacto.toDB = ({ email, telefono, whatsapp, maps, link }) => ({
  email,
  telefono,
  whatsapp,
  maps,
  link,
});
