import React from "react";

import ManejarCambios from "./ManejarCambios";
import BotonAgregar from "./BotonAgregar";

import trash from "../../assets/svg/trash.svg";
import downArrow from "../../assets/svg/down-arrow.svg";

const CollectionListSelect = ({
  title,
  disableSave,
  onChange,
  onDelete,
  onSaveChanges,
  onDiscardChanges,
  onClickAddItem,
  options,
  values,
  optionValueFn = () => null,
  optionDisplayFn,
  className,
}) => {
  return (
    <section className={`collection-list ${className}`}>
      <span className="collection-list__title headline3">{title}</span>
      <div className="collection-list__list">
        {values.map((value, index) => (
          <div key={index} className="collection-list__item">
            <div className="collection-list__select-wrapper">
              <select value={value} onChange={(e) => onChange(e, index)}>
                <option value="">Escoge una opcion...</option>
                {options.map((data, index) => (
                  <option value={optionValueFn(data) || index} key={index}>
                    {optionDisplayFn(data)}
                  </option>
                ))}
              </select>
              <img
                className="input-group__img input-group--bote"
                src={downArrow}
                alt="Flecha abajo"
              />
            </div>
            <img
              onClick={(e) => onDelete(e, index)}
              className="input-group__img input-group--bote"
              src={trash}
              alt="Bote de basura"
            />
          </div>
        ))}
      </div>
      <ManejarCambios
        disableSave={disableSave}
        saveClick={onSaveChanges}
        discardClick={onDiscardChanges}
        noMarginBottom
      />
      <BotonAgregar
        disableSave={disableSave}
        onClick={onClickAddItem}
        className="collection-list__add"
      />
    </section>
  );
};

export default CollectionListSelect;
