import { useQuery } from "react-query";
import { firestore } from "../../firebase";
import fetchCollection from "../../Utils/fetchCollection";

const useDiplomado = (key) => {
  return useQuery(["getDiplomado", key], () =>
    firestore
      .collection("colecciones")
      .doc("admin")
      .collection("diplomadosCollection")
      .where("path", "==", key)
      .get()
      .then(async (docs) => {
        const diplomados = [];
        docs.forEach((d) => diplomados.push({ id: d.id, ...d.data() }));
        if (!diplomados[0]) return { notFound: true };

        const docData = diplomados[0];
        const profesores = await fetchCollection(
          docData.profesores,
          "profesores"
        );
        const otrosDiplomados = await fetchCollection(
          docData.otros.coleccion,
          "diplomadosCollection"
        );
        return { ...docData, profesores, otrosDiplomados };
      })
  );
};

export default useDiplomado;
