import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import useInputChange from "../lib/hooks/useInputChange";
import { auth } from "../../firebase";
import { useAuth } from "../context/context";

import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Cross } from "./cross.svg";

import "./Login.scss";

const Login = () => {
  const { setAuthState } = useAuth();
  const [{ email, password }, setAuthForm] = useState({
    email: "",
    password: "",
  });
  const [recoveryState, setRecoveryForm] = useState({ email: "" });
  const [isRecovery, setRecovery] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { addToast } = useToasts();
  const onChangeAuth = useInputChange(setAuthForm);
  const onChangeRecovery = useInputChange(setRecoveryForm);

  const onSubmitAuth = (e) => {
    e.preventDefault();
    setFetching(() => true);

    auth
      .signInWithEmailAndPassword(email, password)
      .then((success) => {
        addToast("Sesión Iniciada", { appearance: "success" });
        setAuthState((state) => ({ ...state, isAuthenticated: true, email }));
      })
      .catch((e) => {
        if (e?.code === "auth/wrong-password") {
          addToast("Contraseña incorrecta.", { appearance: "error" });
        }
        if (e?.code === "auth/user-not-found") {
          addToast(
            "Esta cuenta no existe. Por favor, verifica tus datos o regístrate.",
            { appearance: "error" }
          );
        }
        setFetching(() => false);
      });
  };

  const onSubmitRecovery = (e) => {
    e.preventDefault();
    setFetching(() => true);

    auth
      .sendPasswordResetEmail(recoveryState.email)
      .then(() => {
        addToast("Correo enviado", { appearance: "success" });
        setFetching(() => false);
      })
      .catch(() => {
        addToast("Error al enviar correo, verifique e intente nuevamente", {
          appearance: "error",
        });
        setFetching(() => false);
      });
  };

  return (
    <main className="admin-login">
      <section className="admin-login__container">
        <Logo />
        <div className="admin-login__form">
          {isRecovery ? (
            <form
              className="admin-login__authForm m-0"
              onSubmit={onSubmitRecovery}
            >
              <span className="admin-login__title">
                Recuperación de contraseña
              </span>
              <span className="admin-login__subtitle">
                Te enviaremos un correo electrónico de recuperación
              </span>
              <label htmlFor="email">Correo electrónico:</label>
              <input
                type="text"
                name="email"
                value={recoveryState.email}
                onChange={onChangeRecovery}
              />
              <button disabled={fetching} type="submit">
                Recuperar
              </button>
              <Cross
                onClick={(e) => {
                  e.persist();
                  setRecovery(() => false);
                }}
              />
            </form>
          ) : (
            <>
              <form className="admin-login__authForm" onSubmit={onSubmitAuth}>
                <label htmlFor="email">Correo Electrónico:</label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={onChangeAuth}
                />
                <label htmlFor="password">Contraseña:</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChangeAuth}
                />
                <button type="submit">LOG IN</button>
              </form>
              <button
                onClick={(e) => {
                  e.persist();
                  setRecovery(() => true);
                }}
                className="admin-login__link"
              >
                Recuperar contraseña
              </button>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default Login;
