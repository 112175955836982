import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";

import {
  LineBreak,
  CollectionListSelect,
  PageCollectionList,
  CollectionTitle,
  BotonAgregar,
} from "../../components/admin/misc";
import { useCollection, usePages } from "../context/context";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import DiplomadosService from "../lib/services/diplomados.service";
import useInputChange from "../lib/hooks/useInputChange";
import CategoriasCard from "../AdminHome/CategoriasCard";

import "./AdminDiplomados.scss";

const AdminDiplomados = () => {
  const {
    diplomados,
    fetchPage,
    fetchTriggered,
    setFetchTriggered,
  } = usePages();
  const {
    profesores,
    diplomadosCollection,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  } = useCollection();
  const [stateDiplomados, setStateDiplomados] = useState(
    _.cloneDeep(diplomados)
  );
  const [isFetching, setIsFetching] = useState(false);
  const diplomadosService = useMemo(() => new DiplomadosService(), []);
  const { addToast } = useToasts();
  const diplomadosPromiseHandler = usePromiseHandler(
    "Diplomado",
    setIsFetching
  );
  const pagePromiseHandler = usePromiseHandler("Página", setIsFetching);
  const categoriaPromiseHandler = usePromiseHandler("Categoría", setIsFetching);
  const profesorPromiseHandler = usePromiseHandler("Profesores", setIsFetching);
  const inputChangeHandler = useInputChange(setStateDiplomados);

  const onChangeDiplomado = (e, index) => {
    e.persist();
    return setStateDiplomados((state) => {
      const newDiplomados = _.cloneDeep(state.diplomados);
      newDiplomados[index].nombre = e.target.value;
      newDiplomados[index].path = e.target.value
        .normalize("NFD")
        .replace(/\s/g, "-")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      return { ...state, diplomados: newDiplomados };
    });
  };

  const onDeleteDiplomado = (e, index) => {
    e.persist();
    const newDiplomados = [...stateDiplomados.diplomados];
    const diplomadosToDelete = { ...newDiplomados[index] };
    newDiplomados.splice(index, 1);
    diplomadosPromiseHandler(
      diplomadosService.onDeleteDiplomado(newDiplomados, diplomadosToDelete.fk),
      "eliminado"
    );
  };

  const onSaveDiplomado = (e, index) => {
    e.persist();
    const collectionID = stateDiplomados.diplomados[index].fk;
    const newPath = stateDiplomados.diplomados[index].path;
    const newShortName = stateDiplomados.diplomados[index].nombre;
    diplomadosPromiseHandler(
      diplomadosService.updatePageDiplomados(
        stateDiplomados.diplomados,
        collectionID,
        newPath,
        newShortName
      ),
      "actualizado"
    );
  };

  const onCreateDiplomado = (e) => {
    e.persist();
    diplomadosPromiseHandler(
      diplomadosService.createDiplomado(stateDiplomados.diplomados),
      "creado"
    );
  };

  const onDiscardDiplomado = (e, index) => {
    e.persist();
    setStateDiplomados((state) => {
      const newDiplomados = [...state.diplomados];
      newDiplomados[index] = _.cloneDeep(diplomados.diplomados[index]);
      return { ...state, diplomados: newDiplomados };
    });
  };

  const onAddProfesor = (e) => {
    e.persist();

    if (stateDiplomados.profesores.length === 3) {
      return addToast("Solo se permiten tres profesores en esta página", {
        appearance: "warning",
      });
    }
    setStateDiplomados((state) => ({
      ...state,
      profesores: [...state.profesores, ""],
    }));
  };

  const onChangeProfesor = (e, index) => {
    e.persist();
    setStateDiplomados((state) => {
      const newProfesores = [...state.profesores];
      newProfesores[index] = e.target.value;
      return { ...state, profesores: newProfesores };
    });
  };

  const onDeleteProfesor = (e, index) => {
    e.persist();
    setStateDiplomados((state) => {
      const newProfesores = [...state.profesores];
      newProfesores.splice(index, 1);
      return { ...state, profesores: newProfesores };
    });
  };

  const onDiscardProfesor = (e) => {
    e.persist();
    setStateDiplomados((state) => ({
      ...state,
      profesores: diplomados.profesores,
    }));
  };

  const onSaveProfesor = (e) => {
    e.persist();
    const newProfesores = _.cloneDeep(stateDiplomados.profesores);
    profesorPromiseHandler(
      diplomadosService.updatePageSection({ profesores: newProfesores }),
      "actualizado"
    );
  };

  const onSaveHeaderHandler = (e) => {
    e.persist();
    diplomadosPromiseHandler(
      diplomadosService.updatePageSection({
        titulo: stateDiplomados.titulo,
        descripcion: stateDiplomados.descripcion,
      }),
      "actualizado"
    );
  };

  const onChangeHeaderHandler = (e) => {
    e.persist();
    inputChangeHandler(e);
  };

  const onDiscardHeaderHandler = (e) => {
    e.persist();
    setStateDiplomados((state) => ({
      ...state,
      titulo: diplomados.titulo,
      descripcion: diplomados.descripcion,
    }));
  };

  const onSaveSeccionDiplomados = (e) => {
    e.persist();
    diplomadosPromiseHandler(
      diplomadosService.updatePageSection({
        seccionDiplomados: stateDiplomados.seccionDiplomados,
      }),
      "actualizado"
    );
  };

  const onChangeSeccionDiplomados = (e) => {
    e.persist();
    setStateDiplomados((state) => ({
      ...state,
      seccionDiplomados: {
        ...state.seccionDiplomados,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const onDiscardSeccionDiplomados = (e) => {
    e.persist();
    setStateDiplomados((state) => ({
      ...state,
      seccionDiplomados: {
        ...diplomados.seccionDiplomados,
      },
    }));
  };

  const onSaveSeccionCategorias = (e) => {
    e.persist();
    diplomadosPromiseHandler(
      diplomadosService.updatePageSection({
        seccionCategorias: stateDiplomados.seccionCategorias,
      }),
      "actualizado"
    );
  };

  const onChangeSeccionCategorias = (e) => {
    e.persist();
    setStateDiplomados((state) => ({
      ...state,
      seccionCategorias: {
        ...state.seccionCategorias,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const onDiscardSeccionCategorias = (e) => {
    e.persist();
    setStateDiplomados((state) => ({
      ...state,
      seccionCategorias: {
        ...diplomados.seccionCategorias,
      },
    }));
  };

  const onAddCategoria = (e) => {
    e.persist();

    categoriaPromiseHandler(
      diplomadosService.updatePageSection({
        categorias: [...stateDiplomados.categorias, { nombre: "", imagen: "" }],
      }),
      "creada"
    );
  };

  const onDeleteCategoria = (index) => {
    const newCategorias = [...stateDiplomados.categorias];
    newCategorias.splice(index, 1);

    categoriaPromiseHandler(
      diplomadosService.updatePageSection({ categorias: newCategorias }),
      "eliminada"
    );
  };

  const onDiscardCategoria = (e, index) => {
    e.persist();
    const oldCategorias = [...diplomados.categorias];
    const newCategorias = [...stateDiplomados.categorias];
    newCategorias[index] = _.cloneDeep(oldCategorias[index]);

    setStateDiplomados((state) => ({ ...state, categorias: newCategorias }));
  };

  const onSaveCategoria = (index, categoria) => {
    const newCategorias = [...stateDiplomados.categorias];
    newCategorias[index] = categoria;
    categoriaPromiseHandler(
      diplomadosService.updatePageSection({ categorias: newCategorias }),
      "actualizada"
    );
  };

  useEffect(() => {
    console.log("object");
    if (!diplomadosCollection.initialFetch) {
      fetchCollection("diplomadosCollection");
    }
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
    if (!diplomados.initialFetch) {
      fetchPage("diplomados");
    }
    if (fetchTriggered || fetchCollectionTriggered) {
      setStateDiplomados(() => _.cloneDeep(diplomados));
      setFetchTriggered(() => false);
      setFetchCollectionTriggered(() => false);
    }
  }, [
    diplomadosCollection.initialFetch,
    profesores.initialFetch,
    diplomados.initialFetch,
    diplomados,
    setStateDiplomados,
    fetchPage,
    fetchCollection,
    fetchTriggered,
    setFetchTriggered,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <section id="admin-diplomados" className="admin-diplomados">
      <h2 className="admin-diplomados__titulo headline3">Diplomados</h2>
      <div className="admin-diplomados__content">
        <CollectionTitle
          data={{
            titulo: stateDiplomados.titulo,
            descripcion: stateDiplomados.descripcion,
          }}
          onSave={onSaveHeaderHandler}
          onDiscardChanges={onDiscardHeaderHandler}
          disableSave={isFetching}
          onChangeInput={onChangeHeaderHandler}
          promiseHandler={pagePromiseHandler}
        />
        <LineBreak />
        <span className="admin-diplomados__subtitulo headline3">
          Diplomados
        </span>
        <CollectionTitle
          data={{
            titulo: stateDiplomados.seccionDiplomados.titulo,
            descripcion: stateDiplomados.seccionDiplomados.descripcion,
          }}
          disableSave={isFetching}
          onSave={onSaveSeccionDiplomados}
          onDiscardChanges={onDiscardSeccionDiplomados}
          onChangeInput={onChangeSeccionDiplomados}
          promiseHandler={diplomadosPromiseHandler}
        />
        <PageCollectionList
          disableSave={isFetching}
          items={stateDiplomados.diplomados || []}
          getValueFn={(x) => x.nombre}
          routerPathFn={(x) => `/admin/diplomados/${x.path}`}
          routerPathError={(x) =>
            diplomados.diplomados.find((y) => y.id === x.id)?.path === ""
          }
          onChangeValue={onChangeDiplomado}
          onDeleteItem={onDeleteDiplomado}
          onClickAddItem={onCreateDiplomado}
          onSaveItem={onSaveDiplomado}
          onDiscardItem={onDiscardDiplomado}
        />
        <LineBreak />
        <span className="admin-diplomados__subtitulo headline3">
          Categorías
        </span>
        <CollectionTitle
          data={{
            titulo: stateDiplomados.seccionCategorias.titulo,
            descripcion: stateDiplomados.seccionCategorias.descripcion,
          }}
          onSave={onSaveSeccionCategorias}
          onDiscardChanges={onDiscardSeccionCategorias}
          disableSave={isFetching}
          onChangeInput={onChangeSeccionCategorias}
          promiseHandler={categoriaPromiseHandler}
        />
        <section className="admin-diplomados__categorias">
          <span className="admin-diplomados__categorias--title headline3">
            Categorías
          </span>
          {stateDiplomados?.categorias?.map((data, index) => (
            <CategoriasCard
              key={index}
              id={index}
              data={data}
              storageCollection="categoriasDiplomados"
              disableSave={isFetching}
              onDiscard={onDiscardCategoria}
              onDelete={onDeleteCategoria}
              onSave={onSaveCategoria}
            />
          ))}
          <BotonAgregar
            onClick={onAddCategoria}
            className="admin-diplomados__categorias--add"
          />
        </section>
        <LineBreak />
        <CollectionListSelect
          disableSave={isFetching}
          title="Profesores"
          onClickAddItem={onAddProfesor}
          onChange={onChangeProfesor}
          onDelete={onDeleteProfesor}
          onSaveChanges={onSaveProfesor}
          onDiscardChanges={onDiscardProfesor}
          values={stateDiplomados.profesores}
          options={profesores.collection}
          optionValueFn={(x) => x.id}
          optionDisplayFn={(x) => x.nombre}
        />
      </div>
    </section>
  );
};
export default AdminDiplomados;
