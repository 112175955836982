import { useQuery } from "react-query";
import { firestore } from "../../firebase";
import fetchCollection from "../../Utils/fetchCollection";

export const useDiplomados = () => {
  return useQuery(
    "getDiplomados",
    () =>
      firestore
        .collection("paginas")
        .doc("diplomados")
        .get()
        .then(async (doc) => {
          const docData = { id: doc.id, ...doc.data() };
          const profesores = await fetchCollection(
            docData.profesores,
            "profesores"
          );

          return { ...docData, profesores };
        }),
    {
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
    }
  );
};
