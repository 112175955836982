export const mMBA = ({
  path,
  nombre,
  intro,
  nombreCorto,
  duracion,
  modalidad,
  horario,
  inicio,
  enfoques,
  profesores,
  otros,
  date,
}) => ({
  path,
  nombre,
  intro,
  duracion,
  modalidad,
  horario,
  nombreCorto,
  inicio,
  enfoques,
  profesores,
  otros,
  date,
});

mMBA.header = ({ nombre, intro, duracion, modalidad, horario, inicio }) => ({
  nombre,
  intro,
  duracion,
  modalidad,
  horario,
  inicio,
});

mMBA.initEnfoque = () => ({
  subtitulo: "",
  lista: [],
});

mMBA.init = () => ({
  path: "",
  nombre: "",
  intro: "",
  nombreCorto: "",
  duracion: "",
  modalidad: "",
  horario: "",
  inicio: "",
  enfoques: { titulo: "", coleccion: [] },
  profesores: [],
  otros: { titulo: "", descripcion: "", coleccion: [] },
  date: new Date(),
});

mMBA.page = ({ titulo, descripcion, profesores, mbas }) => ({
  titulo,
  descripcion,
  profesores,
  mbas,
});
