import React from "react";

import Footer from "../../components/Footer/Footer";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import GenericLinks from "../../components/GenericLinkList/GenericLinks";
import GenericTenemos from "../../components/GenericTenemos/GenericTenemos";
import Header from "../../components/Header/Header";

import "./Cursos.scss";

const Cursos = ({ data }) => {
  return (
    <main className="cursos">
      <Header />
      <GenericHeader
        title={data?.titulo}
        description={data?.descripcion}
        type={"cursos"}
      />
      <GenericLinks
        title={data?.seccionCursos?.titulo}
        subtitle={data?.seccionCursos?.descripcion}
        route="/cursos"
        list={data?.cursos}
      />
      <GenericTenemos
        categorias={data?.talleres}
        categoriasTitle={data?.seccionTalleres?.titulo}
        categoriasSubtitle={data?.seccionTalleres?.descripcion}
        routerFn={(x) => ({
          pathname: `/talleres/${x?.path}`,
          state: { fk: x?.fk },
        })}
      />
      <Footer />
    </main>
  );
};

export default Cursos;
