import { useQuery } from "react-query";
import { firestore } from "../../firebase";

const useContacto = () => {
  return useQuery("getContacto", () =>
    firestore
      .collection("paginas")
      .doc("contacto")
      .get()
      .then((doc) => doc.data())
  );
};

export default useContacto;
