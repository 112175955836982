import { NavLink, useHistory } from "react-router-dom";
import React from "react";
import "animate.css/animate.min.css";

import useFooter from "../../pages/hooks/useFooter";

import logo from "../../assets/svg/logo-inQba-admin.svg";
import fbLogo from "../../assets/svg/fb-logo-footer.svg";
import waLogo from "../../assets/svg/wa-logo-footer.svg";
import linkedin from "../../assets/svg/linkedin.svg";
import instaLogo from "../../assets/svg/insta-logo-footer.svg";
import derechos from "../../assets/svg/derechos-reservados.svg";
import developed from "../../assets/svg/developed-by.svg";
import logos from "../../assets/svg/logos-juntos-footer.svg";
import aviso from "../../assets/svg/aviso-de-privacidad.svg";

import "./Footer.scss";
import cleanURL from "../../Utils/cleanURL";

const Footer = ({ showBanner = true }) => {
  const { data } = useFooter();
  const history = useHistory();

  const navigateHome = () => history.push("/contacto");

  return (
    <div className="footer">
      {showBanner && (
        <div className="footer-hero">
          <h2 className="footer-hero__h2">
            ¿Quieres llevar tu empresa a otro nivel ?
          </h2>
          <span
            onClick={navigateHome}
            className="footer-hero__span animate__animated animate__bounce"
          >
            Inscríbete ahora
          </span>
        </div>
      )}
      <footer className="footer-insite">
        <div className="footer-insite__contenedor-uno">
          <img
            src={logo}
            alt="Logo inQba"
            className="footer-insite__contenedor-uno__inqba-logo"
          />
          <div className="footer-insite__contenedor-uno__hr"></div>
        </div>
        <div className="footer-insite__contenedor-dos">
          <ul className="footer-insite__contenedor-dos__list">
            <li className="footer-insite__contenedor-dos__list-element">
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="footer-insite__contenedor-dos__list-element">
              <NavLink to="/diplomados">Diplomados</NavLink>
            </li>
            <li className="footer-insite__contenedor-dos__list-element">
              <NavLink to="/cursos">InCompany</NavLink>
            </li>
            <li className="footer-insite__contenedor-dos__list-element">
              <NavLink to="/posgrados">Posgrados</NavLink>
            </li>
            <li className="footer-insite__contenedor-dos__list-element">
              <NavLink to="/profesores">Profesores</NavLink>
            </li>
            <li className="footer-insite__contenedor-dos__list-element">
              <NavLink to="/contacto">Contacto</NavLink>
            </li>
          </ul>
          <div className="footer-insite__contenedor-dos__redes">
            <a
              href={`https://${cleanURL(data?.facebook)}`}
              target="__blank"
              noopener="true"
              noreferrer="true"
            >
              <img
                src={fbLogo}
                alt="Logo Facebook"
                className="footer-insite__contenedor-dos__redes-facebook"
              />
            </a>
            <a
              href={`https://${cleanURL(data?.instagram)}`}
              target="__blank"
              noopener="true"
              noreferrer="true"
            >
              <img
                src={instaLogo}
                alt="Logo Instagram"
                className="footer-insite__contenedor-dos__redes-instagram"
              />
            </a>
            <a
              href={`https://wa.me/${data?.whatsapp}`}
              target="__blank"
              noopener="true"
              noreferrer="true"
            >
              <img
                src={waLogo}
                alt="Logo Whatsapp"
                className="footer-insite__contenedor-dos__redes-instagram"
              />
            </a>
            <a
              href="https://www.linkedin.com/school/in.q.ba-escuela-de-negocios/"
              target="__blank"
              noopener="true"
              noreferrer="true"
            >
              <img
                src={linkedin}
                alt="Logo Linkedin"
                className="footer-insite__contenedor-dos__redes-instagram"
              />
            </a>
          </div>
        </div>
      </footer>
      <footer className="footer-info">
        <div className="footer-info__contuno">
          <img
            src={derechos}
            alt="Derechos reservados"
            className="footer-info__contuno__derechos"
          />
          <div className="footer-info__contuno__devby">
            <img
              src={developed}
              alt="Developed by"
              className="footer-info__contuno__devby__developed"
            />
            <NavLink to="/">
              <img
                src={logos}
                alt="Logo Abstracto"
                className="footer-info__contuno__devby__logos"
              />
            </NavLink>
          </div>
        </div>
        <a
          className="footer-info__aviso"
          target="_blank"
          rel="noreferrer"
          href="https://inqba.edu.mx/PDF/aviso_privacidad.pdf"
        >
          <img src={aviso} alt="Aviso de privacidad" />
        </a>
      </footer>
    </div>
  );
};

export default Footer;
