import React from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import FlipCardProfesor from "../../components/FlipCardProfesor/FlipCardProfesor";

import "./Profesores.scss";

const Profesores = ({ data }) => {
  const isLargerThanMobile = window.innerWidth >= 767;

  return (
    <div>
      <Header fondoTransparente={false} />
      <div className="hero">
        <h1 className="hero__h1 headline1 white">Profesores</h1>
        <p className="hero__p subtitle1 white">
          Contamos con los mejores profesores a nivel internacional que te harán
          llegar al siguiente nivel. ¡Conócelos!
        </p>
        <div className="hero__imagen"></div>
      </div>
      <div className="contenedor-profesores flex-column">
        <div className="contenedor-profesores__curva-verde"></div>
        <div className="contenedor-profesores__curva-gris"></div>
        {data?.map((profesor) => (
          <FlipCardProfesor key={profesor.nombre} profesor={profesor} />
        ))}
      </div>
      <Footer showBanner={isLargerThanMobile} />
    </div>
  );
};

export default Profesores;
