import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import "./Header.scss";

import logo from "../../assets/svg/logo-inQba-admin.svg";
import iconoMenu from "../../assets/svg/menu-hamburguesa.svg";
import iconoCerrar from "../../assets/svg/cerrar.svg";
import { LisHeaderHorizontal, LisHeaderVertical } from "./LiHeader";
import useAllQueryData from "../../Utils/useAllQueryData";

const Header = ({ fondoTransparente = true }) => {
  const [menuAbierto, setMenuAbierto] = useState(false);
  const [menuTransparent, setMenuTransparent] = useState(fondoTransparente);
  const routesData = useAllQueryData();

  const closeMenu = () => setMenuAbierto(false);

  useEffect(() => {
    if (fondoTransparente) {
      const updateMenuColor = () => {
        setMenuTransparent(window.oldScroll > window.scrollY);
        window.oldScroll = window.scrollY;
      };
      window.addEventListener("scroll", updateMenuColor);
      return () => {
        window.removeEventListener("scroll", updateMenuColor);
      };
    }
  }, [fondoTransparente]);

  return (
    <div className="header">
      <header className={classnames({ blanco: !menuTransparent })}>
        <NavLink className="link-logo" to="/">
          <img className="logo" src={logo} alt="Logo de inQba" />
        </NavLink>
        <div className="menu-horizontal">
          <LisHeaderHorizontal
            routesData={routesData}
            menuTransparent={menuTransparent}
          />
        </div>
        <div className="menu-dropdown" onClick={() => setMenuAbierto(true)}>
          {!menuAbierto && <img src={iconoMenu} alt="Abrir menu" />}
        </div>
      </header>
      {menuAbierto && (
        <nav>
          <div className="menu-abierto-primero">
            <NavLink className="link-logo" to="/">
              <img className="logo" src={logo} alt="Logo de inQba" />
            </NavLink>
            <img src={iconoCerrar} alt="Abrir menu" onClick={closeMenu} />
          </div>
          <div className="linea-separatoria">
            <div className="linea-separatoria-linea"></div>
          </div>
          <LisHeaderVertical closeMenu={closeMenu} routesData={routesData} />
          <div className="overlay"></div>
        </nav>
      )}
    </div>
  );
};

export default Header;
