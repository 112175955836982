import React from "react";

import "./misc.scss";

const LineBreak = () => <hr className="line-break" />;

export { LineBreak };

export { default as CommonTextInput } from "./CommonTextInput";
export { default as CardAdminTestimonio } from "./CardAdminTestimonio";
export { default as CardAdminCategoria } from "./CardAdminCategoria";
export { default as CollectionListSelect } from "./CollectionListSelect";
export { default as PageCollectionList } from "./PageCollectionList";
export { default as ManejarCambios } from "./ManejarCambios";
export { default as RatingStars } from "./RatingStars";
export { default as BotonAgregar } from "./BotonAgregar";
export { default as CollectionTestimonios } from "./CollectionTestimonios";
export { default as CollectionTitle } from "./CollectionTitle";
export { default as CollectionListInputs } from "./CollectionListInputs";
export { default as CollectionItemHeader } from "./CollectionItemHeader";
export { default as CollectionItemBulletList } from "./CollectionItemBulletList";
