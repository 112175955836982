import React, { useEffect, useMemo, useState } from "react";

import { BotonAgregar } from "../../components/admin/misc";
import { usePages } from "../context/context";
import EventoEspecifico from "./EventoEspecifico";

import "./AdminEventos.scss";
import EventosService from "../lib/services/eventos.service";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";

const AdminEventos = () => {
  const { eventos, fetchPage } = usePages();
  const [fetching, setFetching] = useState(false);
  const eventosService = useMemo(() => new EventosService(), []);
  const eventoPromiseHandler = usePromiseHandler("Evento", setFetching);

  const onAddEvento = (e) => {
    e.persist();
    eventoPromiseHandler(
      eventosService.updateCollection([
        ...eventos.coleccion,
        {
          imagen: "",
          nombre: "",
          ponente: "",
          duracion: "",
          dia: "",
          hora: "",
          descripcion: "",
          dateCreated: new Date(),
        },
      ]),
      "creado"
    );
  };

  const onDeleteEvento = (index) => {
    const newEventos = [...eventos.coleccion];
    newEventos.splice(index, 1);

    eventoPromiseHandler(
      eventosService.updatePageSection({ coleccion: newEventos }),
      "eliminado"
    );
  };

  const onSaveEvento = (index, categoria) => {
    const newEventos = [...eventos.coleccion];
    newEventos[index] = categoria;
    eventoPromiseHandler(
      eventosService.updatePageSection({ coleccion: newEventos }),
      "actualizado"
    );
  };

  useEffect(() => {
    if (!eventos.initialFetch) fetchPage("eventos");
  }, [eventos.initialFetch, fetchPage]);

  return (
    <section className="admin-eventos">
      <h2 className="admin-eventos__titulo headline3">Eventos</h2>
      {eventos?.coleccion?.map((data, index) => (
        <EventoEspecifico
          key={index}
          data={data}
          index={index}
          onDelete={onDeleteEvento}
          onSave={onSaveEvento}
        />
      ))}
      <BotonAgregar
        className="admin-eventos__add"
        disabled={fetching}
        onClick={onAddEvento}
      />
    </section>
  );
};

export default AdminEventos;
