import React from "react";

const WhatsappIcon = ({ color = "black", width = "31", height = "25" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.3166 0C11.9246 0 12.5219 0 13.1299 0C13.2472 0.032 13.3646 0.0746666 13.4819 0.0853333C18.1326 0.533333 22.1646 3.85066 23.4659 8.33066C23.7112 9.19466 23.8286 10.0907 23.9992 10.976C23.9992 11.5093 23.9992 12.0427 23.9992 12.576C23.7966 13.4933 23.6792 14.4213 23.3912 15.3067C21.1726 22.0587 13.5032 25.28 7.09256 22.1973C6.90056 22.1013 6.64456 21.9947 6.46323 22.0587C4.4579 22.6773 2.45256 23.3387 0.447231 23.9787C0.0845644 24.064 -0.0647689 23.9147 0.0205644 23.552C0.660564 21.7173 1.30056 19.8827 1.90856 18.0267C1.98323 17.7813 1.94056 17.44 1.82323 17.2053C-1.63277 10.6667 1.69523 2.656 8.7779 0.533333C9.6099 0.288 10.4739 0.170667 11.3166 0ZM3.70056 20.3733C4.7139 20.0533 5.62056 19.744 6.5379 19.4987C6.77256 19.4347 7.10323 19.4667 7.31656 19.584C9.10856 20.6187 10.9966 21.0773 13.0766 20.896C17.5352 20.512 21.1939 16.8853 21.5352 12.4693C21.9939 6.64533 16.9592 1.792 11.1779 2.47467C4.15923 3.296 0.596564 11.0507 4.51123 16.9387C4.62856 17.12 4.66056 17.44 4.59656 17.6533C4.34056 18.528 4.03123 19.392 3.70056 20.3733Z"
          fill={color}
        />
        <path
          d="M13.7387 14.176C14.0587 13.792 14.368 13.4293 14.656 13.0453C14.9013 12.7253 15.2107 12.5973 15.584 12.768C16.384 13.1413 17.2053 13.4933 17.9733 13.92C18.1653 14.0267 18.3253 14.368 18.3253 14.6133C18.3573 16.2133 17.1627 17.1627 15.808 17.248C13.792 17.3653 12.0427 16.544 10.4533 15.3813C9.01333 14.3253 7.91467 12.96 7.008 11.4347C6.368 10.3573 6.09067 9.20532 6.496 7.96799C6.99733 6.47466 7.744 6.29332 8.97067 6.22932C9.22667 6.21866 9.62133 6.53866 9.76 6.80532C10.1333 7.51999 10.4533 8.28799 10.688 9.05599C10.7733 9.33332 10.6133 9.72799 10.464 10.0267C10.3147 10.3253 10.0587 10.56 9.80267 10.8587C10.8373 12.2987 12.0213 13.5147 13.7387 14.176Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappIcon;
