import { mDiplomados } from "../models/mDiplomados";
import { mMBA } from "../models/mMBA";
import FirestoreService from "./firestore.service";

export default class DiplomadosService extends FirestoreService {
  constructor() {
    super();

    this.collectionRef = this.fs
      .collection("colecciones")
      .doc("admin")
      .collection("diplomadosCollection");
    this.pageRef = this.fs.collection("paginas").doc("diplomados");
  }

  updatePageSection(page) {
    return this.pageRef.set(page, { merge: true });
  }

  onDeleteDiplomado(diplomados, id) {
    return Promise.all([
      this.pageRef.set({ diplomados }, { merge: true }),
      this.collectionRef.doc(id).delete(),
    ]);
  }

  updatePageDiplomados(diplomados, id, newPath, nombreCorto) {
    return Promise.all([
      this.collectionRef
        .doc(id)
        .set({ path: newPath, nombreCorto }, { merge: true }),
      this.pageRef.set({ diplomados }, { merge: true }),
    ]);
  }

  createDiplomado(newDiplomados) {
    return this.collectionRef
      .add(mDiplomados.initDiplomado())
      .then((doc) => doc.id)
      .then((fk) =>
        this.pageRef.set(
          {
            diplomados: [
              ...newDiplomados,
              { fk, path: "", nombre: "", date: new Date() },
            ],
          },
          { merge: true }
        )
      );
  }

  // Fetches para cada Diplomado
  updateDiplomadoHeader(id, data) {
    return this.collectionRef.doc(id).set(mMBA.header(data), { merge: true });
  }

  updateDiplomadoProfesores(id, data) {
    return this.collectionRef
      .doc(id)
      .set({ profesores: data.profesores }, { merge: true });
  }

  updateDiplomadoOtros(id, data) {
    return this.collectionRef.doc(id).set(data, { merge: true });
  }

  updateDiplomadoEnfoques(id, enfoques) {
    return this.collectionRef.doc(id).set({ enfoques }, { merge: true });
  }
}
