import React, { useEffect, useState, useMemo } from "react";

import { ManejarCambios } from "../../components/admin/misc";
import { usePages } from "../context/context";
import { mContacto } from "../lib/models/mContacto";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import ContactoService from "../lib/services/contacto.service";

import mailLogo from "../../assets/svg/mail-logo.svg";
import phoneLogo from "../../assets/svg/phone-logo.svg";
import locationLogo from "../../assets/svg/location-logo.svg";
import whatsappLogo from "../../assets/svg/whatsapp-logo.svg";
import linkLogo from "../../assets/svg/link-logo.svg";

import "./AdminContacto.scss";

const AdminContacto = () => {
  const { contacto, fetchPage, fetchTriggered, setFetchTriggered } = usePages();
  const [state, setState] = useState(mContacto.toDB(contacto));
  const [isFetching, setIsFetching] = useState(false);
  const contactoService = useMemo(() => new ContactoService(), []);
  const promiseHandler = usePromiseHandler("Contacto", setIsFetching);

  const onChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDiscardChanges = (e) => {
    e.persist();
    setState(() => mContacto.toDB(contacto));
  };

  const onSaveChanges = (e) => {
    e.persist();
    promiseHandler(contactoService.updateContacto(state), "actualizado");
  };

  useEffect(() => {
    if (!contacto.initialFetch) {
      fetchPage("contacto");
    }
    if (fetchTriggered) {
      setState(() => mContacto.toDB(contacto));
      setFetchTriggered(() => false);
    }
  }, [
    fetchPage,
    contacto.initialFetch,
    contacto,
    fetchTriggered,
    setFetchTriggered,
  ]);

  return (
    <div className="admin-contacto">
      <h2 className="subtitle1">Contacto</h2>
      <div className="admin-contacto__contenedor-contacto">
        <div className="contenedor">
          <img className="contenedor__imagen" src={mailLogo} alt="Mail" />
          <input
            name="email"
            value={state.email}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
        <div className="contenedor">
          <img className="contenedor__imagen" src={phoneLogo} alt="Teléfono" />
          <input
            name="telefono"
            value={state.telefono}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
        <div className="contenedor">
          <img
            className="contenedor__imagen"
            src={whatsappLogo}
            alt="WhatsApp"
          />
          <input
            name="whatsapp"
            value={state.whatsapp}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
        <div className="contenedor">
          <img
            className="contenedor__imagen"
            src={locationLogo}
            alt="Locación"
          />
          <input
            name="maps"
            value={state.maps}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
        <div className="contenedor">
          <img className="contenedor__imagen" src={linkLogo} alt="Link" />
          <input
            name="link"
            value={state.link}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
      </div>
      <ManejarCambios
        disableSave={isFetching}
        saveClick={onSaveChanges}
        discardClick={onDiscardChanges}
      />
    </div>
  );
};
export default AdminContacto;
