import React from "react";
import { useHistory } from "react-router-dom";

import UseInViewOnce from "../../pages/hooks/useInViewOnce";
import FlechaDerechaIcon from "../svgs/FlechaDerechaIcon";

import "./GenericLinks.scss";

const colorVerde = "#ABC60D";

const GenericLinks = ({
  title = "",
  subtitle = "",
  list = [],
  route = "",
  routeKeyFn = (x) => x.fk,
  routeFn = (x) => null,
  gold = false,
  inscribeteButton = true,
  buttonText = "Inscríbete Ahora",
}) => {
  const [ref, refInView] = UseInViewOnce();
  const history = useHistory();

  const navigate = (e, data) => {
    e.persist();
    const fk = routeKeyFn(data);
    const path = data?.path;

    history.push(`${routeFn(data) || route}/${path}`, { fk });
  };
  const navigateHome = () => history.push("/contacto");

  return list.length === 0 ? null : (
    <section className="generic-links">
      <div className="generic-links__inscribete">
        <h3>{title}</h3>
        <p>{subtitle}</p>
        {inscribeteButton && (
          <span
            ref={ref}
            className={
              refInView
                ? `inscribete ${
                    gold ? "gold" : ""
                  } animate__animated animate__bounce animate__infinite  animate__slow`
                : ""
            }
            onClick={navigateHome}
          >
            {buttonText}
          </span>
        )}
      </div>
      <div className="generic-links__cards">
        {list.map((data, index) =>
          data.link ? (
            <a
              key={index}
              href={data.link}
              className="generic-links__cards-card"
            >
              <span className="generic-links__cards-card__span">
                {data?.nombre || data?.nombreCorto}
              </span>
              <FlechaDerechaIcon
                color={gold ? "#DFC747" : colorVerde}
                className="flecha"
              />
            </a>
          ) : (
            <div
              key={index}
              onClick={(e) => navigate(e, data)}
              className="generic-links__cards-card"
            >
              <span className="generic-links__cards-card__span">
                {data?.nombre || data?.nombreCorto}
              </span>
              <FlechaDerechaIcon
                color={gold ? "#DFC747" : colorVerde}
                className="flecha"
              />
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default GenericLinks;
