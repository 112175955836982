import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import Admin from "./admin/Admin";
import Home from "./pages/Home/Home";
import Diplomados from "./pages/Diplomados/Diplomados";
import Cursos from "./pages/Cursos/Cursos";
import MBA from "./pages/MBA/MBA";
import Profesores from "./pages/Profesores/Profesores";
import Contacto from "./pages/Contacto/Contacto";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import RestoreScroll from "./Utils/RestoreScroll";
import MBAPage from "./pages/MBAPage/MBAPage";
import DiplomadoPage from "./pages/DiplomadoPage/DiplomadoPage";
import CursosPage from "./pages/CursosPage/CursosPage";
import TallerPage from "./pages/TallerPage/TallerPage";
import Eventos from "./pages/Eventos/Eventos";
import AdminContextLayer from "./admin/AdminContextLayer/AdminContextLayer";

import useCursos from "./pages/hooks/useCursos";
import { useDiplomados } from "./pages/hooks/useDiplomados";
import useEventos from "./pages/hooks/useEventos";
import { useHome } from "./pages/hooks/useHome";
import { useMBAS } from "./pages/hooks/useMBAS";
import useProfesores from "./pages/hooks/useProfesores";
import useContacto from "./pages/hooks/useContacto";
import Loader from "./pages/Loader/Loader.jsx";

const InQba = () => {
  const homeData = useHome();
  const eventosData = useEventos();
  const diplomadosData = useDiplomados();
  const MBAData = useMBAS();
  const profesoresData = useProfesores();
  const cursosData = useCursos();
  const contactoData = useContacto();

  const loading =
    homeData.isLoading ||
    eventosData.isLoading ||
    diplomadosData.isLoading ||
    MBAData.isLoading ||
    profesoresData.isLoading ||
    cursosData.isLoading ||
    contactoData.isLoading;

  return (
    <ToastProvider autoDismiss>
      <Router>
        <RestoreScroll />
        <Switch>
          <Route
            path="/admin"
            render={() => (
              <AdminContextLayer>
                <Admin />
              </AdminContextLayer>
            )}
          />
          <Route
            exact
            path="/"
            render={() =>
              loading ? <Loader /> : <Home data={homeData.data} />
            }
          />
          <Route
            exact
            path="/diplomados"
            render={() =>
              loading ? <Loader /> : <Diplomados data={diplomadosData.data} />
            }
          />
          <Route
            exact
            path="/diplomados/:path"
            render={() => (loading ? <Loader /> : <DiplomadoPage />)}
          />
          <Route
            exact
            path="/cursos"
            render={() =>
              loading ? <Loader /> : <Cursos data={cursosData.data} />
            }
          />
          <Route
            exact
            path="/cursos/:path"
            render={() => (loading ? <Loader /> : <CursosPage />)}
          />
          <Route
            exact
            path="/talleres/:path"
            render={() => (loading ? <Loader /> : <TallerPage />)}
          />
          <Route
            exact
            path="/posgrados"
            render={() => (loading ? <Loader /> : <MBA data={MBAData.data} />)}
          />
          <Route
            exact
            path="/posgrados/:path"
            render={() => (loading ? <Loader /> : <MBAPage />)}
          />
          <Route
            exact
            path="/profesores"
            render={() =>
              loading ? <Loader /> : <Profesores data={profesoresData.data} />
            }
          />
          <Route
            exact
            path="/eventos"
            render={() =>
              loading ? <Loader /> : <Eventos data={eventosData.data} />
            }
          />
          <Route
            exact
            path="/contacto"
            render={() =>
              loading ? <Loader /> : <Contacto contacto={contactoData} />
            }
          />
          <Route
            exact
            path="*"
            render={() => (loading ? <Loader /> : <ErrorPage />)}
          />
        </Switch>
      </Router>
    </ToastProvider>
  );
};

export default InQba;
