import React, { useEffect, useState, useMemo } from "react";

import { ManejarCambios } from "../../components/admin/misc";
import { usePages } from "../context/context";
import { mFooter } from "../lib/models/mFooter";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import FooterService from "../lib/services/footer.service";

import facebookLogo from "../../assets/svg/facebook-logo-negro.svg";
import instagramLogo from "../../assets/svg/instagram-logo-negro.svg";
import whatsappLogo from "../../assets/svg/whatsapp-logo-negro.svg";

import "./AdminFooter.scss";
import "../../index.scss";

const AdminFooter = () => {
  const { footer, fetchPage, fetchTriggered, setFetchTriggered } = usePages();
  const [state, setState] = useState(mFooter(footer));
  const [isFetching, setIsFetching] = useState(false);
  const service = useMemo(() => new FooterService(), []);
  const promiseHandler = usePromiseHandler("Footer", setIsFetching);

  const onChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onDiscardChanges = (e) => {
    e.persist();
    setState(() => mFooter(footer));
  };

  const onSaveChanges = (e) => {
    e.persist();
    promiseHandler(service.updateFooter(state), "actualizado");
  };

  useEffect(() => {
    if (!footer.initialFetch) {
      fetchPage("footer");
    }

    if (fetchTriggered) {
      setState(() => mFooter(footer));
      setFetchTriggered(() => false);
    }
  }, [
    footer.initialFetch,
    fetchPage,
    footer,
    fetchTriggered,
    setFetchTriggered,
  ]);

  return (
    <div className="admin-footer">
      <h2 className="subtitle1">Footer</h2>
      <div className="admin-footer__contenedor-redes">
        <div className="contenedor">
          <img
            className="contenedor__imagen"
            src={facebookLogo}
            alt="Logo de facebook"
          />
          <input
            name="facebook"
            value={state.facebook}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
        <div className="contenedor">
          <img
            className="contenedor__imagen"
            src={instagramLogo}
            alt="Logo de instagram"
          />
          <input
            name="instagram"
            value={state.instagram}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
        <div className="contenedor">
          <img
            className="contenedor__imagen"
            src={whatsappLogo}
            alt="Logo de Whats App"
          />
          <input
            name="whatsapp"
            value={state.whatsapp}
            onChange={onChange}
            className="contenedor__input"
            type="text"
          />
        </div>
      </div>
      <ManejarCambios
        disableSave={isFetching}
        saveClick={onSaveChanges}
        discardClick={onDiscardChanges}
      />
    </div>
  );
};
export default AdminFooter;
