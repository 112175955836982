import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import firebase from "firebase/app";

const config = {
  apiKey: "AIzaSyCvWrXBwmUiwpyxYFUK2Rs5oDmGu3bjTfM",
  authDomain: "posgrados-inqba.firebaseapp.com",
  projectId: "posgrados-inqba",
  storageBucket: "posgrados-inqba.appspot.com",
  messagingSenderId: "888311649474",
  appId: "1:888311649474:web:190f22ee08a27cdce8175b",
};

const fb = firebase.initializeApp(config);
export const firestore = fb.firestore();
export const auth = fb.auth();
export const storage = fb.storage();
