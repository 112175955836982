import { useQuery } from "react-query";
import { firestore } from "../../firebase";
import fetchCollection from "../../Utils/fetchCollection";

const useMBA = (key) => {
  return useQuery(["getMBA", key], () =>
    firestore
      .collection("colecciones")
      .doc("admin")
      .collection("mbaCollection")
      .where("path", "==", key)
      .get()
      .then(async (docs) => {
        const mbas = [];
        docs.forEach((d) => mbas.push({ id: d.id, ...d.data() }));
        if (!mbas[0]) return { notFound: true };

        const docData = mbas[0];
        const profesores = await fetchCollection(
          docData.profesores,
          "profesores"
        );
        const otrosMbas = await fetchCollection(
          docData.otros.coleccion,
          "mbaCollection"
        );
        return { ...docData, profesores, otrosMbas };
      })
  );
};

export default useMBA;
