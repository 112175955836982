import FirestoreService from "./firestore.service";

export default class FooterService extends FirestoreService {
  constructor() {
    super();
    this.collectionRef = this.fs.collection("paginas").doc("footer");
  }

  updateFooter(footerData) {
    return this.collectionRef.set(footerData);
  }
}
