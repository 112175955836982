import React, { useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { CommonTextInput, ManejarCambios } from "../../components/admin/misc";
import useInputChange from "../lib/hooks/useInputChange";
import { genHash } from "../../Utils/genHash";

import { ReactComponent as TrashIcon } from "../../assets/svg/trash.svg";
import BackupImage from "../../assets/svg/backup-image.svg";
import calendarIcon from "../../assets/svg/calendar-icon.svg";
import timeIcon from "../../assets/svg/time-icon.svg";
import circleCheckIcon from "../../assets/svg/circlecheck-icon.svg";
import StorageService from "../lib/services/storage.service";

const EventoEspecifico = ({ data, index, onDelete, onSave }) => {
  const [state, setState] = useState(
    ((x) => ({ ...x, imageURL: "", imageFile: null }))(data)
  );
  const { addToast } = useToasts();
  const onChangeInput = useInputChange(setState);
  const storage = useMemo(() => new StorageService(), []);

  const onEditImage = (e) => {
    e.persist();
    const newImage = e.target.files[0];

    setState((state) => ({
      ...state,
      imageFile: newImage,
      imageURL: URL.createObjectURL(newImage),
    }));
  };

  const onDeleteEvento = (e, index) => {
    e.persist();
    deleteImage();
    onDelete(index);
  };

  const deleteImage = () =>
    state.imagen !== "" &&
    storage
      .deleteFile(
        `/eventos/${state.imagen
          .replace(
            `https://firebasestorage.googleapis.com/v0/b/posgrados-inqba.appspot.com/o/eventos%2F`,
            ""
          )
          .replace("?alt=media", "")}`
      )
      .then(() => true)
      .catch(() => false);

  const submitImage = () =>
    storage.uploadImage(
      `/eventos/${genHash(state.imageFile.name)}${
        state.imageFile.type === "image/png" ? ".png" : ".jpg"
      }`,
      state.imageFile
    );

  const onSaveChanges = async (e) => {
    e.persist();
    const dataToSave = { ...state };
    delete dataToSave["imageFile"];
    delete dataToSave["imageURL"];
    addToast("Subiendo imagen...", { appearance: "info" });
    if (state.imageFile) {
      if (state.imagen.length > 0) {
        const imageDel = await deleteImage();
        if (!imageDel) {
          return addToast(
            "Error al borrar imagen anterior, intente borrar el evento más tarde.",
            { appearance: "error" }
          );
        }
      }

      const imagePath = await submitImage();
      if (!imagePath) {
        return addToast("Error al subir imagen nueva, intente más tarde.", {
          appearance: "error",
        });
      }
      onSave(index, {
        ...dataToSave,
        imagen: imagePath,
      });
    } else {
      onSave(index, {
        ...dataToSave,
        imagen: state.imagen,
      });
    }
  };

  const onDiscardChanges = (e) => {
    e.persist();
    setState(((x) => ({ ...x, imageURL: "", imageFile: null }))(data));
  };

  useEffect(() => {
    if (
      !state.imageFile &&
      state.nombre === "" &&
      state.ponente === "" &&
      state.dia === "" &&
      state.hora === "" &&
      state.duracion === "" &&
      state.descripcion === ""
    )
      setState(((x) => ({ ...x, imageURL: "", imageFile: null }))(data));
  }, [
    data,
    state.imageFile,
    state.nombre,
    state.ponente,
    state.dia,
    state.hora,
    state.duracion,
    state.descripcion,
  ]);

  return (
    <div className="evento-especifico card">
      <div className="evento-especifico__body ">
        <span className="evento-especifico__title headline3">
          Evento {index + 1}
        </span>
        <div className="evento-especifico__imagen card__body flex-row">
          <img
            src={
              state.imageURL === "" && state.imagen === ""
                ? BackupImage
                : state.imageURL.length > 0
                ? state.imageURL
                : state.imagen
            }
            alt="Imagen de Categoría"
            className="card__imagen"
          ></img>
          <label htmlFor={`categoria-image-${index}`} className="card__label">
            ELEGIR IMAGEN
          </label>
          <input
            id={`categoria-image-${index}`}
            value={""}
            accept="image/x-png,image/jpeg"
            onChange={onEditImage}
            type="file"
            className="card__input"
          />
          <TrashIcon onClick={onDeleteEvento} />
        </div>
        <CommonTextInput
          name="nombre"
          value={state.nombre}
          onChange={onChangeInput}
          placeholder="Nombre del evento"
        />
        <CommonTextInput
          name="ponente"
          value={state.ponente}
          onChange={onChangeInput}
          placeholder="Ponente"
        />
        <CommonTextInput
          inputClassname="evento-especifico__inputIcon"
          name="dia"
          value={state.dia}
          onChange={onChangeInput}
          placeholder="Día"
          leftIcon={calendarIcon}
        />
        <CommonTextInput
          inputClassname="evento-especifico__inputIcon"
          name="hora"
          value={state.hora}
          onChange={onChangeInput}
          placeholder="Hora"
          leftIcon={timeIcon}
        />
        <CommonTextInput
          inputClassname="evento-especifico__inputIcon"
          name="duracion"
          value={state.duracion}
          onChange={onChangeInput}
          placeholder="Duración"
          leftIcon={circleCheckIcon}
        />
        <textarea
          className="collection-item__textarea"
          rows="5"
          placeholder="Descripción"
          value={state.descripcion}
          name="descripcion"
          onChange={onChangeInput}
        ></textarea>
        <ManejarCambios
          discardClick={onDiscardChanges}
          saveClick={onSaveChanges}
          noMarginBottom
        />
      </div>
    </div>
  );
};

export default EventoEspecifico;
