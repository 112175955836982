import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";

import useWidthListener from "../../pages/hooks/useWidthListener";
import FlipCardProfesor from "../FlipCardProfesor/FlipCardProfesor";

import { ReactComponent as Flecha } from "../UniqueEnfoques/flecha.svg";

import "./UniqueProfesores.scss";

const setContainerModifier = (size) => (size === "mobile" ? 1 : 2);

const setContainerSize = (size) => (size === "mobile" ? 288 : 688);

const UniqueProfesores = ({ type = "mba", collection = [], gold }) => {
  const screenSize = useWidthListener();
  const [isClicked, setClicked] = useState(false);
  const sliderRef = useRef();
  const [currentSize, setCurrentSize] = useState(screenSize);
  const [vwCount, setVW] = useState(1);
  const [containerCount, setContainerCount] = useState(
    setContainerModifier(screenSize)
  );
  const carouselList = useMemo(() => {
    if (collection.length === 0) return [];
    if (screenSize === "mobile") {
      const newCollection = [...collection];

      newCollection.push(collection[0] || { spacer: true });
      newCollection.unshift(
        collection[collection.length - 1] || { spacer: true }
      );

      return newCollection;
    } else {
      const newCollection = [...collection];

      if (newCollection.length % 2 !== 0) {
        newCollection.push({ spacer: true });
        newCollection.push(collection[0] || { spacer: true });
        newCollection.push(collection[1] || { spacer: true });
        newCollection.unshift({ spacer: true });
        newCollection.unshift(
          collection[collection.length - 1] || { spacer: true }
        );
      } else {
        newCollection.push(collection[0]);
        newCollection.push(collection[1]);
        newCollection.unshift(
          collection[collection.length - 2] || { spacer: true }
        );
        newCollection.unshift(
          collection[collection.length - 1] || { spacer: true }
        );
      }

      return newCollection;
    }
  }, [collection, screenSize]);

  const untriggerSwipe = () => {
    if (collection.length <= 1) {
      return true;
    }

    if (collection.length <= 2 && screenSize !== "mobile") return true;

    return false;
  };

  const handleRightButton = (e) => {
    e.persist();
    if (untriggerSwipe()) return;
    const sliderNode = sliderRef.current;
    const containerSize = setContainerSize(screenSize);

    sliderNode.style.transition = "transform 500ms ease-in-out";
    sliderNode.style.transform = `translateX(calc((${100 * (vwCount + 1)}vw + ${
      containerSize * (vwCount + 1)
    }px) * -1))`;

    setVW((x) => x + 1);
    setContainerCount((x) => x + setContainerModifier(screenSize));
    setClicked(() => true);
  };

  const handleLeftButton = (e) => {
    e.persist();
    if (untriggerSwipe()) return;

    const sliderNode = sliderRef.current;
    const containerSize = setContainerSize(screenSize);

    sliderNode.style.transition = "transform 500ms ease-in-out";
    sliderNode.style.transform = `translateX(calc((${100 * (vwCount - 1)}vw + ${
      containerSize * (vwCount - 1)
    }px) * -1))`;

    setVW((x) => x - 1);
    setContainerCount((x) => x - setContainerModifier(screenSize));
    setClicked(() => true);
  };

  const handleTransitionEvent = useCallback(() => {
    if (
      containerCount >=
      carouselList?.length - setContainerModifier(screenSize)
    ) {
      const sliderNode = sliderRef.current;
      const containerSize = setContainerSize(screenSize);

      sliderNode.style.transition = "none";
      sliderNode.style.transform = `translateX(calc((100vw + ${containerSize}px) * -1))`;

      setVW(() => 1);
      setContainerCount(() => setContainerModifier(screenSize));
    }

    if (containerCount <= 0) {
      const sliderNode = sliderRef.current;
      const containerSize = setContainerSize(screenSize);
      const spacing =
        screenSize === "mobile"
          ? carouselList.length - 2
          : Math.floor(carouselList.length / 2) - 2;

      sliderNode.style.transition = "none";
      sliderNode.style.transform = `translateX(calc((${100 * spacing}vw + ${
        containerSize * spacing
      }px) * -1))`;

      setVW(() => spacing);
      setContainerCount(() =>
        screenSize === "mobile" ? spacing : spacing * 2
      );
    }

    setClicked(() => false);
  }, [carouselList?.length, sliderRef, containerCount, screenSize]);

  const handleResizeEvent = useCallback(() => {
    if (currentSize !== screenSize) {
      setClicked(() => true);
      const sliderNode = sliderRef.current;
      if (!sliderNode) return setClicked(() => false);
      const containerSize = setContainerSize(screenSize);
      sliderNode.style.transition = "none";
      sliderNode.style.transform = `translateX(calc((100vw + ${containerSize}px) * -1))`;
      setCurrentSize(() => screenSize);
      setVW(() => 1);
      setContainerSize(() => setContainerModifier(screenSize));
      setClicked(() => false);
    }
  }, [currentSize, screenSize, sliderRef]);

  useEffect(() => {
    const sliderNode = sliderRef.current;
    sliderNode.addEventListener("transitionend", handleTransitionEvent);
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      sliderNode.removeEventListener("transitionend", handleTransitionEvent);
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [handleTransitionEvent, handleResizeEvent, sliderRef]);

  return (
    <section className="unique-profesores">
      <div className="unique-profesores__title">
        <h3 className="headline2">
          ¿Quiénes imparten {type === "mba" && "la maestría"}{" "}
          {type === "diplomado" && "el diplomado"}
          {type === "curso" && "el curso"}
          {type === "taller" && "el taller"}?
        </h3>
      </div>
      <div className="unique-profesores__carousel">
        <div ref={sliderRef} className="unique-profesores__slider">
          {carouselList?.map((data, index) =>
            data?.spacer ? (
              <div
                className={`unique-profesores__item spacer`}
                key={index}
              ></div>
            ) : (
              <FlipCardProfesor
                gold={gold}
                key={index}
                profesor={data}
                className="unique-profesores__item"
              />
            )
          )}
        </div>
        {screenSize === "mobile" &&
        collection.length === 1 ? null : (screenSize === "tablet" ||
            screenSize === "laptop" ||
            screenSize === "desktop") &&
          collection.length <= 2 ? null : (
          <div className="unique-profesores__buttons">
            <button disabled={isClicked} onClick={handleLeftButton}>
              <Flecha />
            </button>
            <button disabled={isClicked} onClick={handleRightButton}>
              <Flecha />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default UniqueProfesores;
