import { useToasts } from "react-toast-notifications";

const usePromiseHandler = (model, fetchingDispatcher) => {
  const { addToast } = useToasts();
  return (promise, operation) => {
    fetchingDispatcher(() => true);
    addToast("Procesando...", {
      appearance: "info",
    });

    promise
      .then(() => {
        fetchingDispatcher(() => false);
        addToast(`${model} ${operation} correctamente`, {
          appearance: "success",
        });
      })
      .catch(() => {
        fetchingDispatcher(() => false);
        addToast(`Error: ${model} no ${operation}, intente más tarde.`, {
          appearance: "error",
        });
      });
  };
};

export default usePromiseHandler;
