import React from "react";
import { BotonAgregar, CommonTextInput, ManejarCambios } from "./misc";

import { ReactComponent as Trash } from "../../assets/svg/trash.svg";

const CollectionItemBulletList = ({
  placeholderTitle = "Enfoques de la maestría",
  collectionTitle,
  titleBullet,
  collection,
  disableSave,
  onChangeTitle,
  onChangeSubtitle,
  onClickAddSection,
  onClickDeleteSection,
  onClickAddBullet,
  onChangeBullet,
  onDeleteBullet,
  saveBulletClick,
  discardBulletClick,
}) => {
  return (
    <section className="collection-item__bulletList">
      <CommonTextInput
        value={collectionTitle}
        onChange={onChangeTitle}
        placeholder={placeholderTitle}
      />
      {collection.map(({ subtitulo, lista }, index) => (
        <div key={index} className="collection-item__bItem">
          <div className="collection-item__bTitle flex-row">
            <span className=" headline3">
              {titleBullet} {index + 1}
            </span>
            <Trash
              width="24px"
              onClick={(e) => onClickDeleteSection(e, index)}
            />
          </div>
          <div className="collection-item__bSubitem">
            <CommonTextInput
              value={subtitulo}
              onChange={(e) => onChangeSubtitle(e, index)}
              placeholder=""
            />
            {lista.map((content, contentIndex) => (
              <CommonTextInput
                key={contentIndex}
                trashActivo
                value={content}
                onChange={(e) => onChangeBullet(e, index, contentIndex)}
                onDelete={(e) => onDeleteBullet(e, index, contentIndex)}
                placeholder={`Bullet ${contentIndex + 1}`}
              />
            ))}
          </div>
          <button
            onClick={(e) => onClickAddBullet(e, index)}
            disabled={disableSave}
            className="collection-item__addBullet"
          >
            AGREGAR BULLET
          </button>
          <ManejarCambios
            noMarginBottom
            disableSave={disableSave}
            saveClick={(e) => saveBulletClick(e, index)}
            discardClick={(e) => discardBulletClick(e, index)}
          />
        </div>
      ))}
      <BotonAgregar
        onClick={onClickAddSection}
        className="collection-item__add"
      />
    </section>
  );
};

export default CollectionItemBulletList;
