import React, { useMemo } from "react";

import { ReactComponent as Star } from "./star.svg";

import "./StarRating.scss";

const StarRating = ({ rating, className }) => {
  const stars = useMemo(() => parseInt(rating), [rating]);

  return (
    <div className={`star-rating ${className || null}`}>
      {stars >= 1 && <Star />}
      {stars >= 2 && <Star />}
      {stars >= 3 && <Star />}
      {stars >= 4 && <Star />}
      {stars >= 5 && <Star />}
    </div>
  );
};

export default StarRating;
