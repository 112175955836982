import React from "react";

const FlechaDerechaIcon = ({
  color = "black",
  width = "24",
  height = "24",
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5859 12L7.29297 4.70712L8.70718 3.29291L17.4143 12L8.70718 20.7071L7.29297 19.2929L14.5859 12Z"
        fill={color}
      />
    </svg>
  );
};

export default FlechaDerechaIcon;
