import React from "react";

const PhoneIcon = ({ color = "black", width = "29", height = "29" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9278 9.66713C13.6402 8.70709 13.8957 7.6953 13.09 6.80219C11.2819 4.27222 10.0659 2.70769 9.30323 1.95425C7.85321 0.52187 5.46791 0.715622 4.21208 1.95303C3.53757 2.61765 3.30922 2.84589 2.61664 3.54935C-1.24113 7.40919 1.11154 15.5688 7.36578 21.8292C13.6186 28.0881 21.7774 30.4421 25.6433 26.5741C26.2824 25.9579 26.8235 25.4165 27.2502 24.9655C28.4809 23.6647 28.6677 21.3846 27.2421 19.9033C26.5112 19.1439 25.0193 17.9867 22.3829 16.1008C21.5754 15.3788 20.618 15.5353 19.7403 16.1111C19.3181 16.3881 19.0108 16.6683 18.4306 17.2491L17.377 18.3033C17.2383 18.4421 15.354 17.4985 13.5249 15.6675C11.6947 13.8356 10.7517 11.9512 10.8898 11.8131C11.1747 11.5283 11.5261 11.1767 11.9442 10.7582C12.1282 10.574 12.2162 10.4848 12.3291 10.3661C12.5642 10.119 12.7589 9.89474 12.9278 9.66713ZM19.3205 20.2478L20.3743 19.1934C20.6936 18.8738 20.9006 18.6773 21.0689 18.5418C23.3784 20.2003 24.716 21.2432 25.2623 21.8108C25.5903 22.1517 25.5394 22.7736 25.2542 23.075C24.8596 23.492 24.3469 24.005 23.7178 24.6119C21.218 27.1127 14.632 25.2126 9.30968 19.885C3.9857 14.5558 2.08646 7.96885 4.56739 5.48655C5.25718 4.78598 5.47573 4.56755 6.14061 3.91241C6.38991 3.66677 7.06896 3.61161 7.37223 3.91119C7.95944 4.49126 9.04872 5.886 10.6511 8.11809C10.5681 8.22108 10.4645 8.33746 10.3385 8.46997C10.2459 8.56723 10.1688 8.64538 10.0003 8.8141C9.58253 9.23224 9.23143 9.58354 8.94699 9.86795C7.15516 11.6596 8.68115 14.709 11.581 17.6117C14.4788 20.5123 17.5293 22.04 19.3205 20.2478Z"
        fill={color}
      />
    </svg>
  );
};

export default PhoneIcon;
