import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Arrow } from "./arrow.svg";

import "./UniqueHeader.scss";

const UniqueHeader = ({
  type = "",
  title = "",
  subtitle = "",
  inscripcionLink = "/contacto",
}) => {
  return (
    <section className={`unique-header ${type === "posgrados" ? "mba" : type}`}>
      <div className="unique-header__container">
        <Link to={`/${type}`} className="unique-header__back">
          <Arrow />
          <span>
            Regresar a {type === "posgrados" && "Posgrados"}
            {type === "diplomados" && "Diplomados"}
            {type === "cursos" && "InCompany"}
          </span>
        </Link>
        <h3 className="unique-header__title headline1">{title}</h3>
        <span className="unique-header__subtitle ">{subtitle}</span>
        <Link
          className="unique-header__button"
          to={{ pathname: inscripcionLink, state: { curso: title } }}
        >
          Inscríbete ahora
        </Link>
      </div>
    </section>
  );
};

export default UniqueHeader;
