import React from "react";

import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar-icon.svg";
import { ReactComponent as RouteIcon } from "../../assets/svg/route-icon.svg";
import { ReactComponent as TimeIcon } from "../../assets/svg/time-icon.svg";
import { ReactComponent as CircleCheckIcon } from "../../assets/svg/circlecheck-icon.svg";

import "./UniqueInfo.scss";

const UniqueInfo = ({
  duracion = "",
  modalidad = "",
  horario = "",
  inicio = "",
  green,
}) => {
  return (
    <section className={`unique-info ${green ? "green" : ""}`}>
      <div className="unique-info__item">
        <div className="unique-info__title">
          <CalendarIcon />
          <span>Duración:</span>
        </div>
        <span className="unique-info__description">{duracion}</span>
      </div>
      <div className="unique-info__item">
        <div className="unique-info__title">
          <RouteIcon />
          <span>Modalidad:</span>
        </div>
        <span className="unique-info__description">{modalidad}</span>
      </div>
      <div className="unique-info__item">
        <div className="unique-info__title">
          <TimeIcon />
          <span>Horario:</span>
        </div>
        <span className="unique-info__description">{horario}</span>
      </div>
      <div className="unique-info__item">
        <div className="unique-info__title">
          <CircleCheckIcon />
          <span>Inicio:</span>
        </div>
        <span className="unique-info__description">{inicio}</span>
      </div>
    </section>
  );
};

export default UniqueInfo;
