export const mCursos = () => ({});

mCursos.init = () => ({
  path: "",
  nombre: "",
  intro: "",
  duracion: "",
  modalidad: "",
  horario: "",
  inicio: "",
  enfoques: { titulo: "", coleccion: [] },
  profesores: [],
  otros: { titulo: "", descripcion: "", coleccion: [] },
  date: new Date(),
});
