import { mProfesor } from "../models/mProfesor";

export const EDIT_FIELD = "EDIT_FIELD";
export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const UPDATE_CURSO = "EDIT_CURSO";
export const CREATE_CURSO = "CREATE_CURSO";
export const DELETE_CURSO = "DELETE_CURSO";
export const DISCARD_CHANGES = "DISCARD_CHANGES";
export const EDIT_IMAGE = "EDIT_IMAGE";

const editField = (state, { field, value }) => ({ ...state, [field]: value });

const createCurso = (state) => ({ ...state, cursos: [...state.cursos, ""] });

const updateCurso = (state = mProfesor.init(), { index, value }) => {
  const newCursos = [...state.cursos];
  newCursos[index] = value;
  return { ...state, cursos: newCursos };
};

const deleteCurso = (state, { index }) => {
  const newCursos = [...state.cursos];
  newCursos.splice(index, 1);
  return { ...state, cursos: newCursos };
};

const discardChanges = ({ prevState }) => {
  return prevState;
};

const editImage = (state, { imageFile }) => ({
  ...state,
  imageFile,
  imageURL: URL.createObjectURL(imageFile),
});

export default function reducer(state, action) {
  switch (action.type) {
    case EDIT_FIELD:
      return editField(state, action.payload);

    case CREATE_CURSO:
      return createCurso(state);

    case UPDATE_CURSO:
      return updateCurso(state, action.payload);

    case DELETE_CURSO:
      return deleteCurso(state, action.payload);

    case DISCARD_CHANGES:
      return discardChanges(action.payload);

    case EDIT_IMAGE:
      return editImage(state, action.payload);

    default:
      return state;
  }
}
