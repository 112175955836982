import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import cleanPhoneNumber from "../../Utils/cleanPhoneNumber";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import WhatsappIcon from "../../components/svgs/WhatsappIcon";
import MailIcon from "../../components/svgs/MailIcon";
import PhoneIcon from "../../components/svgs/PhoneIcon";
import LocationIcon from "../../components/svgs/LocationIcon";
import cleanURL from "../../Utils/cleanURL";

import "./Contacto.scss";

const Contacto = ({ contacto }) => {
  const [isSent, setIsSent] = useState(false);
  const { state } = useLocation();

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const objectFormData = {};
    formData.forEach(function (value, key) {
      objectFormData[key] = value;
    });
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        ...objectFormData,
      }),
    })
      .then(() => setIsSent(true))
      .catch(() => setIsSent(true));
  };

  return (
    <div>
      <Header />
      <div className="contenedor-contacto">
        {isSent ? (
          <div className="contenedor-contacto__seccion-gracias">
            <h2 className="headline2">
              ¡Gracias! Hemos recibido tus datos y nos pondremos en contacto
              contigo.
            </h2>
          </div>
        ) : (
          <div className="contenedor-contacto__seccion-form">
            <span className="headline2 white">Déjanos tus datos:</span>
            <form className="form" onSubmit={formSubmit}>
              <input
                className="caption2 form__input"
                type="text"
                placeholder="Nombre*"
                id="nombre"
                name="nombre"
                required
              />
              <input
                className="caption2 form__input"
                type="text"
                placeholder="Apellido*"
                id="apellido"
                name="apellido"
                required
              />
              <input
                className="caption2 form__input"
                type="email"
                placeholder="Correo electrónico*"
                id="email"
                name="email"
                required
              />
              <input
                className="caption2 form__input"
                type="tel"
                placeholder="Teléfono*"
                id="telefono"
                name="telefono"
                required
              />
              <input
                className="caption2 form__input"
                type="text"
                placeholder="¿A qué empresa perteneces?"
                id="empresa"
                name="empresa"
              />
              <select
                className="caption2 form__input"
                placeholder="Periodo de Interés"
                id="periodo_de_interes"
                name="periodo_de_interes"
                required
                defaultValue=""
              >
                <option value="" disabled>
                  Periodo de Interés
                </option>
                <option value="Primavera">Primavera</option>
                <option value="Otoño">Otoño</option>
              </select>
              <input
                className="caption2 form__input"
                type="text"
                placeholder="Programa de interés"
                defaultValue={state?.curso || ""}
                id="curso_interes"
                name="curso_interes"
              />
              <textarea
                className="caption2 form__textarea"
                type="text"
                placeholder="Compártenos tus dudas"
                id="dudas"
                name="dudas"
              ></textarea>
              <div className="form__label">
                <input
                  type="checkbox"
                  name="terminos_aceptados"
                  required
                  id="terminos_aceptados"
                />
                <label htmlFor="terminos_aceptados">
                  Acepto{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://inqba.edu.mx/PDF/aviso_privacidad.pdf"
                  >
                    términos y condiciones
                  </a>
                </label>
              </div>
              <button className="button white form__btn-submit" type="submit">
                Enviar
              </button>
            </form>
          </div>
        )}
        <div className="contenedor-contacto__seccion-contactanos">
          <span className="subtitle1 white">CONTACTO</span>
          <div className="contenedor">
            <MailIcon color={"white"} width={"22"} height={"18"} />
            <a
              className="caption2 white contenedor__span"
              href={`mailto:${contacto?.data?.email}`}
            >
              {contacto?.data?.email}
            </a>
          </div>
          <div className="contenedor">
            <PhoneIcon color={"white"} width={"20"} height={"20"} />
            <a
              target="__blank"
              noopener="true"
              noreferrer="true"
              className="caption2 white contenedor__span"
              href={`tel:${contacto?.data?.telefono}`}
            >
              {contacto?.data?.telefono}
            </a>
          </div>
          <div className="contenedor">
            <WhatsappIcon color={"white"} width={"20"} height={"20"} />
            <a
              target="__blank"
              noopener="true"
              noreferrer="true"
              className="caption2 white contenedor__span"
              href={`https://wa.me/${cleanPhoneNumber(
                contacto?.data?.whatsapp
              )}`}
            >
              {contacto?.data?.whatsapp}
            </a>
          </div>
          <div className="contenedor">
            <LocationIcon color={"white"} width={"18"} height={"22"} />
            <p className="caption2 white contenedor__span">
              {contacto?.data?.maps}
            </p>
          </div>
          <a
            target="__blank"
            noopener="true"
            noreferrer="true"
            href={`https://${cleanURL(contacto?.data?.link)}`}
            className="button green ver-google"
          >
            Ver en Google Maps
          </a>
        </div>
      </div>
      <Footer showBanner={false} />
    </div>
  );
};

export default Contacto;
