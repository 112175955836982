import React, { useState } from "react";

import "./Carousel.scss";

const Carousel = ({ testimonios = [] }) => {
  const [actualSlide, setActualSlide] = useState(0);

  const changeSlide = (direccion) => {
    if (direccion === "derecha") {
      setActualSlide(actualSlide + 1);
    } else {
      setActualSlide(actualSlide - 1);
    }
  };

  const testimonio = testimonios[actualSlide];

  return testimonios.length === 0 ? null : (
    <div className="carousel">
      <div
        className="carousel__slide fade flex-column"
        key={testimonio?.nombre}
      >
        <p className="carousel__testimonio body white">
          {testimonio?.testimonio}
        </p>
        <span className="carousel__nombre caption3 white">
          {testimonio?.nombre}
        </span>
        <span className="carousel__puesto caption3 green">
          {testimonio?.profesion}
        </span>
      </div>
      <button
        className="carousel__prev"
        alt="Flecha izquierda"
        onClick={() => changeSlide("izquierda")}
        disabled={actualSlide === 0}
      />
      <button
        className="carousel__next"
        alt="Flecha derecha"
        onClick={() => changeSlide("derecha")}
        disabled={actualSlide === testimonios.length - 1}
      />
    </div>
  );
};

export default Carousel;
