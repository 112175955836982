import React from "react";
import { useParams, Redirect } from "react-router-dom";

import useMBA from "../hooks/useMBA";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UniqueHeader from "../../components/UniqueHeader/UniqueHeader";
import UniqueInfo from "../../components/UniqueInfo/UniqueInfo";
import UniqueEnfoques from "../../components/UniqueEnfoques/UniqueEnfoques";
import UniqueProfesores from "../../components/UniqueProfesores/UniqueProfesores";
import GenericLinks from "../../components/GenericLinkList/GenericLinks";

const MBAPage = () => {
  const params = useParams();
  const { data } = useMBA(params.path);
  if (!params.path) return <Redirect to="/no-encontrado" />;

  return data?.notFound ? (
    <Redirect to="/no-encontrado" />
  ) : (
    <main className="unique-mba unique-page">
      <Header fondoTransparente={false} />
      <UniqueHeader
        type="posgrados"
        title={data?.nombre}
        subtitle={data?.intro}
        inscripcionLink="/contacto"
      />
      <UniqueInfo
        duracion={data?.duracion}
        modalidad={data?.modalidad}
        horario={data?.horario}
        inicio={data?.inicio}
      />
      {data?.enfoques?.coleccion.length > 0 && (
        <UniqueEnfoques
          title={data?.enfoques?.title}
          collection={data?.enfoques?.coleccion}
        />
      )}
      {data?.profesores?.length > 0 && (
        <UniqueProfesores type="mba" collection={data?.profesores} gold />
      )}
      <GenericLinks
        title={"Proceso de Admisión"}
        subtitle="Conoce nuestro proceso de admisión, 4 sencillos pasos que te llevarán a otro nivel."
        list={[
          {
            nombre: "Ver Proceso de Admisión",
            link: "/docs/proceso-de-admision-posgrado.pdf",
          },
        ]}
        routeKeyFn={(x) => x.id}
        route="/posgrados"
        gold
        buttonText="Solicitar más Información"
      />
      <Footer />
    </main>
  );
};

export default MBAPage;
