import { storage } from "../../../firebase";

export default class StorageService {
  constructor(st = storage) {
    this.st = st.ref();
  }

  deleteFile = (path) =>
    this.st
      .child(path)
      .delete()
      .then(() => true)
      .catch(() => false);

  uploadImage = (path, image) =>
    this.st
      .child(path)
      .put(image)
      .then(
        (img) =>
          "https://firebasestorage.googleapis.com/v0/b/" +
          img.metadata.bucket +
          "/o/" +
          img.metadata.fullPath.replace(/\//g, "%2F") +
          "?alt=media"
      )
      .catch((e) => {
        console.log(e);
        return false;
      });
}
