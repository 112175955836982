import React, { useEffect, useReducer } from "react";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";

import profesorReducer, {
  CREATE_CURSO,
  DELETE_CURSO,
  DISCARD_CHANGES,
  EDIT_FIELD,
  EDIT_IMAGE,
  UPDATE_CURSO,
} from "../lib/reducers/profesor.reducer";
import {
  CommonTextInput,
  ManejarCambios,
  RatingStars,
} from "../../components/admin/misc";
import BackupImage from "../../assets/svg/backup-image.svg";
import useDispacher from "../lib/hooks/useDispacher";
import StorageService from "../lib/services/storage.service";
import { genHash } from "../../Utils/genHash";
import { mProfesor } from "../lib/models/mProfesor";

const CardAdminProfesor = ({
  profesor = mProfesor.init(),
  varianteProfesores = false,
  disableSave,
  deleteProfesor,
  updateProfesor,
  indexProfesor,
}) => {
  const [state, dispatch] = useReducer(
    profesorReducer,
    mProfesor.toEdit(profesor)
  );
  const { addToast } = useToasts();
  const dispatcher = useDispacher(dispatch);
  const storage = new StorageService();

  const onChangeField = (e) => {
    e.persist();
    dispatcher(EDIT_FIELD, { field: e.target.name, value: e.target.value });
  };

  const onEditImage = (e) => {
    e.persist();
    dispatcher(EDIT_IMAGE, { imageFile: e.target.files[0] });
  };

  const onUpdateCurso = (e, index) => {
    e.persist();
    dispatcher(UPDATE_CURSO, { value: e.target.value, index });
  };

  const onCreateCurso = (e) => {
    e.persist();
    dispatcher(CREATE_CURSO);
  };

  const onDeleteCurso = (e, index) => {
    e.persist();
    dispatcher(DELETE_CURSO, { index });
  };

  const onDiscardChanges = (e) => {
    e.persist();
    dispatcher(DISCARD_CHANGES, { prevState: mProfesor.toEdit(profesor) });
  };

  const deleteImage = () =>
    storage
      .deleteFile(
        `/profesores/${state.imagen
          .replace(
            `https://firebasestorage.googleapis.com/v0/b/posgrados-inqba.appspot.com/o/profesores%2F`,
            ""
          )
          .replace("?alt=media", "")}`
      )
      .then(() => true)
      .catch(() => false);

  const submitImage = () =>
    storage.uploadImage(
      `/profesores/${genHash(state.imageFile.name)}${
        state.imageFile.type === "image/png" ? ".png" : ".jpg"
      }`,
      state.imageFile
    );

  const onDeleteProfesor = async (e) => {
    e.persist();
    if (disableSave) return;
    if (state.imagen.length > 0) {
      const imageDel = await deleteImage();
      if (!imageDel) {
        return addToast(
          "Error al borrar imagen, intente borrar al profesor más tarde.",
          { appearance: "error" }
        );
      }
    }

    deleteProfesor(profesor.id);
  };

  const onSaveChanges = async () => {
    if (state.imageFile) {
      if (state.imagen.length > 0) {
        const imageDel = await deleteImage();
        if (!imageDel) {
          return addToast(
            "Error al borrar imagen anterior, intente borrar al profesor más tarde.",
            { appearance: "error" }
          );
        }
      }

      const imagePath = await submitImage();
      if (!imagePath) {
        return addToast("Error al subir imagen nueva, intente más tarde.", {
          appearance: "error",
        });
      }
      updateProfesor(profesor.id, {
        ...mProfesor.toDB(state),
        imagen: imagePath,
      });
    } else {
      updateProfesor(profesor.id, { ...mProfesor.toDB(state) });
    }
  };

  useEffect(() => {
    if (profesor.imagen !== state.imagen) {
      dispatcher(DISCARD_CHANGES, { prevState: mProfesor.toEdit(profesor) });
    }
    return () => {};
  }, [profesor, dispatcher, state.imagen]);

  return (
    <div
      className={classnames("card", {
        "card--more-margin-top": varianteProfesores,
      })}
    >
      <span className="card__title">Profesor {indexProfesor}</span>
      <CommonTextInput
        onChange={onChangeField}
        value={state.nombre}
        name="nombre"
        placeholder={"Nombre y apellido"}
        onDelete={onDeleteProfesor}
        trashActivo
      />
      <CommonTextInput
        value={state.profesion}
        onChange={onChangeField}
        name="profesion"
        placeholder={"Profesión"}
      />
      <RatingStars
        id={profesor.id}
        rating={state.rating}
        onChange={onChangeField}
      />
      <div className="card__body flex-row">
        <img
          src={
            state.imageURL === "" && state.imagen === ""
              ? BackupImage
              : state.imageURL.length > 0
              ? state.imageURL
              : state.imagen
          }
          alt="Imagen de profesor"
          className="card__imagen"
        ></img>
        <label
          htmlFor={`profesor-image-${profesor.id}`}
          className="card__label"
        >
          ELEGIR IMAGEN
        </label>
        <input
          id={`profesor-image-${profesor.id}`}
          value={""}
          accept="image/x-png,image/jpeg"
          onChange={onEditImage}
          type="file"
          className="card__input"
        />
      </div>
      {varianteProfesores && (
        <>
          <textarea
            className="card__textarea"
            rows="5"
            placeholder="Descripción"
            value={state.descripcion}
            name="descripcion"
            onChange={onChangeField}
          ></textarea>
          <div className="card__variante flex-row">
            <div className="card__cursos">
              {state.cursos.map((cursoValue, index) => (
                <CommonTextInput
                  key={index}
                  placeholder={"Cursos que imparte"}
                  varianteProfesores={varianteProfesores}
                  onChange={(e) => onUpdateCurso(e, index)}
                  onDelete={(e) => onDeleteCurso(e, index)}
                  value={cursoValue}
                  trashActivo
                />
              ))}
            </div>
            <button onClick={onCreateCurso} className="card__boton">
              AGREGAR CURSO
            </button>
          </div>
        </>
      )}
      <ManejarCambios
        disableSave={disableSave}
        discardClick={onDiscardChanges}
        saveClick={onSaveChanges}
      />
    </div>
  );
};

export default CardAdminProfesor;
