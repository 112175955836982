import React, { useState, useEffect } from "react";

import CommonTextInput from "./CommonTextInput";
import ManejarCambios from "./ManejarCambios";
import BackupImage from "../../assets/svg/backup-image.svg";

const PageCollectionListItem = ({
  index,
  itemData,
  disableSave,
  onChangeValue,
  onDeleteItem,
  onSaveItem,
  onDiscardItem,
  routerPathFn,
  routerPathError,
  getValueFn,
  withImage,
  onEditImage,
}) => {
  const [data, setData] = useState({ ...itemData });

  useEffect(() => {
    if (withImage) {
      setData((state) => ({
        ...itemData,
        imageFile: state.imageFile,
      }));
    } else {
      setData(() => ({
        ...itemData,
      }));
    }
  }, [withImage, itemData, setData]);

  return (
    <div className="collection-item__item card" key={index}>
      <CommonTextInput
        editActivo
        trashActivo
        value={getValueFn(data)}
        onChange={(e) => onChangeValue(e, index)}
        onDelete={(e) => onDeleteItem(e, index)}
        placeholder=""
        routerLink={routerPathFn(data)}
        routerLinkError={routerPathError(data)}
      />
      {withImage && (
        <div className="card__body flex-row">
          <img
            src={
              data.imageURL === "" && data.imagen === ""
                ? BackupImage
                : data.imageURL.length > 0
                ? data.imageURL
                : data.imagen
            }
            alt="Imagen de Categoría"
            className="card__imagen"
          ></img>
          <label htmlFor={`categoria-image-${index}`} className="card__label">
            ELEGIR IMAGEN
          </label>
          <input
            id={`categoria-image-${index}`}
            value={""}
            accept="image/x-png,image/jpeg"
            onChange={(e) => onEditImage(e, index)}
            type="file"
            className="card__input"
          />
        </div>
      )}
      <ManejarCambios
        disableSave={disableSave}
        saveClick={(e) => onSaveItem(e, index)}
        discardClick={(e) => onDiscardItem(e, index)}
        noMarginBottom
      />
    </div>
  );
};

export default PageCollectionListItem;
