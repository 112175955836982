import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { auth } from "../../firebase";

import { useAdminModal, useAuth } from "../context/context";
import {
  ALTA,
  ALTA_ADMIN,
  BAJA,
  BAJA_ADMIN,
  CHANGE_PASSWORD,
} from "../context/state/AdminModalState";
import useInputChange from "../lib/hooks/useInputChange";
import { ReactComponent as Cross } from "../Login/cross.svg";

import "./AdminModal.scss";

const AdminModal = ({ close }) => {
  const { type, callback } = useAdminModal();
  const { uid } = useAuth();
  const [formState, setFormState] = useState({
    nombre: "",
    correo: "",
    password: "",
    isAdmin: false,
  });
  const [passwordForm, setPassWordForm] = useState({
    newPassword: "",
    repeatPassword: "",
  });
  const { addToast, removeAllToasts } = useToasts();
  const [fetching, setFetching] = useState(false);
  const queryClient = useQueryClient();

  const onChangeForm = useInputChange(setFormState);
  const onChangePasswordForm = useInputChange(setPassWordForm);

  const onSubmitNewUser = async (e) => {
    e.preventDefault();
    if (formState.password.length < 8) {
      return addToast("La contraseña debe tener 8 o mas caractéres", {
        appearance: "warning",
      });
    }

    addToast("Procesando...", { appearance: "info", autoDismiss: false });

    await fetch("/.netlify/functions/auth", {
      method: "POST",
      body: JSON.stringify({
        action: "addUser",
        payload: {
          uid,
          nombre: formState.nombre,
          correo: formState.correo,
          password: formState.password,
          isAdmin: formState.isAdmin,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          removeAllToasts();
          if (res.users) {
            queryClient.setQueryData(["adminQuery", uid], {
              admin: true,
              usuarios: res.users,
            });
          }
          addToast(
            !res?.error
              ? "Usuario creado"
              : res?.error === "user/no-privilege"
              ? "Error: no tienes privilegios para agregar usuarios"
              : res?.error === "auth/email-already-exists"
              ? "Error: este correo ya esta registrado"
              : res?.error,
            { appearance: !res?.error ? "success" : "error" }
          );
        }

        closeModal();
      })
      .catch((e) => {
        removeAllToasts();
        console.log(e);
        addToast("Error al crear usuario, intente más tarde");
        closeModal();
      });
  };

  const onSubmitRemoval = async (e) => {
    e.persist();
    addToast("Procesando...", { appearance: "info", autoDismiss: false });

    setFetching(() => true);
    await callback().then(() => {
      setTimeout(() => {
        removeAllToasts();
      }, 5000);
    });
    closeModal();
  };

  const onSubmitPasswordChange = (e) => {
    e.preventDefault();

    if (passwordForm.newPassword !== passwordForm.repeatPassword) {
      addToast("Las contraseñas no coinciden", {
        appearance: "error",
      });
      return;
    }

    if (passwordForm.newPassword.length < 8) {
      addToast("Por favor, ingresa una contraseña de 8 o más caracteres", {
        appearance: "error",
      });
      return;
    }

    addToast("Procesando...", { appearance: "info", autoDismiss: false });

    auth.currentUser
      .updatePassword(passwordForm.newPassword)
      .then(() => {
        removeAllToasts();
        addToast("Contraseña cambiada con éxito.", { appearance: "success" });
        closeModal();
      })
      .catch(() => {
        removeAllToasts();
        addToast("Error al cambiar contraseña, intente más tarde", {
          appearance: "error",
        });
        closeModal();
      });
  };

  const closeModal = (e) => {
    if (e) e.persist();

    setFormState(() => ({ nombre: "", correo: "", isAdmin: false }));
    setPassWordForm(() => ({ newPassword: "", repeatPassword: "" }));
    setFetching(() => false);
    close();
  };

  return (
    <section className="admin-modal">
      <div className="admin-modal__container">
        {type === BAJA || type === BAJA_ADMIN || type === ALTA_ADMIN ? (
          <div className="admin-baja">
            <span className="admin-modal__title">
              ¿Estás seguro de que quieres{" "}
              {type === BAJA
                ? "eliminar"
                : type === BAJA_ADMIN
                ? "remover admin"
                : type === ALTA_ADMIN
                ? "hacer admin"
                : ""}
              ?
            </span>
            <div className="admin-modal__buttons">
              <button
                onClick={onSubmitRemoval}
                disabled={fetching}
                className="admin-modal__button mr"
              >
                {type === BAJA
                  ? "Eliminar"
                  : type === BAJA_ADMIN
                  ? "Remover admin"
                  : type === ALTA_ADMIN
                  ? "Hacer admin"
                  : ""}
              </button>
              <button
                disabled={fetching}
                className="admin-modal__button --white"
                onClick={closeModal}
              >
                Cancelar
              </button>
            </div>
          </div>
        ) : type === ALTA ? (
          <div className="admin-modal__alta">
            <span className="admin-modal__subtitle">Alta de usuario</span>
            <form className="admin-modal__form" onSubmit={onSubmitNewUser}>
              <div className="admin-modal__field">
                <label htmlFor="nombre" className="admin-modal__label">
                  Nombre
                </label>
                <input
                  className="admin-modal__input"
                  type="text"
                  value={formState.nombre}
                  onChange={onChangeForm}
                  name="nombre"
                />
              </div>
              <div className="admin-modal__field">
                <label htmlFor="correo" className="admin-modal__label">
                  Correo electrónico
                </label>
                <input
                  className="admin-modal__input"
                  type="email"
                  onChange={onChangeForm}
                  value={formState.correo}
                  name="correo"
                />
              </div>
              <div className="admin-modal__field">
                <label htmlFor="password" className="admin-modal__label">
                  Contraseña
                </label>
                <input
                  className="admin-modal__input"
                  type="password"
                  name="password"
                  onChange={onChangeForm}
                  value={formState.password}
                />
              </div>
              <div className="admin-modal__checkbox">
                <label htmlFor="isAdmin">Admin:</label>
                <input
                  type="checkbox"
                  name="isAdmin"
                  value={formState.isAdmin}
                  checked={formState.isAdmin}
                  onChange={(e) => {
                    e.persist();
                    setFormState((x) => ({ ...x, isAdmin: !x.isAdmin }));
                  }}
                />
              </div>
              <button
                disabled={fetching}
                type="submit"
                className="admin-modal__button --alone"
              >
                Agregar
              </button>
            </form>
          </div>
        ) : type === CHANGE_PASSWORD ? (
          <section className="admin-modal__passwordChange">
            <span className="admin-modal__subtitle">Cambiar contraseña</span>
            <form
              className="admin-modal__form"
              onSubmit={onSubmitPasswordChange}
            >
              <div className="admin-modal__field">
                <label htmlFor="newPassword" className="admin-modal__label">
                  Ingresar nueva contraseña
                </label>
                <input
                  className="admin-modal__input"
                  type="password"
                  value={passwordForm.newPassword}
                  onChange={onChangePasswordForm}
                  name="newPassword"
                />
              </div>
              <div className="admin-modal__field">
                <label htmlFor="repeatPassword" className="admin-modal__label">
                  Confirmar nueva contraseña
                </label>
                <input
                  className="admin-modal__input"
                  type="password"
                  value={passwordForm.repeatPassword}
                  onChange={onChangePasswordForm}
                  name="repeatPassword"
                />
              </div>
              <button
                disabled={fetching}
                type="submit"
                className="admin-modal__button --alone"
              >
                GUARDAR
              </button>
            </form>
          </section>
        ) : null}
        <button
          className="admin-modal__close"
          disabled={fetching}
          onClick={closeModal}
        >
          <Cross />
        </button>
      </div>
    </section>
  );
};

export default AdminModal;
