import React, { useState } from "react";
import classnames from "classnames";

import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar-icon.svg";
import { ReactComponent as TimeIcon } from "../../assets/svg/time-icon.svg";
import { ReactComponent as CircleCheckIcon } from "../../assets/svg/circlecheck-icon.svg";
import refreshArrows from "../../assets/svg/refresh-arrows.svg";

const EventosCard = ({ evento, openModal }) => {
  const [flipActive, setFlipActive] = useState(false);
  return (
    <section className="eventos-card">
      <div className={classnames("eventos-card__inner", { flip: flipActive })}>
        <div className="eventos-card__front flex-column">
          <div
            className="eventos-card__foto"
            style={{ backgroundImage: `url(${evento.imagen})` }}
          ></div>
          <span className="eventos-card__nombre caption3 black">
            {evento.nombre}
          </span>
          <span className="eventos-card__ponente caption5 black">
            Ponente: {evento.ponente}
          </span>
          <span className="eventos-card__fecha caption5 black">
            {evento.fecha}
          </span>
          <span
            className="eventos-card__ver-mas button-primary green"
            onClick={() => setFlipActive(true)}
          >
            Más información
          </span>
        </div>
        <div className="eventos-card__back ">
          <div className="eventos-card__back-scrollable flex-column">
            <span className="eventos-card__back-nombre caption3 black">
              {evento.nombre}
            </span>
            <div className="eventos-card__info">
              <div className="eventos-card__infoItem">
                <CalendarIcon />
                <span className="eventos-card__infoText">
                  Día: {evento.dia}
                </span>
              </div>
              <div className="eventos-card__infoItem">
                <TimeIcon />
                <span className="eventos-card__infoText">
                  Hora: {evento.hora}
                </span>
              </div>
              <div className="eventos-card__infoItem">
                <CircleCheckIcon />
                <span className="eventos-card__infoText">
                  Duración: {evento.duracion}
                </span>
              </div>
            </div>
            <span onClick={openModal} className="eventos-card__back-register">
              Registrarme al evento
            </span>
            <hr className="eventos-card__back-line" />
            <span className="eventos-card__back-subtitle">Descripción:</span>
            <span className="eventos-card__back-ponente">{evento.ponente}</span>
            <span className="eventos-card__back-descripcion">
              {evento.descripcion}
            </span>
          </div>
          <img
            className="eventos-card__refresh-arrows"
            alt="Logo de una presentación"
            src={refreshArrows}
            onClick={() => setFlipActive(false)}
          />
        </div>
      </div>
    </section>
  );
};

export default EventosCard;
