import React from "react";

import "./BoxHeader.scss";

const BoxHeader = ({ children }) => {
  return (
    <div className="box-header">
      <span className="box-header__title">{children}</span>
    </div>
  );
};

export default BoxHeader;
