import React, { useState } from "react";

import classnames from "classnames";

import iconPress from "../../assets/svg/press-icon.svg";
import iconPressYellow from "../../assets/svg/press-icon-yellow.svg";
import refreshArrows from "../../assets/svg/refresh-arrows.svg";
import StarRating from "../StarRating/StarRating";

import "./FlipCardProfesor.scss";

const FlipCardProfesor = ({ profesor, className, gold }) => {
  const [flipActive, setFlipActive] = useState(false);
  return (
    <div className={`flip-card ${className || ""} ${gold ? "gold" : ""}`}>
      <div className={classnames("flip-card__inner", { flip: flipActive })}>
        <div className="flip-card__front flex-column">
          <div
            className="flip-card__foto"
            style={{ backgroundImage: `url(${profesor.imagen})` }}
          ></div>
          <StarRating
            className="flip-card__estrellas"
            rating={profesor.rating}
          />
          <span className="flip-card__nombre caption3 black">
            {profesor.nombre}
          </span>
          <span className="flip-card__puesto caption5 black">
            {profesor.profesion}
          </span>
          <div className="flip-card__cursos flex-row">
            <img
              className="flip-card__press-icon"
              alt="Logo de una presentación"
              src={gold ? iconPressYellow : iconPress}
            />
            <span className="flip-card__numero-cursos caption3">{`${
              profesor.cursos.length
            } curso${profesor.cursos.length > 1 ? "s" : ""}`}</span>
          </div>
          <span
            className="flip-card__ver-mas button-primary green"
            onClick={() => setFlipActive(true)}
          >
            Ver más
          </span>
        </div>
        <div className="flip-card__back ">
          <div className="flip-card__back-scrollable flex-column">
            <span className="flip-card__nombre caption3 black">
              {profesor.nombre}
            </span>
            <span className="flip-card__puesto caption5 black">
              {profesor.profesion}
            </span>
            <div className="flip-card__linea"></div>
            <span className="flip-card__perfil-back caption5 black">
              {profesor.descripcion}
            </span>
            <img
              className="flip-card__press-icon-back"
              alt="Logo de una presentación"
              src={gold ? iconPressYellow : iconPress}
            />
            {profesor?.cursos?.map((curso, index) => (
              <span
                key={index}
                className="flip-card__especialidad-back button-primary green"
              >
                {curso}
              </span>
            ))}
          </div>
          <img
            className="flip-card__refresh-arrows"
            alt="Logo de una presentación"
            src={refreshArrows}
            onClick={() => setFlipActive(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default FlipCardProfesor;
