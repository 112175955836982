import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import StarRating from "../StarRating/StarRating";

import "./CardProfesor.scss";

const CardProfesor = ({ profesores = [], color = "blanco" }) => {
  const history = useHistory();
  return profesores.map((profesor, index) => {
    const classPosicionProfesor =
      index % 2 ? "card-profesor--non" : "card-profesor--par";
    return (
      <div
        className={classnames(`card-profesor`, classPosicionProfesor)}
        key={profesor.nombre}
        onClick={() => history.push("/profesores")}
      >
        <div
          className="card-profesor__foto"
          style={{ backgroundImage: `url(${profesor.imagen})` }}
        ></div>
        <StarRating
          className="card-profesor__estrellas"
          rating={profesor?.rating}
        />
        <span className={`card-profesor__nombre ${color}`}>
          {profesor?.nombre}
        </span>
        <span className={`card-profesor__puesto ${color}`}>
          {profesor?.profesion}
        </span>
      </div>
    );
  });
};

export default CardProfesor;
