import { useQuery } from "react-query";
import { firestore } from "../../firebase";

const useProfesores = () => {
  return useQuery(
    "getProfesores",
    () =>
      firestore
        .collection("colecciones")
        .doc("admin")
        .collection("profesores")
        .get()
        .then((docs) => {
          const array = [];
          docs.forEach((doc) => array.push(doc.data()));
          return array;
        }),
    {
      retry: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  );
};

export default useProfesores;
