import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import useWidthListener from "../../pages/hooks/useWidthListener";

import { ReactComponent as Flecha } from "./flecha.svg";
import { ReactComponent as Bullet } from "./bullet.svg";

import "./UniqueEnfoques.scss";

const setContainerModifier = (size) =>
  size === "mobile" || size === "tablet" ? 1 : size === "laptop" ? 2 : 3;

const UniqueEnfoques = ({
  title = "Plan de Estudio",
  collection = [],
  green,
}) => {
  const screenSize = useWidthListener();
  const [currentSize, setCurrentSize] = useState(screenSize);
  const [vwCount, setVW] = useState(1);
  const [containerCount, setContainerCount] = useState(
    setContainerModifier(screenSize)
  );
  const [isClicked, setClicked] = useState(false);
  const sliderRef = useRef();
  const carouselList = useMemo(() => {
    if (collection.length === 0) return [];
    if (screenSize === "mobile" || screenSize === "tablet") {
      if (collection.length <= 1) return collection;
      const newCollection = [...collection];
      newCollection.push(collection[0] || { spacer: true });
      newCollection.unshift(
        collection[collection.length - 1] || { spacer: true }
      );
      return newCollection;
    }
    if (screenSize === "laptop") {
      const newCollection = [...collection];
      if (collection.length <= 2) return collection;
      if (newCollection.length % 2 !== 0) {
        newCollection.push({ spacer: true });
        newCollection.push(collection[0] || { spacer: true });
        newCollection.push(collection[1] || { spacer: true });
        newCollection.unshift({ spacer: true });
        newCollection.unshift(
          collection[collection.length - 1] || { spacer: true }
        );
      } else {
        newCollection.push(collection[0]);
        newCollection.push(collection[1]);
        newCollection.unshift(
          collection[collection.length - 2] || { spacer: true }
        );
        newCollection.unshift(
          collection[collection.length - 1] || { spacer: true }
        );
      }
      return newCollection;
    }
    if (screenSize === "desktop") {
      if (collection.length <= 3) return collection;
      const newCollection = [...collection];
      if (newCollection.length % 3 === 0) {
        newCollection.push(collection[0] || { spacer: true });
        newCollection.push(collection[1] || { spacer: true });
        newCollection.push(collection[2] || { spacer: true });
        newCollection.unshift(
          collection[collection.length - 3] || { spacer: true }
        );
        newCollection.unshift(
          collection[collection.length - 2] || { spacer: true }
        );
        newCollection.unshift(
          collection[collection.length - 1] || { spacer: true }
        );
      }

      if (newCollection.length % 3 === 1) {
        newCollection.push({ spacer: true });
        newCollection.push({ spacer: true });
        newCollection.push(collection[0] || { spacer: true });
        newCollection.push(collection[1] || { spacer: true });
        newCollection.push(collection[2] || { spacer: true });
        newCollection.unshift({ spacer: true });
        newCollection.unshift({ spacer: true });
        newCollection.unshift(
          collection[collection.length - 1] || { spacer: true }
        );
      }

      if (newCollection.length % 3 === 2) {
        newCollection.push({ spacer: true });
        newCollection.push(collection[0]);
        newCollection.push(collection[1]);
        newCollection.push(collection[2]);
        newCollection.unshift({ spacer: true });
        newCollection.unshift(collection[collection.length - 2]);
        newCollection.unshift(collection[collection.length - 1]);
      }

      return newCollection;
    }
  }, [collection, screenSize]);

  const setContainerSize = (size) =>
    size === "mobile"
      ? 288
      : size === "tablet"
      ? 397
      : size === "laptop"
      ? 818
      : size === "desktop"
      ? 1239
      : 288;

  const untriggerSwipe = () => {
    if (collection.length <= 1) {
      return true;
    }

    if (
      collection.length <= 2 &&
      (screenSize === "laptop" || screenSize === "desktop")
    )
      return true;

    if (collection.length <= 3 && screenSize === "desktop") return true;

    return false;
  };

  const handleRightButton = (e) => {
    e.persist();
    if (untriggerSwipe()) return;

    setClicked(() => true);
    const sliderNode = sliderRef.current;
    const containerSize = setContainerSize(screenSize);

    sliderNode.style.transition = "transform 500ms ease-in-out";
    sliderNode.style.transform = `translateX(calc((${100 * (vwCount + 1)}vw + ${
      containerSize * (vwCount + 1)
    }px) * -1))`;

    setVW((x) => x + 1);
    setContainerCount((x) => x + setContainerModifier(screenSize));
  };

  const handleLeftButton = (e) => {
    e.persist();
    if (untriggerSwipe()) return;

    setClicked(() => true);
    const sliderNode = sliderRef.current;
    const containerSize = setContainerSize(screenSize);

    sliderNode.style.transition = "transform 500ms ease-in-out";
    sliderNode.style.transform = `translateX(calc((${100 * (vwCount - 1)}vw + ${
      containerSize * (vwCount - 1)
    }px) * -1))`;

    setVW((x) => x - 1);
    setContainerCount((x) => x - setContainerModifier(screenSize));
  };

  const noSwiping = useCallback(
    () =>
      (screenSize === "desktop" && collection.length <= 3) ||
      (screenSize === "laptop" && collection.length <= 2) ||
      ((screenSize === "mobile" || screenSize === "tablet") &&
        collection.length <= 1),
    [screenSize, collection]
  );

  const handleTransitionEvent = useCallback(() => {
    if (
      containerCount >=
      carouselList?.length - setContainerModifier(screenSize)
    ) {
      const sliderNode = sliderRef.current;
      const containerSize = setContainerSize(screenSize);

      sliderNode.style.transition = "none";
      sliderNode.style.transform = `translateX(calc((100vw + ${containerSize}px) * -1))`;

      setVW(() => 1);
      setContainerCount(() => setContainerModifier(screenSize));
    }

    if (containerCount <= 0) {
      const sliderNode = sliderRef.current;
      const containerSize = setContainerSize(screenSize);
      const spacing =
        screenSize === "mobile" || screenSize === "tablet"
          ? carouselList.length - 2
          : screenSize === "laptop"
          ? Math.floor(carouselList.length / 2) - 2
          : Math.floor(carouselList.length / 3) - 2;

      sliderNode.style.transition = "none";
      sliderNode.style.transform = `translateX(calc((${100 * spacing}vw + ${
        containerSize * spacing
      }px) * -1))`;

      setVW(() => spacing);
      setContainerCount(() =>
        screenSize === "mobile" || screenSize === "tablet"
          ? spacing
          : screenSize === "laptop"
          ? spacing * 2
          : spacing * 3
      );
    }

    setClicked(() => false);
  }, [carouselList?.length, sliderRef, containerCount, screenSize]);

  const handleResizeEvent = useCallback(() => {
    if (currentSize !== screenSize) {
      setClicked(() => true);
      if (noSwiping()) {
        setClicked(() => false);
        return;
      }
      const sliderNode = sliderRef.current;
      if (!sliderNode) return setClicked(() => false);
      const containerSize = setContainerSize(screenSize);
      sliderNode.style.transition = "none";
      sliderNode.style.transform = `translateX(calc((100vw + ${containerSize}px) * -1))`;
      setCurrentSize(() => screenSize);
      setVW(() => 1);
      setContainerSize(() => setContainerModifier(screenSize));
      setClicked(() => false);
    }
  }, [currentSize, screenSize, sliderRef, noSwiping]);

  useEffect(() => {
    const sliderNode = sliderRef.current;
    sliderNode.addEventListener("transitionend", handleTransitionEvent);
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      sliderNode.removeEventListener("transitionend", handleTransitionEvent);
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [handleTransitionEvent, handleResizeEvent, sliderRef]);

  return (
    <main className={`unique-enfoques ${green ? "green" : ""}`}>
      <h3 className="unique-enfoques__title headline2">{title}</h3>
      <section className="unique-enfoques__carousel">
        <div
          className={`unique-enfoques__slider ${noSwiping() ? "center" : ""}`}
          ref={sliderRef}
        >
          {carouselList?.map((data, index) =>
            data?.spacer ? (
              <div
                className={`unique-enfoques__item spacer `}
                key={index}
              ></div>
            ) : (
              <div className={`unique-enfoques__item `} key={index}>
                <h3 className="unique-enfoques__itemTitle">
                  {data?.subtitulo}
                </h3>
                <div className="unique-enfoques__bullets">
                  {data?.lista?.map((bullet, idx) => (
                    <div key={idx} className="unique-enfoques__bullet">
                      <Bullet />
                      <span>{bullet}</span>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
        {noSwiping() ? null : (
          <div className="unique-enfoques__buttons">
            <button disabled={isClicked} onClick={handleLeftButton}>
              <Flecha />
            </button>
            <button disabled={isClicked} onClick={handleRightButton}>
              <Flecha />
            </button>
          </div>
        )}
      </section>
    </main>
  );
};

export default UniqueEnfoques;
