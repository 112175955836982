import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";

import {
  LineBreak,
  CollectionListSelect,
  PageCollectionList,
  CollectionTitle,
} from "../../components/admin/misc";
import { useCollection, usePages } from "../context/context";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import MBAService from "../lib/services/mba.service";

import "./AdminMBA.scss";

const AdminMBA = () => {
  const { mba, fetchPage, fetchTriggered, setFetchTriggered } = usePages();
  const {
    profesores,
    mbaCollection,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  } = useCollection();
  const [stateMBA, setStateMBA] = useState(_.cloneDeep(mba));
  const [isFetching, setIsFetching] = useState(false);
  const mbaService = useMemo(() => new MBAService(), []);
  const { addToast } = useToasts();
  const mbaPromiseHandler = usePromiseHandler("MBA", setIsFetching);
  const pagePromiseHandler = usePromiseHandler("Página", setIsFetching);
  const profesorPromiseHandler = usePromiseHandler("Profesores", setIsFetching);

  const onChangeMBA = (e, index) => {
    e.persist();
    return setStateMBA((state) => {
      const newMBAS = _.cloneDeep(state.mbas);
      newMBAS[index].nombre = e.target.value;
      newMBAS[index].path = e.target.value
        .normalize("NFD")
        .replace(/\s/g, "-")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      return { ...state, mbas: newMBAS };
    });
  };

  const onDeleteMBA = (e, index) => {
    e.persist();
    const newMBAS = [...stateMBA.mbas];
    const mbaToDelete = { ...newMBAS[index] };
    newMBAS.splice(index, 1);
    mbaPromiseHandler(
      mbaService.deleteMBA(newMBAS, mbaToDelete.fk),
      "eliminado"
    );
  };

  const onSaveMBA = (e, index) => {
    e.persist();
    const collectionID = stateMBA.mbas[index].fk;
    const newPath = stateMBA.mbas[index].path;
    const newShortName = stateMBA.mbas[index].nombre;
    mbaPromiseHandler(
      mbaService.updatePageMBAs(
        stateMBA.mbas,
        collectionID,
        newPath,
        newShortName
      ),
      "actualizado"
    );
  };

  const onCreateMBA = (e) => {
    e.persist();
    mbaPromiseHandler(mbaService.createMBA(stateMBA.mbas), "creado");
  };

  const onDiscardMBA = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newMBAS = [...state.mbas];
      newMBAS[index] = _.cloneDeep(mba.mbas[index]);
      return { ...state, mbas: newMBAS };
    });
  };

  const onAddProfesor = (e) => {
    e.persist();
    if (stateMBA.profesores.length === 3) {
      return addToast("Solo se permiten tres profesores en esta página", {
        appearance: "warning",
      });
    }
    setStateMBA((state) => ({
      ...state,
      profesores: [...state.profesores, ""],
    }));
  };

  const onChangeProfesor = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newProfesores = [...state.profesores];
      newProfesores[index] = e.target.value;
      return { ...state, profesores: newProfesores };
    });
  };

  const onDeleteProfesor = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newProfesores = [...state.profesores];
      newProfesores.splice(index, 1);
      return { ...state, profesores: newProfesores };
    });
  };

  const onDiscardProfesor = (e) => {
    e.persist();
    setStateMBA((state) => ({ ...state, profesores: mba.profesores }));
  };

  const onSaveProfesor = (e) => {
    e.persist();
    const newProfesores = _.cloneDeep(stateMBA.profesores);
    profesorPromiseHandler(
      mbaService.updateProfesorMBAPage(newProfesores),
      "actualizado"
    );
  };

  const onSaveHeaderHandler = (e) => {
    e.persist();
    mbaPromiseHandler(
      mbaService.updatePageHeader({
        titulo: stateMBA.titulo,
        descripcion: stateMBA.descripcion,
      }),
      "actualizado"
    );
  };

  const onChangeHeaderHandler = (e) => {
    e.persist();
    setStateMBA((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onDiscardHeaderHandler = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      titulo: mba.titulo,
      descripcion: mba.descripcion,
    }));
  };

  useEffect(() => {
    if (!mbaCollection.initialFetch) {
      fetchCollection("mbaCollection");
    }
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
    if (!mba.initialFetch) {
      fetchPage("mba");
    }
    if (fetchTriggered || fetchCollectionTriggered) {
      setStateMBA(() => _.cloneDeep(mba));
      setFetchTriggered(() => false);
      setFetchCollectionTriggered(() => false);
    }
  }, [
    mbaCollection.initialFetch,
    profesores.initialFetch,
    mba.initialFetch,
    mba,
    setStateMBA,
    fetchPage,
    fetchCollection,
    fetchTriggered,
    setFetchTriggered,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <section id="admin-mba" className="admin-mba">
      <h2 className="admin-mba__titulo headline3">Posgrados</h2>
      <div className="admin-mba__content">
        <CollectionTitle
          data={{
            titulo: stateMBA.titulo,
            descripcion: stateMBA.descripcion,
          }}
          onSave={onSaveHeaderHandler}
          onDiscardChanges={onDiscardHeaderHandler}
          disableSave={isFetching}
          onChangeInput={onChangeHeaderHandler}
          promiseHandler={pagePromiseHandler}
        />
        <PageCollectionList
          disableSave={isFetching}
          className={"admin-mba__list"}
          items={stateMBA.mbas}
          getValueFn={(x) => x.nombre}
          routerPathFn={(x) => `/admin/posgrados/${x.path}`}
          routerPathError={(x) =>
            mba.mbas.find((y) => y.id === x.id)?.path === ""
          }
          onChangeValue={onChangeMBA}
          onDeleteItem={onDeleteMBA}
          onClickAddItem={onCreateMBA}
          onSaveItem={onSaveMBA}
          onDiscardItem={onDiscardMBA}
        />
        <LineBreak />
        <CollectionListSelect
          disableSave={isFetching}
          title="Profesores"
          onClickAddItem={onAddProfesor}
          onChange={onChangeProfesor}
          onDelete={onDeleteProfesor}
          onSaveChanges={onSaveProfesor}
          onDiscardChanges={onDiscardProfesor}
          values={stateMBA.profesores}
          options={profesores.collection}
          optionValueFn={(x) => x.id}
          optionDisplayFn={(x) => x.nombre}
        />
      </div>
    </section>
  );
};
export default AdminMBA;
