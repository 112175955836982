import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import {
  CollectionItemHeader,
  LineBreak,
  CollectionTitle,
  CollectionListSelect,
  CollectionItemBulletList,
} from "../../components/admin/misc";
import { useCollection } from "../context/context";
import { mMBA } from "../lib/models/mMBA";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import useInputChange from "../lib/hooks/useInputChange";
import MBAService from "../lib/services/mba.service";

import "./AdminMBA.scss";

const AdminMBAPage = () => {
  const { idMBA } = useParams();
  const {
    mbaCollection,
    profesores,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  } = useCollection();
  const [stateMBA, setStateMBA] = useState(
    () => mbaCollection.collection.find((x) => x?.path === idMBA) || mMBA.init()
  );
  const [isFetching, setFetching] = useState(false);
  const mbaService = useMemo(() => new MBAService(), []);
  const currentMBA = useMemo(
    () => mbaCollection.collection.find((x) => x?.path === idMBA),
    [mbaCollection.collection, idMBA]
  );
  const mbaPromiseHandler = usePromiseHandler("MBA", setFetching);
  const profesorPromiseHandler = usePromiseHandler("Profesor", setFetching);
  const enfoquePromiseHandler = usePromiseHandler("Enfoque", setFetching);
  const handleInputChange = useInputChange(setStateMBA);

  const onSaveHeaderChanges = (e) => {
    e.persist();
    mbaPromiseHandler(
      mbaService.updateMBAHeader(currentMBA.id, stateMBA),
      "actualizado"
    );
  };

  const onDiscardHeaderChanges = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      ...mMBA.header(_.cloneDeep(currentMBA)),
    }));
  };

  const onAddProfesor = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      profesores: [...state.profesores, ""],
    }));
  };

  const onChangeProfesor = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newProfesores = [...state.profesores];
      newProfesores[index] = e.target.value;
      return { ...state, profesores: newProfesores };
    });
  };

  const onDeleteProfesor = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newProfesores = [...state.profesores];
      newProfesores.splice(index, 1);
      return { ...state, profesores: newProfesores };
    });
  };

  const onDiscardProfesor = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      profesores: currentMBA.profesores || [],
    }));
  };

  const onSaveProfesor = (e) => {
    e.persist();
    profesorPromiseHandler(
      mbaService.updateMBAProfesores(currentMBA.id, stateMBA),
      "actualizado"
    );
  };

  const onAddOtroMBA = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      otros: { ...state.otros, coleccion: [...state.otros.coleccion, ""] },
    }));
  };

  const onChangeOtroMBA = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newColeccion = [...state.otros.coleccion];
      newColeccion[index] = e.target.value;
      return { ...state, otros: { ...state.otros, coleccion: newColeccion } };
    });
  };

  const onDeleteOtroMBA = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newColeccion = [...state.otros.coleccion];
      newColeccion.splice(index, 1);
      return { ...state, otros: { ...state.otros, coleccion: newColeccion } };
    });
  };

  const onDiscardOtroMBA = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      otros: _.cloneDeep(
        currentMBA?.otros || { titulo: "", descripcion: "", coleccion: [] }
      ),
    }));
  };

  const onSaveOtroMBA = (e) => {
    e.persist();
    mbaPromiseHandler(
      mbaService.updateMBAOtros(currentMBA.id, {
        otros: { ...currentMBA.otros, coleccion: stateMBA.otros.coleccion },
      }),
      "actualizado"
    );
  };

  const onChangeInputOtrosTitle = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      otros: { ...state.otros, [e.target.name]: e.target.value },
    }));
  };

  const onDiscardInputOtrosTitle = (e) => {
    e.persist();
    setStateMBA((state) => ({
      ...state,
      otros: {
        ...state.otros,
        titulo: currentMBA.otros.titulo,
        descripcion: currentMBA.otros.descripcion,
      },
    }));
  };

  const onSaveInputOtrosTitle = (e) => {
    e.persist();
    mbaPromiseHandler(
      mbaService.updateMBAOtros(currentMBA.id, {
        otros: {
          ...currentMBA.otros,
          titulo: stateMBA.otros.titulo,
          descripcion: stateMBA.otros.descripcion,
        },
      })
    );
  };

  const onChangeCollectionTitle = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.titulo = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onChangeBulletSubtitle = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].subtitulo = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onClickAddBullet = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista.push("");
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onChangeBulletItem = (e, index, indexCollection) => {
    e.persist();
    setStateMBA((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista[indexCollection] = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onDeleteBulletItem = (e, index, indexCollection) => {
    e.persist();
    setStateMBA((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista.splice(indexCollection, 1);
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onClickAddBulletSection = (e) => {
    e.persist();
    const newEnfoques = _.cloneDeep(stateMBA.enfoques);
    newEnfoques.coleccion.push(mMBA.initEnfoque());
    enfoquePromiseHandler(
      mbaService.updateMBAEnfoques(currentMBA.id, newEnfoques),
      "creado"
    );
  };

  const onClickDeleteBulletSection = (e, index) => {
    e.persist();
    const newEnfoques = _.cloneDeep(stateMBA.enfoques);
    newEnfoques.coleccion.splice(index, 1);
    enfoquePromiseHandler(
      mbaService.updateMBAEnfoques(currentMBA.id, newEnfoques),
      "eliminado"
    );
  };

  const onSaveBulletClick = (e, index) => {
    e.persist();
    const enfoquesToSave = _.cloneDeep(currentMBA.enfoques);
    enfoquesToSave.titulo = stateMBA.enfoques.titulo;
    enfoquesToSave.coleccion[index] = _.cloneDeep(
      stateMBA.enfoques.coleccion[index]
    );
    enfoquePromiseHandler(
      mbaService.updateMBAEnfoques(currentMBA.id, enfoquesToSave),
      "actualizado"
    );
  };

  const onDiscardBulletClick = (e, index) => {
    e.persist();
    setStateMBA((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index] = _.cloneDeep(
        currentMBA.enfoques.coleccion[index]
      );
      return { ...state, enfoques: newEnfoques };
    });
  };

  useEffect(() => {
    if (!mbaCollection.initialFetch) {
      fetchCollection("mbaCollection");
    }
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
    if (fetchCollectionTriggered) {
      setStateMBA(
        () =>
          mbaCollection.collection.find((x) => x?.path === idMBA) || mMBA.init()
      );
      setFetchCollectionTriggered(() => false);
    }
  }, [
    mbaCollection.collection,
    setStateMBA,
    idMBA,
    mbaCollection.initialFetch,
    fetchCollection,
    profesores.initialFetch,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <section className="mba-page collection-item">
      <CollectionItemHeader
        values={mMBA.header(stateMBA)}
        onChangeInput={handleInputChange}
        disableSave={isFetching}
        onSaveChanges={onSaveHeaderChanges}
        onDiscardChanges={onDiscardHeaderChanges}
      />
      <LineBreak />
      <CollectionItemBulletList
        titleBullet="Enfoque"
        collectionTitle={stateMBA.enfoques.titulo}
        collection={stateMBA.enfoques.coleccion}
        disableSave={isFetching}
        onChangeTitle={onChangeCollectionTitle}
        onChangeSubtitle={onChangeBulletSubtitle}
        onClickAddBullet={onClickAddBullet}
        onClickAddSection={onClickAddBulletSection}
        onClickDeleteSection={onClickDeleteBulletSection}
        onChangeBullet={onChangeBulletItem}
        onDeleteBullet={onDeleteBulletItem}
        saveBulletClick={onSaveBulletClick}
        discardBulletClick={onDiscardBulletClick}
      />
      <LineBreak />
      <CollectionListSelect
        title="Profesores"
        disableSave={isFetching}
        onClickAddItem={onAddProfesor}
        onChange={onChangeProfesor}
        onDelete={onDeleteProfesor}
        onSaveChanges={onSaveProfesor}
        onDiscardChanges={onDiscardProfesor}
        values={stateMBA.profesores}
        options={profesores.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
      />
      <LineBreak />
      <CollectionTitle
        data={{
          titulo: stateMBA.otros.titulo,
          descripcion: stateMBA.otros.descripcion,
        }}
        disableSave={isFetching}
        onChangeInput={onChangeInputOtrosTitle}
        onDiscardChanges={onDiscardInputOtrosTitle}
        onSave={onSaveInputOtrosTitle}
      />
      <CollectionListSelect
        title="Otros Posgrados"
        className="mba-page__listSelect"
        disableSave={isFetching}
        values={stateMBA.otros.coleccion}
        options={mbaCollection.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
        onClickAddItem={onAddOtroMBA}
        onChange={onChangeOtroMBA}
        onDelete={onDeleteOtroMBA}
        onSaveChanges={onSaveOtroMBA}
        onDiscardChanges={onDiscardOtroMBA}
      />
    </section>
  );
};

export default AdminMBAPage;
