import React from "react";

const BotonAgregar = ({ onClick, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={`boton-agregar ${className}`}
    >
      <span className="boton-agregar__span">+</span> AGREGAR
    </button>
  );
};

export default BotonAgregar;
