import { useQuery } from "react-query";
import { firestore } from "../../firebase";
import fetchCollection from "../../Utils/fetchCollection";

const useTaller = (key) => {
  return useQuery(["getTaller", key], () =>
    firestore
      .collection("colecciones")
      .doc("admin")
      .collection("talleresCollection")
      .where("path", "==", key)
      .get()
      .then(async (docs) => {
        const talleres = [];
        docs.forEach((d) => talleres.push({ id: d.id, ...d.data() }));
        if (!talleres[0]) return { notFound: true };

        const docData = talleres[0];
        const profesores = await fetchCollection(
          docData.profesores,
          "profesores"
        );
        const otrosCursos = [
          ...(await fetchCollection(
            docData.otros.coleccion,
            "cursosCollection"
          )),
          ...(await fetchCollection(
            docData.otros.coleccion,
            "tallerescollection"
          )),
        ].filter((x) => x);

        return { ...docData, profesores, otrosCursos };
      })
  );
};

export default useTaller;
