import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import GenericLinks from "../../components/GenericLinkList/GenericLinks";
import GenericTenemos from "../../components/GenericTenemos/GenericTenemos";

import HubaLogo from "../../assets/img/huba.png";
import SinergiaLogo from "../../assets/img/sinergia.png";
import SonataCowork from "../../assets/img/sonata-cowork.png";

import "./Diplomados.scss";
import "../../components/GenericTenemos/GenericTenemos.scss";

const Diplomados = ({ data }) => {
  return (
    <main className="diplomados">
      <Header />
      <GenericHeader
        title={data?.titulo}
        description={data?.descripcion}
        type={"diplomados"}
      />
      <GenericLinks
        title={data?.seccionDiplomados?.titulo}
        subtitle={data?.seccionDiplomados?.descripcion}
        route="/diplomados"
        list={data?.diplomados}
      />
      <GenericTenemos
        categorias={data?.categorias}
        categoriasTitle={data?.seccionCategorias?.titulo}
        categoriasSubtitle={data?.seccionCategorias?.descripcion}
        profesoresTitle="Nuestros profesores"
        profesoresSubtitle="Te ofrecemos los mejores profesores a nivel internacional para que cumplas tus objetivos de mano de los expertos."
        profesores={data?.profesores}
        withProfesores={false}
      />
      <div className="tenemos__fondo">
        <div className="tenemos__fondo__profesores-cards topped">
          <div className="tenemos__fondo__profesores-cards__profesores grid-uno topped">
            <h4>Nuestras alianzas</h4>
            <p className="tenemos__fondo__profesores-cards__profesores-p">
              Nuestros aliados, contribuyen en la generación de espacios de
              trabajo innovadores así como en el desarrollo de la creatividad y
              la generación de experiencias que fomentan el networking.
            </p>
          </div>
          <div className="card-profesor card-profesor--par">
            <div
              className="card-profesor__foto"
              style={{ backgroundImage: `url(${SinergiaLogo})` }}
            ></div>
          </div>
          <div class="card-profesor card-profesor--non">
            <div
              className="card-profesor__foto"
              style={{ backgroundImage: `url(${HubaLogo})` }}
            ></div>
          </div>
          <div class="card-profesor card-profesor--par">
            <div
              className="card-profesor__foto"
              style={{ backgroundImage: `url(${SonataCowork})` }}
            ></div>
          </div>
        </div>
      </div>

      <Footer />
    </main>
  );
};

export default Diplomados;
