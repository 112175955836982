import useQueryData from "./useQueryData";

const useAllQueryData = () => {
  const eventos = useQueryData("getEventos");
  const diplomados = useQueryData("getDiplomados");
  const cursos = useQueryData("getCursos");
  const mbas = useQueryData("getMBAS");
  const profesores = useQueryData("getProfesores");

  return { eventos, diplomados, cursos, mbas, profesores };
};

export default useAllQueryData;
