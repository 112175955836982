import React from "react";

import Footer from "../../components/Footer/Footer";
import GenericHeader from "../../components/GenericHeader/GenericHeader";
import GenericLinks from "../../components/GenericLinkList/GenericLinks";
import GenericTenemos from "../../components/GenericTenemos/GenericTenemos";
import Header from "../../components/Header/Header";

import { ReactComponent as Ribbon } from "../../assets/svg/ribbon-mbas.svg";
import { ReactComponent as First } from "./svg/1.svg";
import { ReactComponent as Second } from "./svg/2.svg";
import { ReactComponent as Third } from "./svg/3.svg";
import { ReactComponent as Fourth } from "./svg/4.svg";
import { ReactComponent as Fifth } from "./svg/5.svg";
import { ReactComponent as Sixth } from "./svg/6.svg";
import { ReactComponent as Seventh } from "./svg/7.svg";
import { ReactComponent as Eighth } from "./svg/8.svg";

import "./MBA.scss";

const MBA = ({ data }) => {
  return (
    <main className="mba-home">
      <Header />
      <GenericHeader
        title={data?.titulo}
        description={data?.descripcion}
        type="mba"
        isMBA
      />
      <span className="mba-home__ribbon">
        <Ribbon />
      </span>
      <GenericLinks
        gold
        title="Nuestros MBA"
        subtitle="Estudiar nuestro programa en inQba Escuela de Negocios cambiará la visión actual de tu empresa, proyectándola a futuros modelos de negocios innovadores que actualmente el país necesita."
        route="/posgrados"
        list={data?.mbas}
      />
      <h3 className="mba-home__title">¿Qué significa “Drive Your Business”?</h3>
      <section className="mba-home__grid">
        <div className="mba-home__grid-item">
          <First />
          <span>
            Conocer el funcionamiento de áreas medulares de la empresa.
          </span>
        </div>
        <div className="mba-home__grid-item">
          <Second />
          <span>Tomar decisiones de forma asertiva.</span>
        </div>
        <div className="mba-home__grid-item">
          <Third />
          <span>Detectar y contrarrestar amenazas.</span>
        </div>
        <div className="mba-home__grid-item">
          <Fourth />
          <span>Administrar riesgos.</span>
        </div>
        <div className="mba-home__grid-item">
          <Fifth />
          <span>Formalizar procesos.</span>
        </div>
        <div className="mba-home__grid-item">
          <Sixth />
          <span>Crear y dirigir empresas.</span>
        </div>
        <div className="mba-home__grid-item">
          <Seventh />
          <span>
            Crear o desarrollar el valor de la empresa. Detección y
            aprovechamiento de oportunidades de crecimiento sostenible.
          </span>
        </div>
        <div className="mba-home__grid-item">
          <Eighth />
          <span>Conocer e implementar prácticas para la innovación</span>
        </div>
      </section>
      <GenericTenemos
        gold
        profesoresTitle="Nuestros profesores"
        profesoresSubtitle="Te ofrecemos los mejores profesores a nivel internacional para que cumplas tus objetivos de mano de los expertos."
        profesores={data?.profesores}
      />
      <Footer />
    </main>
  );
};

export default MBA;
