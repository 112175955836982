import React, { useState } from "react";
import { AdminModalContext } from "../context";

export const [ALTA, BAJA, BAJA_ADMIN, ALTA_ADMIN, CHANGE_PASSWORD] = [
  "ALTA",
  "BAJA",
  "BAJA_ADMIN",
  "ALTA_ADMIN",
  "CHANGE_PASSWORD",
];

const AdminModalState = ({ children }) => {
  const [{ type, callback }, setState] = useState({
    type: ALTA,
    callback: () => {},
  });

  return (
    <AdminModalContext.Provider
      value={{
        type,
        callback,
        setModalState: setState,
      }}
    >
      {children}
    </AdminModalContext.Provider>
  );
};

export default AdminModalState;
