import React, { useMemo } from "react";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";

import CardProfesor from "../CardProfesor/CardProfesor";
import UseInViewOnce from "../../pages/hooks/useInViewOnce";
import { splitCategories } from "../../Utils/splitCategories";

import "./GenericTenemos.scss";

const GenericTenemos = ({
  categorias = [],
  profesores = [],
  categoriasTitle = "",
  categoriasSubtitle = "",
  profesoresTitle = "",
  profesoresSubtitle = "",
  routerFn = null,
  gold,
  withProfesores = true,
}) => {
  const [ref2, ref2InView] = UseInViewOnce();
  const splittedCategories = useMemo(() => splitCategories(categorias), [
    categorias,
  ]);
  const history = useHistory();
  const navigate = (e, data) => {
    if (!routerFn) return;
    e.persist();

    history.push(routerFn(data));
  };

  return (
    <section className="tenemos">
      {categorias?.length > 0 && (
        <>
          <h3>{categoriasTitle}</h3>
          <p className="tenemos-p">{categoriasSubtitle}</p>
        </>
      )}
      <div className="tenemos__fondo">
        {categorias?.length > 0 && (
          <div className="tenemos__fondo__cards">
            {splittedCategories.map((categoria, index) => (
              <div key={index} className={`tenemos__fondo__cards__card-dupla`}>
                <div
                  className="tenemos__fondo__cards__card-dupla__card"
                  style={{
                    backgroundImage: `url(${categoria?.[0]?.imagen})`,
                  }}
                  onClick={(e) => navigate(e, categoria?.[0])}
                >
                  <div className="tenemos__fondo__cards__card-dupla__card--mask">
                    {categoria?.[0]?.nombre}
                  </div>
                </div>
                {categoria?.[1] ? (
                  <div
                    className="tenemos__fondo__cards__card-dupla__card"
                    style={{
                      backgroundImage: `url(${categoria?.[1]?.imagen})`,
                    }}
                    onClick={(e) => navigate(e, categoria?.[1])}
                  >
                    <div className="tenemos__fondo__cards__card-dupla__card--mask">
                      {categoria?.[1]?.nombre}
                    </div>
                  </div>
                ) : (
                  <div className="tenemos__fondo__cards__card-dupla__card spacer" />
                )}
              </div>
            ))}
          </div>
        )}
        {profesores?.length > 0 && withProfesores && (
          <div
            className={classnames("tenemos__fondo__profesores-cards", {
              topped: categorias?.length % 2 === 0 && categorias?.length !== 0,
            })}
          >
            <div
              className={classnames(
                "tenemos__fondo__profesores-cards__profesores grid-uno",
                {
                  topped:
                    categorias?.length % 2 === 0 && categorias?.length !== 0,
                  isMBA: gold,
                }
              )}
            >
              <h4>{profesoresTitle}</h4>
              <p className="tenemos__fondo__profesores-cards__profesores-p">
                {profesoresSubtitle}
              </p>
              <Link
                to="/profesores"
                ref={ref2}
                className={
                  ref2InView
                    ? `inscribete ${
                        gold ? "gold" : ""
                      } animate__animated animate__bounce animate__infinite  animate__slow`
                    : ""
                }
              >
                Ver más profesores
              </Link>
            </div>
            <CardProfesor profesores={profesores} color="gris" />
          </div>
        )}
      </div>
    </section>
  );
};

export default GenericTenemos;
