import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import UseInViewOnce from "../../pages/hooks/useInViewOnce";
import BoxHeader from "../BoxHeader/BoxHeader";

import "./GenericHeader.scss";

const GenericHeader = ({ title, description, type, isMBA, intro }) => {
  const [isBouncing, setIsBouncing] = useState("fadeInUp");
  const [ref, refInView] = UseInViewOnce();
  const history = useHistory();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsBouncing("bounce animate__infinite  animate__slow");
    }, 2500);
    return () => {
      window.clearTimeout(timeOut);
    };
  }, []);

  return (
    <section className={`generic-header ${type}`}>
      <BoxHeader>{title}</BoxHeader>
      <span className="generic-header__subtitle subtitle1">{description}</span>
      {isMBA && (
        <span className="generic-header__mbaSubtitle">Drive Your Business</span>
      )}
      <span
        onClick={() => {
          history.push("/contacto");
        }}
        ref={ref}
        className={`
        inscribete
        ${isMBA ? "gold" : ""}
        ${refInView ? ` animate__animated animate__${isBouncing}` : ""}
        `}
      >
        Inscríbete Ahora
      </span>
    </section>
  );
};

export default GenericHeader;
