import FirestoreService from "./firestore.service";

export default class EventosService extends FirestoreService {
  constructor() {
    super();
    this.pageRef = this.fs.collection("paginas").doc("eventos");
  }

  updateCollection(coleccion) {
    return this.pageRef.set({ coleccion });
  }
  updatePageSection(seccion) {
    return this.pageRef.set(seccion, { merge: true });
  }
}
