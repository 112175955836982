import { createContext, useContext } from "react";

export const PagesContext = createContext({
  fetchPage: (key) => {},
  home: {
    initialFetch: false,
    id: "",
    mbas: [],
    categorias: [],
    profesores: [],
    testimonios: [{ id: "", nombre: "", profesion: "", testimonio: "" }],
  },
  mba: {
    initialFetch: false,
    id: "",
    titulo: "",
    descripcion: "",
    mbas: [],
    profesores: [],
  },
  cursos: {
    initialFetch: false,
    id: "",
    titulo: "",
    descripcion: "",
    seccionCursos: {
      titulo: "",
      descripcion: "",
    },
    cursos: [],
    seccionTalleres: {
      titulo: "",
      descripcion: "",
    },
    talleres: [],
  },
  diplomados: {
    id: "",
    initialFetch: false,
    titulo: "",
    descripcion: "",
    seccionDiplomados: {
      titulo: "",
      descripcion: "",
    },
    diplomados: [],
    seccionCategorias: {
      titulo: "",
      descripcion: "",
    },
    categorias: [],
    profesores: [],
  },
  profesores: {
    initialFetch: false,
    id: "",
  },
  contacto: {
    initialFetch: false,
    id: "",
    email: "",
    telefono: "",
    whatsapp: "",
    maps: "",
    link: "",
  },
  footer: {
    initialFetch: false,
    id: "",
    facebook: "",
    instagram: "",
    whatsapp: "",
  },
  eventos: {
    initialFetch: false,
    coleccion: [],
  },
  fetchTriggered: false,
  setFetchTriggered: () => {},
});

export const CollectionContext = createContext({
  fetchCollection: (key) => {},
  mbaCollection: {
    initialFetch: false,
    collection: [],
  },
  profesores: {
    initialFetch: false,
    collection: [],
  },
  diplomadosCollection: {
    initialFetch: false,
    collection: [],
  },
  cursosCollection: {
    initialFetch: false,
    collection: [],
  },
  talleresCollection: {
    initialFetch: false,
    collection: [],
  },
  fetchCollectionTriggered: false,
  setFetchCollectionTriggered: () => {},
});

export const AuthContext = createContext({
  nombre: "",
  correo: "",
  uid: "",
  isAuthenticated: false,
  data: { admin: false, usuarios: [] },
  setAuthState: () => {},
});

export const AdminModalContext = createContext({
  type: "",
  callback: () => {},
  setModalState: () => {},
});

export const useAdminModal = () => useContext(AdminModalContext);
export const useAuth = () => useContext(AuthContext);
export const usePages = () => useContext(PagesContext);
export const useCollection = () => useContext(CollectionContext);
