import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import {
  LineBreak,
  CollectionListSelect,
  CollectionItemHeader,
  CollectionItemBulletList,
  CollectionTitle,
} from "../../components/admin/misc";
import { useCollection } from "../context/context";
import { mMBA } from "../lib/models/mMBA";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import useInputChange from "../lib/hooks/useInputChange";
import DiplomadosService from "../lib/services/diplomados.service";

import "./AdminDiplomados.scss";

const AdminDiplomadosPage = () => {
  const { idDiplomado } = useParams();
  const {
    diplomadosCollection,
    profesores,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  } = useCollection();
  const [stateDiplomado, setStateDiplomado] = useState(
    () =>
      diplomadosCollection.collection.find((x) => x?.path === idDiplomado) ||
      mMBA.init()
  );
  const [isFetching, setFetching] = useState(false);
  const diplomadoService = useMemo(() => new DiplomadosService(), []);
  const currentDiplomado = useMemo(
    () => diplomadosCollection.collection.find((x) => x?.path === idDiplomado),
    [diplomadosCollection.collection, idDiplomado]
  );
  const diplomadoPromiseHandler = usePromiseHandler("Diplomado", setFetching);
  const profesorPromiseHandler = usePromiseHandler("Profesor", setFetching);
  const enfoquePromiseHandler = usePromiseHandler("Enfoque", setFetching);
  const handleInputChange = useInputChange(setStateDiplomado);

  const onSaveHeaderChanges = (e) => {
    e.persist();
    diplomadoPromiseHandler(
      diplomadoService.updateDiplomadoHeader(
        currentDiplomado.id,
        stateDiplomado
      ),
      "actualizado"
    );
  };

  const onDiscardHeaderChanges = (e) => {
    e.persist();
    console.log(currentDiplomado);
    setStateDiplomado((state) => ({
      ...state,
      ...mMBA.header(_.cloneDeep(currentDiplomado)),
    }));
  };

  const onAddProfesor = (e) => {
    e.persist();
    setStateDiplomado((state) => ({
      ...state,
      profesores: [...state.profesores, ""],
    }));
  };

  const onChangeProfesor = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newProfesores = [...state.profesores];
      newProfesores[index] = e.target.value;
      return { ...state, profesores: newProfesores };
    });
  };

  const onDeleteProfesor = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newProfesores = [...state.profesores];
      newProfesores.splice(index, 1);
      return { ...state, profesores: newProfesores };
    });
  };

  const onDiscardProfesor = (e) => {
    e.persist();
    setStateDiplomado((state) => ({
      ...state,
      profesores: currentDiplomado.profesores || [],
    }));
  };

  const onSaveProfesor = (e) => {
    e.persist();
    profesorPromiseHandler(
      diplomadoService.updateDiplomadoProfesores(
        currentDiplomado.id,
        stateDiplomado
      ),
      "actualizado"
    );
  };

  const onAddOtroMBA = (e) => {
    e.persist();
    setStateDiplomado((state) => ({
      ...state,
      otros: { ...state.otros, coleccion: [...state.otros.coleccion, ""] },
    }));
  };

  const onChangeOtroMBA = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newColeccion = [...state.otros.coleccion];
      newColeccion[index] = e.target.value;
      return { ...state, otros: { ...state.otros, coleccion: newColeccion } };
    });
  };

  const onDeleteOtroMBA = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newColeccion = [...state.otros.coleccion];
      newColeccion.splice(index, 1);
      return { ...state, otros: { ...state.otros, coleccion: newColeccion } };
    });
  };

  const onDiscardOtroMBA = (e) => {
    e.persist();
    setStateDiplomado((state) => ({
      ...state,
      otros: _.cloneDeep(
        currentDiplomado?.otros || {
          titulo: "",
          descripcion: "",
          coleccion: [],
        }
      ),
    }));
  };

  const onSaveOtroMBA = (e) => {
    e.persist();
    diplomadoPromiseHandler(
      diplomadoService.updateDiplomadoOtros(currentDiplomado.id, {
        otros: {
          ...currentDiplomado.otros,
          coleccion: stateDiplomado.otros.coleccion,
        },
      }),
      "actualizado"
    );
  };

  const onChangeInputOtrosTitle = (e) => {
    e.persist();
    setStateDiplomado((state) => ({
      ...state,
      otros: { ...state.otros, [e.target.name]: e.target.value },
    }));
  };

  const onDiscardInputOtrosTitle = (e) => {
    e.persist();
    setStateDiplomado((state) => ({
      ...state,
      otros: {
        ...state.otros,
        titulo: currentDiplomado.otros.titulo,
        descripcion: currentDiplomado.otros.descripcion,
      },
    }));
  };

  const onSaveInputOtrosTitle = (e) => {
    e.persist();
    diplomadoPromiseHandler(
      diplomadoService.updateDiplomadoOtros(currentDiplomado.id, {
        otros: {
          ...currentDiplomado.otros,
          titulo: stateDiplomado.otros.titulo,
          descripcion: stateDiplomado.otros.descripcion,
        },
      }),
      "actualizado"
    );
  };

  const onChangeCollectionTitle = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.titulo = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onChangeBulletSubtitle = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].subtitulo = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onClickAddBullet = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista.push("");
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onChangeBulletItem = (e, index, indexCollection) => {
    e.persist();
    setStateDiplomado((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista[indexCollection] = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onDeleteBulletItem = (e, index, indexCollection) => {
    e.persist();
    setStateDiplomado((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista.splice(indexCollection, 1);
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onClickAddBulletSection = (e) => {
    e.persist();
    const newEnfoques = _.cloneDeep(stateDiplomado.enfoques);
    newEnfoques.coleccion.push(mMBA.initEnfoque());
    enfoquePromiseHandler(
      diplomadoService.updateDiplomadoEnfoques(
        currentDiplomado.id,
        newEnfoques
      ),
      "creado"
    );
  };

  const onClickDeleteBulletSection = (e, index) => {
    e.persist();
    const newEnfoques = _.cloneDeep(stateDiplomado.enfoques);
    newEnfoques.coleccion.splice(index, 1);
    enfoquePromiseHandler(
      diplomadoService.updateDiplomadoEnfoques(
        currentDiplomado.id,
        newEnfoques
      ),
      "eliminado"
    );
  };

  const onSaveBulletClick = (e, index) => {
    e.persist();
    const enfoquesToSave = _.cloneDeep(currentDiplomado.enfoques);
    enfoquesToSave.titulo = stateDiplomado.enfoques.titulo;
    enfoquesToSave.coleccion[index] = _.cloneDeep(
      stateDiplomado.enfoques.coleccion[index]
    );
    enfoquePromiseHandler(
      diplomadoService.updateDiplomadoEnfoques(
        currentDiplomado.id,
        enfoquesToSave
      ),
      "actualizado"
    );
  };

  const onDiscardBulletClick = (e, index) => {
    e.persist();
    setStateDiplomado((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index] = _.cloneDeep(
        currentDiplomado.enfoques.coleccion[index]
      );
      return { ...state, enfoques: newEnfoques };
    });
  };

  useEffect(() => {
    if (!diplomadosCollection.initialFetch) {
      fetchCollection("diplomadosCollection");
    }
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
    if (fetchCollectionTriggered) {
      setStateDiplomado(
        () =>
          diplomadosCollection.collection.find(
            (x) => x?.path === idDiplomado
          ) || mMBA.init()
      );
      setFetchCollectionTriggered(() => false);
    }
  }, [
    diplomadosCollection.collection,
    setStateDiplomado,
    idDiplomado,
    diplomadosCollection.initialFetch,
    fetchCollection,
    profesores.initialFetch,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <section className="diplomados-page collection-item">
      <CollectionItemHeader
        values={mMBA.header(stateDiplomado)}
        placeHolderNombre="Nombre del diplomado"
        onChangeInput={handleInputChange}
        disableSave={isFetching}
        onSaveChanges={onSaveHeaderChanges}
        onDiscardChanges={onDiscardHeaderChanges}
      />
      <LineBreak />
      <CollectionItemBulletList
        placeholderTitle="Enfoques del diplomado"
        titleBullet="Enfoque"
        collectionTitle={stateDiplomado.enfoques.titulo}
        collection={stateDiplomado.enfoques.coleccion}
        disableSave={isFetching}
        onChangeTitle={onChangeCollectionTitle}
        onChangeSubtitle={onChangeBulletSubtitle}
        onClickAddBullet={onClickAddBullet}
        onClickAddSection={onClickAddBulletSection}
        onClickDeleteSection={onClickDeleteBulletSection}
        onChangeBullet={onChangeBulletItem}
        onDeleteBullet={onDeleteBulletItem}
        saveBulletClick={onSaveBulletClick}
        discardBulletClick={onDiscardBulletClick}
      />
      <LineBreak />
      <CollectionListSelect
        title="Profesores"
        disableSave={isFetching}
        onClickAddItem={onAddProfesor}
        onChange={onChangeProfesor}
        onDelete={onDeleteProfesor}
        onSaveChanges={onSaveProfesor}
        onDiscardChanges={onDiscardProfesor}
        values={stateDiplomado.profesores}
        options={profesores.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
      />
      <LineBreak />
      <CollectionTitle
        data={{
          titulo: stateDiplomado.otros.titulo,
          descripcion: stateDiplomado.otros.descripcion,
        }}
        disableSave={isFetching}
        onChangeInput={onChangeInputOtrosTitle}
        onDiscardChanges={onDiscardInputOtrosTitle}
        onSave={onSaveInputOtrosTitle}
      />
      <CollectionListSelect
        title="Otros Diplomados"
        className="diplomados-page__listSelect"
        disableSave={isFetching}
        values={stateDiplomado.otros.coleccion}
        options={diplomadosCollection.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
        onClickAddItem={onAddOtroMBA}
        onChange={onChangeOtroMBA}
        onDelete={onDeleteOtroMBA}
        onSaveChanges={onSaveOtroMBA}
        onDiscardChanges={onDiscardOtroMBA}
      />
    </section>
  );
};

export default AdminDiplomadosPage;
