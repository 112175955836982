export const mDiplomados = ({
  titulo,
  descripcion,
  seccionDiplomados,
  diplomados,
  seccionCategorias,
  categorias,
  profesores,
}) => ({
  titulo,
  descripcion,
  seccionDiplomados,
  diplomados,
  seccionCategorias,
  categorias,
  profesores,
});

mDiplomados.initDiplomado = () => ({
  path: "",
  nombre: "",
  intro: "",
  duracion: "",
  modalidad: "",
  horario: "",
  inicio: "",
  enfoques: { titulo: "", coleccion: [] },
  profesores: [],
  otros: { titulo: "", descripcion: "", coleccion: [] },
  date: new Date(),
});
