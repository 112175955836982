import FirestoreService from "./firestore.service";

export default class ContactoService extends FirestoreService {
  constructor() {
    super();
    this.collectionRef = this.fs.collection("paginas").doc("contacto");
  }

  updateContacto(contacto) {
    return this.collectionRef.set(contacto);
  }
}
