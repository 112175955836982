import React from "react";

const LocationIcon = ({ color = "black", width = "25", height = "32" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 31.0754L13.4044 30.2857C21.0095 23.6451 24.875 17.8468 24.875 12.75C24.875 5.53216 19.2416 0.375 12.5 0.375C5.75845 0.375 0.125 5.53216 0.125 12.75C0.125 17.8468 3.99055 23.6451 11.5956 30.2857L12.5 31.0754ZM12.5 27.4122C6.04659 21.5961 2.875 16.6677 2.875 12.75C2.875 7.10783 7.22566 3.125 12.5 3.125C17.7743 3.125 22.125 7.10783 22.125 12.75C22.125 16.6677 18.9534 21.5961 12.5 27.4122ZM12.5 5.875C16.297 5.875 19.375 8.95304 19.375 12.75C19.375 16.547 16.297 19.625 12.5 19.625C8.70304 19.625 5.625 16.547 5.625 12.75C5.625 8.95304 8.70304 5.875 12.5 5.875ZM8.375 12.75C8.375 10.4718 10.2218 8.625 12.5 8.625C14.7782 8.625 16.625 10.4718 16.625 12.75C16.625 15.0282 14.7782 16.875 12.5 16.875C10.2218 16.875 8.375 15.0282 8.375 12.75Z"
        fill={color}
      />
    </svg>
  );
};

export default LocationIcon;
