import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";

import {
  LineBreak,
  PageCollectionList,
  CollectionTitle,
} from "../../components/admin/misc";
import { useCollection, usePages } from "../context/context";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import useInputChange from "../lib/hooks/useInputChange";
import CursosService from "../lib/services/cursos.service";
import { genHash } from "../../Utils/genHash";
import StorageService from "../lib/services/storage.service";

import "./AdminCursos.scss";

const AdminCursos = () => {
  const { cursos, fetchPage, fetchTriggered, setFetchTriggered } = usePages();
  const {
    cursosCollection,
    talleresCollection,
    fetchCollection,
    setFetchCollectionTriggered,
    fetchCollectionTriggered,
  } = useCollection();
  const [stateCursos, setStateCursos] = useState(
    ((x) => ({
      ...x,
      talleres: x.talleres.map((taller) => ({
        ...taller,
        imageFile: null,
        imageURL: "",
      })),
    }))(cursos)
  );
  const [isFetching, setIsFetching] = useState(false);
  const cursosService = useMemo(() => new CursosService(), []);
  const storage = useMemo(() => new StorageService(), []);
  const { addToast } = useToasts();
  const pagePromiseHandler = usePromiseHandler("Página", setIsFetching);
  const cursosPromiseHandler = usePromiseHandler("Curso", setIsFetching);
  const talleresPromiseHandler = usePromiseHandler("Taller", setIsFetching);
  const inputChangeHandler = useInputChange(setStateCursos);

  const onChangeCurso = (e, index) => {
    e.persist();
    setStateCursos((state) => {
      const newCursos = _.cloneDeep(state.cursos);
      newCursos[index].nombre = e.target.value;
      newCursos[index].path = e.target.value
        .normalize("NFD")
        .replace(/\s/g, "-")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      return { ...state, cursos: newCursos };
    });
  };

  const onDeleteCurso = (e, index) => {
    e.persist();
    const newCursos = [...stateCursos.cursos];
    const cursoToDelete = { ...newCursos[index] };
    newCursos.splice(index, 1);
    cursosPromiseHandler(
      cursosService.onDeleteCurso(newCursos, cursoToDelete.fk),
      "eliminado"
    );
  };

  const onSaveCurso = (e, index) => {
    e.persist();
    const collectionID = stateCursos.cursos[index].fk;
    const newPath = stateCursos.cursos[index].path;
    cursosPromiseHandler(
      cursosService.updatePageCursos(stateCursos.cursos, collectionID, newPath),
      "actualizado"
    );
  };

  const onCreateCurso = (e) => {
    e.persist();
    cursosPromiseHandler(
      cursosService.createCurso(stateCursos.cursos),
      "creado"
    );
  };

  const onDiscardCurso = (e, index) => {
    e.persist();
    setStateCursos((state) => {
      const newCursos = [...state.cursos];
      newCursos[index] = _.cloneDeep(cursos.cursos[index]);
      return { ...state, diplomados: newCursos };
    });
  };

  const onSaveHeaderHandler = (e) => {
    e.persist();
    pagePromiseHandler(
      cursosService.updatePageSection({
        titulo: stateCursos.titulo,
        descripcion: stateCursos.descripcion,
      }),
      "actualizada"
    );
  };

  const onChangeHeaderHandler = (e) => {
    e.persist();
    inputChangeHandler(e);
  };

  const onDiscardHeaderHandler = (e) => {
    e.persist();
    setStateCursos((state) => ({
      ...state,
      titulo: cursos.titulo,
      descripcion: cursos.descripcion,
    }));
  };

  const onSaveSeccionCursos = (e) => {
    e.persist();
    pagePromiseHandler(
      cursosService.updatePageSection({
        seccionCursos: stateCursos.seccionCursos,
      }),
      "actualizada"
    );
  };

  const onChangeSeccionCursos = (e) => {
    e.persist();
    setStateCursos((state) => ({
      ...state,
      seccionCursos: {
        ...state.seccionCursos,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const onDiscardSeccionCursos = (e) => {
    e.persist();
    setStateCursos((state) => ({
      ...state,
      seccionCursos: {
        ...cursos.seccionCursos,
      },
    }));
  };

  const onSaveSeccionTalleres = (e) => {
    e.persist();
    pagePromiseHandler(
      cursosService.updatePageSection({
        seccionTalleres: stateCursos.seccionTalleres,
      }),
      "actualizada"
    );
  };

  const onChangeSeccionTalleres = (e) => {
    e.persist();
    setStateCursos((state) => ({
      ...state,
      seccionTalleres: {
        ...state.seccionTalleres,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const onDiscardSeccionTalleres = (e) => {
    e.persist();
    setStateCursos((state) => ({
      ...state,
      seccionTalleres: {
        ...cursos.seccionTalleres,
      },
    }));
  };

  const onChangeTaller = (e, index) => {
    e.persist();
    setStateCursos((state) => {
      const newTalleres = _.cloneDeep(state.talleres);
      newTalleres[index].nombre = e.target.value;
      newTalleres[index].path = e.target.value
        .normalize("NFD")
        .replace(/\s/g, "-")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      console.log(newTalleres);
      return { ...state, talleres: newTalleres };
    });
  };

  const onDeleteTaller = async (e, index) => {
    e.persist();
    const newTalleres = [...stateCursos.talleres];
    const tallerToDelete = { ...newTalleres[index] };
    await deleteImage(tallerToDelete.imagen);
    newTalleres.splice(index, 1);
    talleresPromiseHandler(
      cursosService.onDeleteTaller(
        newTalleres.map((x) => {
          x["imageFile"] = null;
          x["imageURL"] = "";
          return x;
        }),
        tallerToDelete.fk
      ),
      "eliminado"
    );
  };

  const onSaveTaller = (index, imagePath) => {
    const collectionID = stateCursos.talleres[index].fk;
    const newPath = stateCursos.talleres[index].path;
    talleresPromiseHandler(
      cursosService.updatePageTalleres(
        stateCursos.talleres.map((x, i) => {
          if (index === i && imagePath) {
            x["imagen"] = imagePath;
          }
          x["imageFile"] = null;
          x["imageURL"] = "";
          return x;
        }),
        collectionID,
        newPath
      ),
      "actualizado"
    );
  };

  const onCreateTaller = (e) => {
    e.persist();
    talleresPromiseHandler(
      cursosService.createTaller(
        stateCursos.talleres.map((x) => {
          x["imageFile"] = null;
          x["imageURL"] = "";
          return x;
        })
      ),
      "creado"
    );
  };

  const onDiscardTaller = (e, index) => {
    e.persist();
    setStateCursos((state) => {
      const newTalleres = [...state.talleres];
      newTalleres[index] = _.cloneDeep(cursos.talleres[index]);
      return { ...state, talleres: newTalleres };
    });
  };

  const onEditTallerImage = (e, index) => {
    e.persist();
    const newImage = e.target.files[0];

    setStateCursos((state) => {
      const talleres = [...state.talleres];
      const newTaller = {
        ...talleres[index],
        imageFile: newImage,
        imageURL: URL.createObjectURL(newImage),
      };

      talleres[index] = newTaller;
      return { ...state, talleres };
    });
  };

  const deleteImage = (imagen) =>
    imagen !== "" &&
    storage
      .deleteFile(
        `/talleres/${imagen
          .replace(
            `https://firebasestorage.googleapis.com/v0/b/posgrados-inqba.appspot.com/o/talleres%2F`,
            ""
          )
          .replace("?alt=media", "")}`
      )
      .then(() => true)
      .catch(() => false);

  const submitImage = (index) =>
    storage.uploadImage(
      `/talleres/${genHash(stateCursos.talleres[index].imageFile.name)}${
        stateCursos.talleres[index].imageFile.type === "image/png"
          ? ".png"
          : ".jpg"
      }`,
      stateCursos.talleres[index].imageFile
    );

  const onSaveTallerChanges = async (e, index) => {
    e.persist();
    addToast("Subiendo imagen", { appearance: "info" });
    if (stateCursos.talleres[index].imageFile) {
      if (stateCursos.talleres[index].imagen.length > 0) {
        const imageDel = await deleteImage(stateCursos.talleres[index].imagen);
        if (!imageDel) {
          return addToast(
            "Error al borrar imagen anterior, intente borrar la categoría más tarde.",
            { appearance: "error" }
          );
        }
      }

      const imagePath = await submitImage(index);
      if (!imagePath) {
        return addToast("Error al subir imagen nueva, intente más tarde.", {
          appearance: "error",
        });
      }

      onSaveTaller(index, imagePath);
    } else {
      onSaveTaller(index);
    }
  };

  useEffect(() => {
    if (!cursosCollection.initialFetch) {
      fetchCollection("cursosCollection");
    }
    if (!talleresCollection.initialFetch) {
      fetchCollection("talleresCollection");
    }
    if (!cursos.initialFetch) {
      fetchPage("cursos");
    }
    if (fetchTriggered || fetchCollectionTriggered) {
      setStateCursos(
        ((x) => ({
          ...x,
          talleres: x.talleres.map((taller) => ({
            ...taller,
            imageFile: null,
            imageURL: "",
          })),
        }))(cursos)
      );
      setFetchTriggered(() => false);
      setFetchCollectionTriggered(() => false);
    }
  }, [
    cursosCollection.initialFetch,
    talleresCollection.initialFetch,
    cursos.initialFetch,
    cursos,
    setStateCursos,
    fetchPage,
    fetchCollection,
    fetchTriggered,
    setFetchTriggered,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <section id="admin-cursos" className="admin-cursos">
      <h2 className="admin-cursos__titulo headline3">InCompany</h2>
      <div className="admin-cursos__content">
        <CollectionTitle
          data={{
            titulo: stateCursos.titulo,
            descripcion: stateCursos.descripcion,
          }}
          onSave={onSaveHeaderHandler}
          onDiscardChanges={onDiscardHeaderHandler}
          disableSave={isFetching}
          onChangeInput={onChangeHeaderHandler}
          promiseHandler={pagePromiseHandler}
        />
        <LineBreak />
        <span className="admin-cursos__subtitulo headline3">Cursos</span>
        <CollectionTitle
          data={{
            titulo: stateCursos.seccionCursos.titulo,
            descripcion: stateCursos.seccionCursos.descripcion,
          }}
          disableSave={isFetching}
          onSave={onSaveSeccionCursos}
          onDiscardChanges={onDiscardSeccionCursos}
          onChangeInput={onChangeSeccionCursos}
          promiseHandler={cursosPromiseHandler}
        />
        <PageCollectionList
          disableSave={isFetching}
          items={stateCursos.cursos || []}
          getValueFn={(x) => x.nombre}
          routerPathFn={(x) => `/admin/cursos/${x.path}`}
          routerPathError={(x) =>
            cursos.cursos.find((y) => y.id === x.id)?.path === ""
          }
          onChangeValue={onChangeCurso}
          onDeleteItem={onDeleteCurso}
          onClickAddItem={onCreateCurso}
          onSaveItem={onSaveCurso}
          onDiscardItem={onDiscardCurso}
        />
        <LineBreak />
        <span className="admin-cursos__subtitulo headline3">Talleres</span>
        <CollectionTitle
          data={{
            titulo: stateCursos.seccionTalleres.titulo,
            descripcion: stateCursos.seccionTalleres.descripcion,
          }}
          onSave={onSaveSeccionTalleres}
          onDiscardChanges={onDiscardSeccionTalleres}
          disableSave={isFetching}
          onChangeInput={onChangeSeccionTalleres}
          promiseHandler={talleresPromiseHandler}
        />
        <PageCollectionList
          disableSave={isFetching}
          withImage
          onEditImage={onEditTallerImage}
          items={stateCursos.talleres || []}
          getValueFn={(x) => x.nombre}
          routerPathFn={(x) => `/admin/talleres/${x.path}`}
          routerPathError={(x) =>
            cursos.talleres.find((y) => y.id === x.id)?.path === ""
          }
          onChangeValue={onChangeTaller}
          onDeleteItem={onDeleteTaller}
          onClickAddItem={onCreateTaller}
          onSaveItem={onSaveTallerChanges}
          onDiscardItem={onDiscardTaller}
        />
      </div>
    </section>
  );
};
export default AdminCursos;
