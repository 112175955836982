import { useQuery } from "react-query";
import { firestore } from "../../firebase";

const useFooter = () => {
  return useQuery("getFooter", () =>
    firestore
      .collection("paginas")
      .doc("footer")
      .get()
      .then((doc) => doc.data())
  );
};

export default useFooter;
