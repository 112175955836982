const { useQuery } = require("react-query");

const useAdminQuery = (uid) => {
  return useQuery(
    ["adminQuery", uid],
    uid && uid.length !== 0
      ? async () =>
          await fetch("/.netlify/functions/auth", {
            method: "POST",
            body: JSON.stringify({ action: "readAdmin", payload: { uid } }),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw new Error("error/fetch-error");
            })
            .then((x) => x)
            .catch((error) => ({ error }))
      : () => Promise.resolve(null),
    {
      retry: false,
      enabled: false,
      refetchOnMount: false,
    }
  );
};

export default useAdminQuery;
