import React from "react";
import classnames from "classnames";

const RatingStars = ({ rating, onChange, id }) => {
  return (
    <div className="rating">
      <input
        type="radio"
        name="rating"
        id={`rating-5-${id}`}
        value="5"
        onChange={onChange}
        checked={rating === "5"}
      />
      <label
        className={classnames({ inRange: parseInt(rating) >= 5 })}
        htmlFor={`rating-5-${id}`}
      ></label>
      <input
        type="radio"
        name="rating"
        id={`rating-4-${id}`}
        value="4"
        onChange={onChange}
        checked={rating === "4"}
      />
      <label
        className={classnames({ inRange: parseInt(rating) >= 4 })}
        htmlFor={`rating-4-${id}`}
      ></label>
      <input
        type="radio"
        name="rating"
        id={`rating-3-${id}`}
        value="3"
        onChange={onChange}
        checked={rating === "3"}
      />
      <label
        className={classnames({ inRange: parseInt(rating) >= 3 })}
        htmlFor={`rating-3-${id}`}
      ></label>
      <input
        type="radio"
        name="rating"
        id={`rating-2`}
        value="2"
        onChange={onChange}
        checked={rating === "2"}
      />
      <label
        className={classnames({ inRange: parseInt(rating) >= 2 })}
        htmlFor={`rating-2-${id}`}
      ></label>
      <input
        type="radio"
        name="rating"
        id={`rating-1-${id}`}
        value="1"
        onChange={onChange}
        checked={rating === "1"}
      />
      <label
        className={classnames({ inRange: parseInt(rating) >= 1 })}
        htmlFor={`rating-1-${id}`}
      ></label>
    </div>
  );
};

export default RatingStars;
