import React, { useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { CommonTextInput, ManejarCambios } from "../../components/admin/misc";
import useInputChange from "../lib/hooks/useInputChange";
import { genHash } from "../../Utils/genHash";
import StorageService from "../lib/services/storage.service";

import BackupImage from "../../assets/svg/backup-image.svg";

const CategoriasCard = ({
  id,
  data,
  disableSave,
  onDelete,
  onSave,
  storageCollection = "categorias",
  onDiscard,
}) => {
  const [state, setState] = useState(
    ((x) => ({ ...x, imageURL: "", imageFile: null }))(data)
  );
  const inputChangeHandler = useInputChange(setState);
  const storage = useMemo(() => new StorageService(), []);
  const { addToast } = useToasts();

  const onEditImage = (e) => {
    e.persist();
    const newImage = e.target.files[0];

    setState((state) => ({
      ...state,
      imageFile: newImage,
      imageURL: URL.createObjectURL(newImage),
    }));
  };

  const onDeleteImage = (e, index) => {
    e.persist();
    deleteImage();
    onDelete(index);
  };

  const deleteImage = () =>
    state.imagen !== "" &&
    storage
      .deleteFile(
        `/${storageCollection}/${state.imagen
          .replace(
            `https://firebasestorage.googleapis.com/v0/b/posgrados-inqba.appspot.com/o/${storageCollection}%2F`,
            ""
          )
          .replace("?alt=media", "")}`
      )
      .then(() => true)
      .catch(() => false);

  const submitImage = () =>
    storage.uploadImage(
      `/${storageCollection}/${genHash(state.imageFile.name)}${
        state.imageFile.type === "image/png" ? ".png" : ".jpg"
      }`,
      state.imageFile
    );

  const onSaveChanges = async (e) => {
    e.persist();
    addToast("Subiendo imagen", { appearance: "info" });
    if (state.imageFile) {
      if (state.imagen.length > 0) {
        const imageDel = await deleteImage();
        if (!imageDel) {
          return addToast(
            "Error al borrar imagen anterior, intente borrar la categoría más tarde.",
            { appearance: "error" }
          );
        }
      }

      const imagePath = await submitImage();
      if (!imagePath) {
        return addToast("Error al subir imagen nueva, intente más tarde.", {
          appearance: "error",
        });
      }
      onSave(id, {
        nombre: state.nombre,
        imagen: imagePath,
      });
    } else {
      onSave(id, {
        nombre: state.nombre,
        imagen: state.imagen,
      });
    }
  };

  useEffect(() => {
    if (!state.imageFile)
      setState(((x) => ({ ...x, imageURL: "", imageFile: null }))(data));
  }, [data, state.imageFile]);

  return (
    <div className="card">
      <span className="headline3">Categoria {id + 1}</span>
      <CommonTextInput
        onDelete={(e) => onDeleteImage(e, id)}
        trashActivo
        value={state.nombre}
        name="nombre"
        onChange={inputChangeHandler}
        placeholder="Nombre de la categoría"
      />
      <div className="card__body flex-row">
        <img
          src={
            state.imageURL === "" && state.imagen === ""
              ? BackupImage
              : state.imageURL.length > 0
              ? state.imageURL
              : state.imagen
          }
          alt="Imagen de Categoría"
          className="card__imagen"
        ></img>
        <label htmlFor={`categoria-image-${id}`} className="card__label">
          ELEGIR IMAGEN
        </label>
        <input
          id={`categoria-image-${id}`}
          value={""}
          accept="image/x-png,image/jpeg"
          onChange={onEditImage}
          type="file"
          className="card__input"
        />
      </div>
      <ManejarCambios
        saveClick={onSaveChanges}
        discardClick={(e) => {
          onDiscard(e, id);
          setState((x) => ({ ...x, imageFile: null, imageURL: "" }));
        }}
        disableSave={disableSave}
      />
    </div>
  );
};

export default CategoriasCard;
