import React, { useEffect, useMemo, useState } from "react";
import "animate.css/animate.min.css";
import classnames from "classnames";
import { useInView } from "react-intersection-observer";
import { Link, useHistory } from "react-router-dom";

import "./Home.scss";

import laptop from "../../assets/svg/laptop-home.svg";
import estrella from "../../assets/svg/estrella-home.svg";
import idea from "../../assets/svg/idea-home.svg";

import Header from "../../components/Header/Header";
import CardProfesor from "../../components/CardProfesor/CardProfesor";
import Footer from "../../components/Footer/Footer";
import FlechaDerechaIcon from "../../components/svgs/FlechaDerechaIcon";
import Carousel from "../../components/Carousel/Carousel";
import { splitCategories } from "../../Utils/splitCategories";
import useWidthListener from "../hooks/useWidthListener";

const UseInViewOnce = () =>
  useInView({
    triggerOnce: true,
    threshold: "0",
  });

const Home = ({ data }) => {
  const categorias = useMemo(() => splitCategories(data?.categorias), [
    data?.categorias,
  ]);
  const [isBouncing, setIsBouncing] = useState("fadeInUp");
  const history = useHistory();

  const [ref1, ref1InView] = UseInViewOnce();
  const [ref2, ref2InView] = UseInViewOnce();
  const [ref3, ref3InView] = UseInViewOnce();
  const [ref4, ref4InView] = UseInViewOnce();
  const [ref5, ref5InView] = UseInViewOnce();
  const [ref6, ref6InView] = UseInViewOnce();
  const [ref7, ref7InView] = UseInViewOnce();
  const [ref8, ref8InView] = UseInViewOnce();
  const [ref9, ref9InView] = UseInViewOnce();
  const [ref10, ref10InView] = UseInViewOnce();
  const [ref11, ref11InView] = UseInViewOnce();

  const navigate = (path) => history.push(path);
  const navigateHome = () => history.push("/contacto");

  useEffect(() => {
    const bodySelector = window.document.querySelector("body");
    bodySelector.classList.add("gradient-home");

    const timeOut = setTimeout(() => {
      setIsBouncing("bounce animate__infinite  animate__slow");
    }, 2500);

    return () => {
      bodySelector.classList.remove("gradient-home");
      window.clearTimeout(timeOut);
    };
  }, [data?.categorias]);

  const colorVerde = "#ABC60D";
  const screenSize = useWidthListener();

  return (
    <div className="home">
      <div className="gradiente"></div>
      <Header />
      <div>
        <div className="home__hero-image">
          <h1
            ref={ref1}
            className={ref1InView ? "animate__animated animate__fadeInUp" : ""}
          >
            Nunca dejes de crecer
          </h1>
          <p
            ref={ref2}
            className={ref2InView ? "animate__animated animate__fadeInUp" : ""}
          >
            Nuestros programas están diseñados para impulsar la carrera y los
            negocios de emprendedores mexicanos.
          </p>
          <span
            ref={ref3}
            className={
              ref3InView
                ? `inscribete animate__animated animate__${isBouncing}`
                : ""
            }
            onClick={navigateHome}
          >
            Inscríbete Ahora
          </span>
        </div>
        <div className="home__hero-image__dos">
          <div className="home__hero-image__dos__desaparece-mobile">
            <h3>¿Qué es inQba?</h3>
            <p className="home__hero-image__dos__desaparece-mobile__p">
              inQba Escuela de Negocios es una institución con más de 10 años de
              experiencia especializada en la formación profesional de
              emprendedores de alto impacto, con un enfoque único que apunta al
              emprendimiento en Puebla con más de 200 empresas de egresados en
              licenciatura, 68 empresas de maestría y más de 5,000 egresados en
              los diversos programas de educación continua y capacitación.
            </p>
            <span
              ref={ref4}
              className={
                ref4InView
                  ? "inscribete animate__animated animate__bounce animate__infinite  animate__slow"
                  : ""
              }
              onClick={navigateHome}
            >
              Inscríbete Ahora
            </span>
          </div>
          <div
            ref={ref5}
            className={
              ref5InView
                ? "home__hero-image__dos-img animate__animated animate__fadeInUp"
                : ""
            }
          ></div>
        </div>
      </div>
      <div className="home__seccion-que">
        <div className="home__seccion-que__cuadrados__curva-verde"></div>
        <div className="home__seccion-que__cuadrados__curva-gris"></div>
        <div className="home__seccion-que__desaparece-laptop">
          <h3>¿Qué es inQba?</h3>
          <p className="home__seccion-que__desaparece-laptop__p">
            inQba Escuela de Negocios es una institución con más de 10 años de
            experiencia especializada en la formación profesional de
            emprendedores de alto impacto, con un enfoque único que apunta al
            emprendimiento en Puebla con más de 200 empresas de egresados en
            licenciatura, 68 empresas de maestría y más de 5,000 egresados en
            los diversos programas de educación continua y capacitación.
          </p>
          <span
            ref={ref6}
            className={
              ref6InView
                ? "inscribete animate__animated animate__bounce animate__infinite  animate__slow"
                : ""
            }
            onClick={navigateHome}
          >
            Inscríbete Ahora
          </span>
        </div>
        <div className="home__seccion-que__cuadrados">
          <div
            ref={ref7}
            className={
              ref7InView
                ? "home__seccion-que__cuadrados cuadrado animate__animated animate__fadeInUp"
                : ""
            }
          >
            <img
              src={laptop}
              alt="laptop"
              className="home__seccion-que__cuadrados cuadrado__imagen"
            />
            <p>
              Nuestros programas están diseñados con una metodología altamente
              enfocada a la práctica para que crezcas profesionalmente junto con
              tu empresa.
            </p>
          </div>
          <div></div>
          <div></div>
          <div
            ref={ref8}
            className={
              ref8InView
                ? "home__seccion-que__cuadrados cuadrado animate__animated animate__fadeInUp"
                : ""
            }
          >
            <img
              src={estrella}
              alt="estrella"
              className="home__seccion-que__cuadrados cuadrado__imagen"
            />
            <p>
              Nuestros programas han impactado a más de 2,500 emprendedores,
              profesionistas y empresarios.
            </p>
          </div>
          <div
            ref={ref9}
            className={
              ref9InView
                ? "home__seccion-que__cuadrados cuadrado animate__animated animate__fadeInUp"
                : ""
            }
          >
            <img
              src={idea}
              alt="idea"
              className="home__seccion-que__cuadrados cuadrado__imagen"
            />
            <p>
              Diseñamos e implementamos programas de capacitación de acuerdo a
              las necesidades específicas de tu empresa u organización.
            </p>
          </div>
        </div>
      </div>
      {data?.mbas?.length > 0 && (
        <div className="home__seccion-mba">
          <div className="home__seccion-mba__inscribete">
            <h3>Nuestra Oferta</h3>
            <p>
              Contamos con un posgrado que ha impulsado el potencial de
              ejecutivos y emprendedores que ejercen un rol de liderazgo en
              empresas de alto impacto.
            </p>
            <span
              ref={ref10}
              className={
                ref10InView
                  ? "inscribete animate__animated animate__bounce animate__infinite  animate__slow"
                  : ""
              }
              onClick={navigateHome}
            >
              Inscríbete Ahora
            </span>
          </div>
          <div className="home__seccion-mba__cards">
            {data?.mbas?.map((mba, index) => (
              <div
                key={index}
                onClick={() => navigate(`/posgrados/${mba?.path}`)}
                className="home__seccion-mba__cards-card"
              >
                <span className="home__seccion-mba__cards-card__span">
                  {mba?.nombreCorto}
                </span>
                <FlechaDerechaIcon color={colorVerde} className="flecha" />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="home__seccion-tenemos">
        {categorias?.length > 0 && (
          <>
            <h3>Nuestra Oferta Educativa:</h3>
            <p className="home__seccion-tenemos-p">
              Contamos con programas en distintas áreas. ¡Capacítate y lleva tu
              empresa a otro nivel!
            </p>
          </>
        )}
        <div className="home__seccion-tenemos__fondo">
          <div className="home__seccion-tenemos__fondo__curva-verde"></div>
          <div className="home__seccion-tenemos__fondo__curva-gris"></div>
          {categorias?.length > 0 && (
            <div className="home__seccion-tenemos__fondo__cards">
              {categorias.map((categoria, index) => (
                <div
                  key={index}
                  className="home__seccion-tenemos__fondo__cards__card-dupla"
                >
                  <div
                    className="home__seccion-tenemos__fondo__cards__card-dupla__card"
                    style={{
                      backgroundImage: `url(${categoria?.[0]?.imagen})`,
                    }}
                  >
                    <div className="home__seccion-tenemos__fondo__cards__card-dupla__card--mask">
                      {categoria?.[0]?.nombre}
                    </div>
                  </div>
                  {categoria?.[1] ? (
                    <div
                      className="home__seccion-tenemos__fondo__cards__card-dupla__card"
                      style={{
                        backgroundImage: `url(${categoria?.[1]?.imagen})`,
                      }}
                    >
                      <div className="home__seccion-tenemos__fondo__cards__card-dupla__card--mask">
                        {categoria?.[1]?.nombre}
                      </div>
                    </div>
                  ) : screenSize === "desktop" ? (
                    <div className="home__seccion-tenemos__fondo__cards__card-dupla__card spacer" />
                  ) : null}
                </div>
              ))}
            </div>
          )}
          {data?.profesores?.length > 0 && (
            <div
              className={classnames(
                "home__seccion-tenemos__fondo__profesores-cards",
                { topped: data?.categorias?.length % 2 !== 0 }
              )}
            >
              <div
                className={classnames(
                  "home__seccion-tenemos__fondo__profesores-cards__profesores grid-uno",
                  { topped: data?.categorias?.length % 2 !== 0 }
                )}
              >
                <h4>Nuestros Profesores</h4>
                <p className="home__seccion-tenemos__fondo__profesores-cards__profesores-p">
                  Nuestra planta docente está integrada por maestros empresarios
                  y especialistas destacados a nivel nacional e internacional,
                  para que cumplas tus objetivos de la mano de los expertos.
                </p>
                <Link
                  to="/profesores"
                  ref={ref11}
                  className={
                    ref11InView
                      ? "inscribete animate__animated animate__bounce animate__infinite  animate__slow"
                      : ""
                  }
                >
                  Ver más profesores
                </Link>
              </div>
              <CardProfesor profesores={data?.profesores} />
            </div>
          )}
          <Carousel testimonios={data?.testimonios} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
