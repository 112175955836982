import React, { useEffect, useState, useMemo } from "react";

import { BotonAgregar } from "../../components/admin/misc";
import { useCollection } from "../context/context";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import ProfesoresService from "../lib/services/profesores.service";
import CardAdminProfesor from "./CardAdminProfesor";

import "./AdminProfesores.scss";

const AdminProfesores = () => {
  const { profesores, fetchCollection } = useCollection();
  const [isFetching, setIsFetching] = useState(false);
  const ps = useMemo(() => new ProfesoresService(), []);
  const promiseHandler = usePromiseHandler("Profesor", setIsFetching);

  const deleteProfesor = (id) => {
    promiseHandler(ps.deleteProfesor(id), "eliminado");
  };

  const createProfesor = (e) => {
    e.persist();
    promiseHandler(ps.createProfesor(), "creado");
  };

  const updateProfesor = (id, profesor) => {
    promiseHandler(ps.updateProfesor(id, profesor), "actualizado");
  };

  useEffect(() => {
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
  }, [profesores.initialFetch, fetchCollection]);

  return (
    <div className="admin-profesores">
      <div className="admin-profesores__contenedor">
        <span className="admin-profesores__titulo headline3">Profesores</span>
        <div className="admin-profesores__contenido">
          {profesores.collection
            .sort((a, b) => b.date - a.date)
            .map((profesorData, index) => (
              <CardAdminProfesor
                disableSave={isFetching}
                indexProfesor={profesores.collection.length - index}
                deleteProfesor={deleteProfesor}
                updateProfesor={updateProfesor}
                key={index}
                profesor={profesorData}
                varianteProfesores={true}
              />
            ))}
        </div>
        <div className="admin-profesores__boton">
          <BotonAgregar
            disabled={isFetching}
            onClick={createProfesor}
            varianteProfesores={true}
          />
        </div>
      </div>
    </div>
  );
};
export default AdminProfesores;
