export const mProfesor = ({
  id,
  nombre,
  profesion,
  rating,
  imagen,
  descripcion,
  cursos,
  date,
}) => ({
  id,
  nombre,
  profesion,
  rating,
  imagen,
  descripcion,
  cursos,
  date,
});

mProfesor.toEdit = ({
  id,
  nombre,
  profesion,
  rating,
  imagen,
  descripcion,
  cursos,
  date,
}) => ({
  id,
  nombre,
  profesion,
  rating,
  imagen,
  descripcion,
  cursos,
  date,
  imageFile: null,
  imageURL: "",
});

mProfesor.toDB = ({
  nombre,
  profesion,
  rating,
  imagen,
  descripcion,
  cursos,
  date,
}) => ({
  nombre,
  profesion,
  rating,
  imagen,
  descripcion,
  cursos,
  date,
});

mProfesor.init = () => ({
  nombre: "",
  profesion: "",
  rating: "",
  imagen: "",
  descripcion: "",
  cursos: [],
  date: new Date(),
});
