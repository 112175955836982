import FirestoreService from "./firestore.service";

export default class HomeService extends FirestoreService {
  constructor() {
    super();
    this.ref = this.fs.collection("paginas").doc("home");
  }

  updatePageSection(seccion) {
    return this.ref.set(seccion, { merge: true });
  }
}
