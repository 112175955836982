import React from "react";

import Login from "./Login/Login";
import AdminContainer from "./AdminContainer/AdminContainer";
import { useAuth } from "./context/context";
import useAdminQuery from "./lib/hooks/useAdminQuery";

import "./Admin.scss";

const Admin = () => {
  const { isAuthenticated, uid } = useAuth();
  const { data } = useAdminQuery(uid);

  return !isAuthenticated ? (
    <Login />
  ) : (
    <AdminContainer isAdmin={data?.admin} usuarios={data?.usuarios} />
  );
};

export default Admin;
