import { useQuery } from "react-query";
import { firestore } from "../../firebase";

const useEventos = () => {
  return useQuery(
    "getEventos",
    () =>
      firestore
        .collection("paginas")
        .doc("eventos")
        .get()
        .then((doc) => doc.data()),
    {
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
    }
  );
};

export default useEventos;
