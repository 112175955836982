import React from "react";

import CommonTextInput from "./CommonTextInput";
import ManejarCambios from "./ManejarCambios";
import { mMBA } from "../../admin/lib/models/mMBA";

import calendarIcon from "../../assets/svg/calendar-icon.svg";
import routeIcon from "../../assets/svg/route-icon.svg";
import timeIcon from "../../assets/svg/time-icon.svg";
import circleCheckIcon from "../../assets/svg/circlecheck-icon.svg";

const CollectionItemHeader = ({
  values = mMBA.header(mMBA.init()),
  onChangeInput,
  placeHolderNombre = "Nombre del Posgrado",
  disableSave,
  onSaveChanges,
  onDiscardChanges,
}) => {
  return (
    <section className="collection-item__header">
      <CommonTextInput
        name="nombre"
        placeholder={placeHolderNombre}
        value={values.nombre}
        onChange={onChangeInput}
      />
      <textarea
        className="collection-item__textarea"
        rows="5"
        placeholder="Intro"
        value={values.intro}
        name="intro"
        onChange={onChangeInput}
      ></textarea>
      <div className="collection-item__list">
        <CommonTextInput
          name="duracion"
          placeholder="Duración"
          value={values.duracion}
          onChange={onChangeInput}
          leftIcon={calendarIcon}
        />
        <CommonTextInput
          name="modalidad"
          placeholder="Modalidad"
          value={values.modalidad}
          onChange={onChangeInput}
          leftIcon={routeIcon}
        />
        <CommonTextInput
          name="horario"
          placeholder="Horario"
          value={values.horario}
          onChange={onChangeInput}
          leftIcon={timeIcon}
        />
        <CommonTextInput
          name="inicio"
          value={values.inicio}
          onChange={onChangeInput}
          placeholder="Inicio"
          leftIcon={circleCheckIcon}
        />
      </div>
      <ManejarCambios
        noMarginBottom
        saveClick={onSaveChanges}
        discardClick={onDiscardChanges}
        disableSave={disableSave}
      />
    </section>
  );
};

export default CollectionItemHeader;
