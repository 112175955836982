import React from "react";
import { useParams, Redirect } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import GenericLinks from "../../components/GenericLinkList/GenericLinks";
import Header from "../../components/Header/Header";
import UniqueEnfoques from "../../components/UniqueEnfoques/UniqueEnfoques";
import UniqueHeader from "../../components/UniqueHeader/UniqueHeader";
import UniqueInfo from "../../components/UniqueInfo/UniqueInfo";
import UniqueProfesores from "../../components/UniqueProfesores/UniqueProfesores";
import useCurso from "../hooks/useCurso";

const CursosPage = () => {
  const params = useParams();
  const { data } = useCurso(params.path);
  if (!params.path) return <Redirect to="/no-encontrado" />;

  return data?.notFound ? (
    <Redirect to="/no-encontrado" />
  ) : (
    <main className="cursos-page unique-page">
      <Header fondoTransparente={false} />
      <UniqueHeader
        type="cursos"
        title={data?.nombre}
        subtitle={data?.intro}
        inscripcionLink="/contacto"
      />
      <UniqueInfo
        green
        duracion={data?.duracion}
        modalidad={data?.modalidad}
        horario={data?.horario}
        inicio={data?.inicio}
      />
      {data?.enfoques?.coleccion.length > 0 && (
        <UniqueEnfoques
          green
          title={data?.enfoques?.title}
          collection={data?.enfoques?.coleccion}
        />
      )}
      {data?.profesores?.length > 0 && (
        <UniqueProfesores type="cursos" collection={data?.profesores} />
      )}
      {data?.otrosCursos.length > 0 ? (
        <GenericLinks
          title={data?.otros?.titulo}
          subtitle={data?.otros?.descripcion}
          list={data?.otrosCursos}
          routeKeyFn={(x) => x.id}
          routeFn={(x) =>
            x.fromCollection === "cursosCollection" ? "/cursos" : "/talleres"
          }
        />
      ) : (
        <div style={{ paddingBottom: "40px" }} />
      )}
      <Footer />
    </main>
  );
};

export default CursosPage;
