import { useCallback, useEffect, useState } from "react";

const useWidthListener = () => {
  const switchListener = () => {
    if (window.innerWidth < 768) {
      return "mobile";
    } else if (window.innerWidth >= 768 && window.innerWidth < 1152) {
      return "tablet";
    } else if (window.innerWidth >= 1152 && window.innerWidth < 1440) {
      return "laptop";
    } else if (window.innerWidth >= 1440) {
      return "desktop";
    }
  };

  const [state, setState] = useState(switchListener());

  const listener = useCallback(() => {
    setState(switchListener());
  }, []);

  useEffect(() => {
    listener();
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [listener]);

  return state;
};

export default useWidthListener;
