import React from "react";

const MailIcon = ({ color = "black", width = "31", height = "25" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 0.125H27.875C29.3938 0.125 30.625 1.35622 30.625 2.875V22.125C30.625 23.6438 29.3938 24.875 27.875 24.875H3.125C1.60622 24.875 0.375 23.6438 0.375 22.125V2.875C0.375 1.35622 1.60622 0.125 3.125 0.125ZM3.125 9.22486V22.125H27.875V9.22544L15.5 15.413L3.125 9.22486ZM3.125 6.1502L15.5 12.3383L27.875 6.15085V2.875H3.125V6.1502Z"
        fill={color}
      />
    </svg>
  );
};

export default MailIcon;
