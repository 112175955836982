import React from "react";
import classnames from "classnames";

const ManejarCambios = ({
  disableSave,
  discardClick,
  saveClick,
  noMarginBottom,
}) => {
  return (
    <div
      className={classnames("cambios flex-row", {
        "mb-0": noMarginBottom,
      })}
    >
      <button
        disabled={disableSave}
        type="button"
        onClick={saveClick}
        className="cambios__button cambios--guardar"
      >
        GUARDAR
      </button>
      <button
        disabled={disableSave}
        type="button"
        onClick={discardClick}
        className="cambios__button cambios--descartar"
      >
        DESCARTAR
      </button>
    </div>
  );
};

export default ManejarCambios;
