import React from "react";
import { NavLink } from "react-router-dom";

import "./BarraLateral.scss";

import logoinQba from "../../assets/svg/logo-inQba-admin.svg";
import casa from "../../assets/svg/casa.svg";
import newspaper from "../../assets/svg/newspaper.svg";
import press from "../../assets/svg/press.svg";
import star from "../../assets/svg/star.svg";
import people from "../../assets/svg/people.svg";
import mail from "../../assets/svg/mail.svg";
import footer from "../../assets/svg/footer.svg";
import eventos from "../../assets/svg/eventos-admin-icon.svg";
import FlechaDerechaIcon from "../../components/svgs/FlechaDerechaIcon";

const seccionesMenu = [
  {
    imagen: casa,
    alt: "Imagen de cara sonriente",
    texto: "Home",
    link: "/admin/home",
  },
  {
    imagen: newspaper,
    alt: "Imagen de estrella adentro de un círculo",
    texto: "Diplomados",
    link: "/admin/diplomados",
  },
  {
    imagen: press,
    alt: "Imagen de un periódico",
    texto: "InCompany",
    link: "/admin/cursos",
  },
  {
    imagen: star,
    alt: "Imagen de un sobre de carta",
    texto: "Posgrados",
    link: "/admin/Posgrados",
  },
  {
    imagen: eventos,
    alt: "Imagen de un sobre de carta",
    texto: "Eventos",
    link: "/admin/eventos",
  },
  {
    imagen: people,
    alt: "Imagen de un sobre de carta",
    texto: "Profesores",
    link: "/admin/profesores",
  },
  {
    imagen: mail,
    alt: "Imagen de un sobre de carta",
    texto: "Contacto",
    link: "/admin/contacto",
  },
  {
    imagen: footer,
    alt: "Imagen de un sobre de carta",
    texto: "Footer",
    link: "/admin/footer",
  },
];

const BarraLateral = () => {
  const elementosMenu = () =>
    seccionesMenu.map((seccionMenu, index) => {
      return (
        <li key={index}>
          <NavLink exact={true} to={seccionMenu.link}>
            <div>
              <img
                src={seccionMenu.imagen}
                alt={seccionMenu.alt}
                className="icono"
              />
              <span>{seccionMenu.texto}</span>
            </div>
            <FlechaDerechaIcon />
          </NavLink>
        </li>
      );
    });

  return (
    <div id="barra-lateral-admin">
      <div className="logo-barra">
        <a href="/">
          <img src={logoinQba} alt="Logo de inQba" className="logo-inQba" />
        </a>
      </div>
      <div className="contenido">
        <span className="contenido__span">Contenido</span>
        <ul>{elementosMenu()}</ul>
      </div>
    </div>
  );
};
export default BarraLateral;
