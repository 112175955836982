import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import useInputChange from "../../admin/lib/hooks/useInputChange";

import { ReactComponent as CloseIcon } from "./close-icon.svg";

import "./EventoModal.scss";

const EventoModal = ({ evento, closeModal }) => {
  const [contacto, setFormData] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
  });
  const [isSent, setIsSent] = useState(false);
  const { addToast } = useToasts();
  const onChange = useInputChange(setFormData);

  const submit = (e) => {
    e.preventDefault();
    addToast("Enviando formulario...", { appearance: "info" });
    fetch("/.netlify/functions/mail", {
      method: "POST",
      body: JSON.stringify({
        action: "sendEventMail",
        payload: { evento, contacto },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return null;
      })
      .then((res) => {
        if (!res)
          return addToast("Error al enviar formulario, intente más tarde", {
            appearance: "error",
          });
        if (res.error)
          return addToast("Error al enviar formulario, intente más tarde", {
            appearance: "error",
          });

        setIsSent(() => true);
      })
      .catch((e) => {
        console.log(e);
        addToast("Error al enviar formulario, intente más tarde", {
          appearance: "error",
        });
      });
  };

  const onClose = (e) => {
    e.persist();
    setIsSent(() => false);
    setFormData(() => ({ nombre: "", apellido: "", correo: "", telefono: "" }));
    closeModal();
  };

  return (
    <section className="evento-modal">
      <div className="evento-modal__container">
        {isSent ? (
          <>
            <CloseIcon onClick={onClose} />
            <span className="evento-modal__title">
              ¡Hemos recibido tus datos correctamente!
            </span>
            <span className="evento-modal__subtitle">
              Pronto recibirás un correo con los datos de tu registro.
            </span>
            <button className="evento-modal__boton back" onClick={onClose}>
              Ver más eventos
            </button>
          </>
        ) : (
          <>
            <CloseIcon onClick={onClose} />
            <span className="evento-modal__title">Déjanos tus datos</span>
            <form onSubmit={submit} className="evento-modal__form">
              <input
                className="evento-modal__input"
                required
                name="nombre"
                type="text"
                value={contacto.nombre}
                onChange={onChange}
                placeholder="Nombre*"
              />
              <input
                className="evento-modal__input"
                required
                name="apellido"
                type="text"
                value={contacto.apellido}
                onChange={onChange}
                placeholder="Apellido*"
              />
              <input
                className="evento-modal__input"
                required
                name="correo"
                type="text"
                value={contacto.correo}
                onChange={onChange}
                placeholder="Correo electrónico*"
              />
              <input
                className="evento-modal__input"
                required
                name="telefono"
                type="text"
                value={contacto.telefono}
                onChange={onChange}
                placeholder="Teléfono*"
              />
              <button className="evento-modal__boton" type="submit">
                Enviar
              </button>
            </form>
          </>
        )}
      </div>
    </section>
  );
};

export default EventoModal;
