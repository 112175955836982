import React, { useState } from "react";
import useModal from "react-hooks-use-modal";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import EventosCard from "./EventosCard";
import EventoModal from "./EventoModal";

import "./Eventos.scss";

const Eventos = ({ data }) => {
  const [evento, setEvento] = useState({});
  const [Modal, open, close] = useModal("root", {
    preventScroll: true,
  });

  const openModal = (e, index) => {
    e.persist();
    setEvento(() => ({ ...data?.coleccion?.[index] }));
    console.log(data?.coleccion?.[index]);
    open();
  };

  return (
    <main className="eventos">
      <Header fondoTransparente={false} />
      <div className="hero">
        <h1 className="hero__h1 headline1 white">Eventos</h1>
        <p className="hero__p subtitle1 white">
          ¡Sé parte de los eventos exclusivos que inQba ofrece para que
          desarrolles todo tu potencial!
        </p>
        <div className="hero__imagen"></div>
      </div>
      <section className="eventos__content">
        <div className="eventos__container">
          {data?.coleccion?.map((data, index) => (
            <section key={index} className="eventos__card-container">
              <EventosCard
                evento={data}
                openModal={(e) => openModal(e, index)}
              />
              <button
                onClick={(e) => openModal(e, index)}
                className="eventos__card-container__button"
              >
                Regístrate ahora
              </button>
            </section>
          ))}
        </div>
      </section>
      <Footer />
      <Modal>
        <EventoModal evento={evento} closeModal={close} />
      </Modal>
    </main>
  );
};

export default Eventos;
