import { mCursos } from "../models/mCursos";
import { mMBA } from "../models/mMBA";
import FirestoreService from "./firestore.service";

export default class CursosService extends FirestoreService {
  constructor() {
    super();

    this.collectionRefCursos = this.fs
      .collection("colecciones")
      .doc("admin")
      .collection("cursosCollection");
    this.collectionRefTalleres = this.fs
      .collection("colecciones")
      .doc("admin")
      .collection("talleresCollection");
    this.pageRef = this.fs.collection("paginas").doc("cursos");
  }

  updatePageSection(page) {
    return this.pageRef.set(page, { merge: true });
  }

  onDeleteCurso(cursos, id) {
    return Promise.all([
      this.pageRef.set({ cursos }, { merge: true }),
      this.collectionRefCursos.doc(id).delete(),
    ]);
  }

  updatePageCursos(cursos, id, newPath) {
    return Promise.all([
      this.collectionRefCursos.doc(id).set({ path: newPath }, { merge: true }),
      this.pageRef.set({ cursos }, { merge: true }),
    ]);
  }

  createCurso(newCursos) {
    return this.collectionRefCursos
      .add(mCursos.init())
      .then((doc) => doc.id)
      .then((fk) =>
        this.pageRef.set(
          {
            cursos: [
              ...newCursos,
              { fk, path: "", nombre: "", date: new Date() },
            ],
          },
          { merge: true }
        )
      );
  }

  onDeleteTaller(talleres, id) {
    return Promise.all([
      this.pageRef.set({ talleres }, { merge: true }),
      this.collectionRefTalleres.doc(id).delete(),
    ]);
  }

  updatePageTalleres(talleres, id, newPath) {
    return Promise.all([
      this.collectionRefTalleres
        .doc(id)
        .set({ path: newPath }, { merge: true }),
      this.pageRef.set({ talleres }, { merge: true }),
    ]);
  }

  createTaller(newTalleres) {
    return this.collectionRefTalleres
      .add(mCursos.init())
      .then((doc) => doc.id)
      .then((fk) =>
        this.pageRef.set(
          {
            talleres: [
              ...newTalleres,
              { fk, path: "", nombre: "", imagen: "", date: new Date() },
            ],
          },
          { merge: true }
        )
      );
  }

  // Fetches para cada Curso
  updateCursoHeader(id, data) {
    return this.collectionRefCursos
      .doc(id)
      .set(mMBA.header(data), { merge: true });
  }

  updateCursoProfesores(id, data) {
    return this.collectionRefCursos
      .doc(id)
      .set({ profesores: data.profesores }, { merge: true });
  }

  updateCursoOtros(id, data) {
    return this.collectionRefCursos.doc(id).set(data, { merge: true });
  }

  updateCursoEnfoques(id, enfoques) {
    return this.collectionRefCursos.doc(id).set({ enfoques }, { merge: true });
  }

  // Fetches para cada Taller
  updateTallerHeader(id, data) {
    return this.collectionRefTalleres
      .doc(id)
      .set(mMBA.header(data), { merge: true });
  }

  updateTallerProfesores(id, data) {
    return this.collectionRefTalleres
      .doc(id)
      .set({ profesores: data.profesores }, { merge: true });
  }

  updateTallerOtros(id, data) {
    return this.collectionRefTalleres.doc(id).set(data, { merge: true });
  }

  updateTallerEnfoques(id, enfoques) {
    return this.collectionRefTalleres
      .doc(id)
      .set({ enfoques }, { merge: true });
  }
}
