import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import edit from "../../assets/svg/edit-icon.svg";
import PressIcon from "../svgs/PressIcon";
import trash from "../../assets/svg/trash.svg";

const CommonTextInput = ({
  inputClassname,
  trashActivo,
  editActivo,
  placeholder,
  value,
  onChange,
  onDelete,
  name,
  varianteProfesores,
  routerLink,
  routerLinkError,
  leftIcon,
}) => {
  const history = useHistory();

  const pushRouterLink = () => {
    if (routerLinkError) return;
    history.push(routerLink);
  };

  return (
    <div className="input-group flex-row">
      {varianteProfesores && (
        <PressIcon
          className="input-group__img input-group--press"
          color={"black"}
        />
      )}
      {leftIcon && (
        <img
          className={classnames(
            "input-group__img input-group--press leftInfo",
            {
              opaque: routerLinkError,
            }
          )}
          src={leftIcon}
          alt="Icon"
        />
      )}
      <input
        name={name}
        value={value}
        onChange={onChange}
        className={`input-group__input ${inputClassname || ""}`}
        type="text"
        placeholder={placeholder}
      />
      {trashActivo && (
        <img
          onClick={onDelete}
          className="input-group__img input-group--bote"
          src={trash}
          alt="Bote de basura"
        />
      )}
      {editActivo && (
        <img
          onClick={pushRouterLink}
          className={classnames("input-group__img input-group--bote", {
            opaque: routerLinkError,
          })}
          src={edit}
          alt="Edit icon"
        />
      )}
    </div>
  );
};

export default CommonTextInput;
