import React from "react";

import "./ErrorPage.scss";

import errorMobile from "../../assets/svg/error-404.svg";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function ErrorPage() {
  return (
    <>
      <Header fondoTransparente={false} />
      <div className="error-page flex-column">
        <img
          className="error-page__image"
          src={errorMobile}
          alt="página de error"
        ></img>
        <h1 className="error-page__header subtitle1 black">
          ¡Esta página no existe!
        </h1>
        <p className="error-page__paragraph black">
          Has click
          <a href="/" className="error-page__enlace headline3 green">
            aquí
          </a>
          para regresar a la página principal
        </p>
      </div>
      <Footer showBanner={false} />
    </>
  );
}

export default ErrorPage;
