import React from "react";
import { Route, Switch } from "react-router-dom";

import "./Central.scss";

import logoinQba from "../../assets/svg/logo-inQba-admin.svg";

import AdminContacto from "../AdminContacto/AdminContacto";
import AdminCursos from "../AdminCursos/AdminCursos";
import AdminDiplomados from "../AdminDiplomados/AdminDiplomados";
import AdminHome from "../AdminHome/AdminHome";
import AdminMBA from "../AdminMBA/AdminMBA";
import AdminProfesores from "../AdminProfesores/AdminProfesores";
import AdminFooter from "../AdminFooter/AdminFooter";
import AdminMBAPage from "../AdminMBA/AdminMBAPage";
import AdminDiplomadosPage from "../AdminDiplomados/AdminDiplomadosPage";
import AdminCursosPage from "../AdminCursos/AdminCursosPage";
import AdminTalleresPage from "../AdminCursos/AdminTalleresPage";
import AdminEventos from "../AdminEventos/AdminEventos";
import AdminPerfil from "../AdminPerfil/AdminPerfil";
import { useAuth } from "../context/context";

const Central = ({ usuarios, isAdmin, openModal }) => {
  const { nombre } = useAuth();
  return (
    <div className="central">
      <Switch>
        <Route exact path="/admin">
          <div className="default-autoadmin">
            <h1>¡Bienvenido al autoadmin {nombre}!</h1>
            <img src={logoinQba} alt="Logo inQba" />
          </div>
        </Route>
        <Route exact path="/admin/home">
          <AdminHome />
        </Route>
        <Route exact path="/admin/perfil">
          <AdminPerfil
            isAdmin={isAdmin}
            usuarios={usuarios}
            openModal={openModal}
          />
        </Route>
        <Route path="/admin/diplomados" exact>
          <AdminDiplomados />
        </Route>
        <Route path="/admin/diplomados/:idDiplomado" exact>
          <AdminDiplomadosPage />
        </Route>
        <Route path="/admin/cursos" exact>
          <AdminCursos />
        </Route>
        <Route path="/admin/cursos/:idCurso" exact>
          <AdminCursosPage />
        </Route>
        <Route path="/admin/talleres/:idCurso" exact>
          <AdminTalleresPage />
        </Route>
        <Route path="/admin/posgrados" exact>
          <AdminMBA />
        </Route>
        <Route path="/admin/posgrados/:idMBA" exact>
          <AdminMBAPage />
        </Route>
        <Route path="/admin/eventos" exact>
          <AdminEventos />
        </Route>
        <Route path="/admin/profesores" exact>
          <AdminProfesores />
        </Route>
        <Route path="/admin/contacto">
          <AdminContacto />
        </Route>
        <Route path="/admin/footer">
          <AdminFooter />
        </Route>
      </Switch>
    </div>
  );
};
export default Central;
