import React from "react";
const colorVerde = "#ABC60D";
const PressIcon = ({
  color = colorVerde,
  width = "29",
  height = "29",
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.25 3.5H26.875V17.25C26.875 18.7688 25.6438 20 24.125 20H15.875V21.9715L22.0824 25.6959L20.6676 28.0541L15.875 25.1785V28.25H13.125V25.1785L8.33243 28.0541L6.91757 25.6959L13.125 21.9715V20H4.875C3.35622 20 2.125 18.7688 2.125 17.25V3.5H0.75V0.75H28.25V3.5ZM4.875 3.5V17.25H24.125V3.5H4.875ZM9 14.5H11.75V9H9V14.5ZM15.875 14.5H13.125V6.25H15.875V14.5ZM17.25 14.5H20V10.375H17.25V14.5Z"
        fill={color}
      />
    </svg>
  );
};

export default PressIcon;
