import { mProfesor } from "../models/mProfesor";
import FirestoreService from "./firestore.service";

export default class ProfesoresService extends FirestoreService {
  constructor() {
    super();
    this.collectionRef = this.fs
      .collection("colecciones")
      .doc("admin")
      .collection("profesores");
  }

  createProfesor(profesor = mProfesor.init()) {
    return this.collectionRef.add(profesor);
  }

  updateProfesor(id, profesor = mProfesor.init()) {
    return this.collectionRef.doc(id).set(profesor);
  }

  deleteProfesor(id) {
    return this.collectionRef.doc(id).delete();
  }
}
