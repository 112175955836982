import React from "react";
import { NavLink } from "react-router-dom";

import classnames from "classnames";

const lis = [
  { nombre: "Home", link: "/", exact: true },
  { nombre: "Diplomados", link: "/diplomados", exact: true },
  { nombre: "InCompany", link: "/cursos", exact: true },
  { nombre: <b>Posgrados</b>, link: "/posgrados", exact: true },
  { nombre: "Eventos", link: "/eventos", exact: true },
  // { nombre: "Programas In.company", link: "/in-company", exact: true },
  { nombre: "Profesores", link: "/profesores", exact: true },
  { nombre: "Contacto", link: "/contacto", exact: true },
];

const validateRenderedTab = (elemento, routesData) => {
  const { eventos, mbas, cursos, diplomados, profesores } = routesData;

  if (
    // Tomamos cursos como base porque es el ultimo que se fetchea
    (!cursos && elemento.link !== "/" && elemento.link !== "/contacto") ||
    (elemento.link === "/eventos" && eventos?.coleccion?.length === 0) ||
    (elemento.link === "/posgrados" && mbas?.mbas?.length === 0) ||
    (elemento.link === "/cursos" &&
      cursos?.cursos?.length === 0 &&
      cursos?.talleres?.length === 0) ||
    (elemento.link === "/profesores" && profesores?.length === 0) ||
    (elemento.link === "/diplomados" && diplomados?.diplomados?.length === 0)
  )
    return true;
  return false;
};

const LisHeaderHorizontal = ({ menuTransparent, routesData }) => {
  return (
    <ul>
      {lis.map((elemento, index) => {
        return validateRenderedTab(elemento, routesData) ? null : (
          <li key={index}>
            <NavLink
              exact={elemento.exact}
              to={elemento?.link}
              className={classnames({
                gris: !menuTransparent,
                mba: elemento.link === "/posgrados",
              })}
            >
              {elemento?.nombre}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

const LisHeaderVertical = ({ closeMenu, routesData }) => {
  return (
    <ul>
      {lis.map((elemento, index) => {
        return validateRenderedTab(elemento, routesData) ? null : (
          <li key={index} onClick={closeMenu}>
            <NavLink to={`${elemento?.link}`}>{elemento?.nombre}</NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export { LisHeaderHorizontal, LisHeaderVertical };
