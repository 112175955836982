import { useQuery } from "react-query";
import { firestore } from "../../firebase";
import fetchCollection from "../../Utils/fetchCollection";

const useCurso = (key) => {
  return useQuery(["getCurso", key], () =>
    firestore
      .collection("colecciones")
      .doc("admin")
      .collection("cursosCollection")
      .where("path", "==", key)
      .get()
      .then(async (docs) => {
        const cursos = [];
        docs.forEach((d) => cursos.push({ id: d.id, ...d.data() }));
        if (!cursos[0]) return { notFound: true };

        const docData = cursos[0];
        const profesores = await fetchCollection(
          docData.profesores,
          "profesores"
        );
        const otrosCursos = [
          ...(await fetchCollection(
            docData.otros.coleccion,
            "cursosCollection"
          )),
          ...(await fetchCollection(
            docData.otros.coleccion,
            "tallerescollection"
          )),
        ].filter((x) => x);

        return { ...docData, profesores, otrosCursos };
      })
  );
};

export default useCurso;
