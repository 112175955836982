import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { auth } from "../../../firebase";
import { AuthContext } from "../context";

const AuthState = ({ children }) => {
  const [{ nombre, correo, isAuthenticated, uid }, setAuthState] = useState({
    nombre: "",
    correo: "",
    isAuthenticated: false,
    uid: "",
  });
  const initialMountRef = useRef(true);
  const queryClient = useQueryClient();

  const triggerQuery = useMutation(
    async (user) =>
      await setAuthState(() => ({
        isAuthenticated: true,
        correo: user?.email,
        nombre: user?.displayName,
        uid: user?.uid,
      })),
    {
      onSuccess: () => queryClient.refetchQueries("adminQuery"),
    }
  );

  useEffect(() => {
    if (initialMountRef.current) {
      initialMountRef.current = false;
      auth.onAuthStateChanged((user) => {
        user && !isAuthenticated
          ? triggerQuery.mutate(user)
          : setAuthState((state) => ({ ...state, isAuthenticated: false }));
      });
    }
  }, [isAuthenticated, triggerQuery]);

  return (
    <AuthContext.Provider
      value={{
        nombre,
        isAuthenticated,
        correo,
        uid,
        setAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
