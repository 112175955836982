import React from "react";

import { BotonAgregar, CommonTextInput, ManejarCambios } from "./misc";

const CollectionTestimonios = ({
  className,
  collection,
  onChangeInput,
  onDeleteTestimonio,
  onAddTestimonio,
  onDiscardChanges,
  onSave,
  disableSave,
}) => {
  return (
    <div className={`collection-testimonios ${className}`}>
      {collection.map((data, index) => (
        <div key={index} className={`card`}>
          <span className="headline3">Testimonio {index + 1}</span>
          <CommonTextInput
            onChange={(e) => onChangeInput(e, index)}
            value={data.nombre}
            name="nombre"
            placeholder={"Nombre y apellido"}
            trashActivo
            onDelete={(e) => onDeleteTestimonio(e, index)}
          />
          <CommonTextInput
            onChange={(e) => onChangeInput(e, index)}
            value={data.profesion}
            name="profesion"
            placeholder={"Profesión"}
          />
          <textarea
            className="card__textarea"
            rows="5"
            placeholder="Testimonio"
            value={data.testimonio}
            name="testimonio"
            onChange={(e) => onChangeInput(e, index)}
          ></textarea>
          <ManejarCambios
            noMarginBottom
            disableSave={disableSave}
            saveClick={onSave}
            discardClick={onDiscardChanges}
          />
        </div>
      ))}
      <BotonAgregar
        onClick={onAddTestimonio}
        disabled={disableSave}
        className="collection-testimonios__add"
      />
    </div>
  );
};

export default CollectionTestimonios;
