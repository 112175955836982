import React from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useModal from "react-hooks-use-modal";

import { auth } from "../../firebase";
import BarraLateral from "../BarraLateral/BarraLateral";
import Central from "../Central/Central";
import AdminModal from "../AdminModal/AdminModal";
import { useAdminModal } from "../context/context";
import { ALTA } from "../context/state/AdminModalState";

const AdminContainer = ({ isAdmin, usuarios }) => {
  const { setModalState } = useAdminModal();
  const { addToast } = useToasts();
  const [Modal, open, close] = useModal();

  const logOut = (e) => {
    e.persist();
    auth
      .signOut()
      .then(() => {
        addToast("Sesion finalizada", { appearance: "success" });
      })
      .catch(() => {
        addToast("Error al cerrar sesión, intente nuevamente", {
          appearance: "error",
        });
      });
  };

  const openAddUser = (e) => {
    e.persist();
    setModalState(() => ({ type: ALTA }));
    open();
  };

  return (
    <main id="contenedor-admin">
      <BarraLateral />
      <div className="wrap-vertical">
        <div className="barra-superior">
          <div className="dropdown">
            <div className="person-icon"></div>
            <ul className="dropdown-content">
              <li>
                <Link to="/admin/perfil" className="boton-menu">
                  Perfil
                </Link>
              </li>
              {isAdmin && (
                <li>
                  <button onClick={openAddUser}>Agregar usuario</button>
                </li>
              )}
              <div></div>
              <li>
                <button onClick={logOut}>Cerrar sesión</button>
              </li>
            </ul>
          </div>
        </div>
        <Central usuarios={usuarios} isAdmin={isAdmin} openModal={open} />
      </div>
      {isAdmin && (
        <Modal>
          <AdminModal close={close} />
        </Modal>
      )}
    </main>
  );
};

export default AdminContainer;
