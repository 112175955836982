import { mMBA } from "../models/mMBA";
import FirestoreService from "./firestore.service";

export default class MBAService extends FirestoreService {
  constructor() {
    super();

    this.collectionRef = this.fs
      .collection("colecciones")
      .doc("admin")
      .collection("mbaCollection");
    this.pageRef = this.fs.collection("paginas").doc("mba");
  }

  updatePageHeader(page) {
    return this.pageRef.set(page, { merge: true });
  }

  deleteMBA(mbas, id) {
    return Promise.all([
      this.pageRef.set({ mbas }, { merge: true }),
      this.collectionRef.doc(id).delete(),
    ]);
  }

  createMBA(newMBAS) {
    return this.collectionRef
      .add(mMBA.init())
      .then((doc) => doc.id)
      .then((fk) =>
        this.pageRef.set(
          {
            mbas: [...newMBAS, { fk, path: "", nombre: "", date: new Date() }],
          },
          { merge: true }
        )
      );
  }

  updatePageMBAs(mbas, id, newPath, newShortName) {
    return Promise.all([
      this.collectionRef
        .doc(id)
        .set({ path: newPath, nombreCorto: newShortName }, { merge: true }),
      this.pageRef.set({ mbas }, { merge: true }),
    ]);
  }

  createProfesorMBAPage(profesores) {
    return this.pageRef.set(
      { profesores: [...profesores, ""] },
      { merge: true }
    );
  }

  updateProfesorMBAPage(profesores) {
    return this.pageRef.set({ profesores }, { merge: true });
  }

  // Fetches para cada MBA
  updateMBAHeader(id, data) {
    return this.collectionRef.doc(id).set(mMBA.header(data), { merge: true });
  }

  updateMBAProfesores(id, data) {
    return this.collectionRef
      .doc(id)
      .set({ profesores: data.profesores }, { merge: true });
  }

  updateMBAOtros(id, data) {
    return this.collectionRef.doc(id).set(data, { merge: true });
  }

  updateMBAEnfoques(id, enfoques) {
    return this.collectionRef.doc(id).set({ enfoques }, { merge: true });
  }
}
