import React, { useMemo, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";

import {
  LineBreak,
  CollectionListSelect,
  CollectionTestimonios,
  BotonAgregar,
} from "../../components/admin/misc";
import { usePages, useCollection } from "../context/context";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import HomeService from "../lib/services/home.service";
import CategoriasCard from "./CategoriasCard";

import "./AdminHome.scss";

const AdminHome = () => {
  const {
    mbaCollection,
    profesores,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  } = useCollection();
  const { home, fetchPage, fetchTriggered, setFetchTriggered } = usePages();
  const [stateHome, setStateHome] = useState(_.cloneDeep(home));
  const [isFetching, setFetching] = useState(false);
  const { addToast } = useToasts();
  const homeService = useMemo(() => new HomeService(), []);
  const mbaPromiseHandler = usePromiseHandler("MBA", setFetching);
  const profesoresPromiseHandler = usePromiseHandler("Profesor", setFetching);
  const categoriasPromiseHandler = usePromiseHandler("Categoría", setFetching);
  const testimonioPromiseHandler = usePromiseHandler("Testimonio", setFetching);

  const onAddMBA = (e) => {
    e.persist();
    setStateHome((state) => ({
      ...state,
      mbas: [...state.mbas, ""],
    }));
  };

  const onChangeMBA = (e, index) => {
    e.persist();
    setStateHome((state) => {
      const newMBAS = [...state.mbas];
      newMBAS[index] = e.target.value;
      return { ...state, mbas: newMBAS };
    });
  };

  const onDeleteMBA = (e, index) => {
    e.persist();
    setStateHome((state) => {
      const newMBAS = [...state.mbas];
      newMBAS.splice(index, 1);
      return { ...state, mbas: newMBAS };
    });
  };

  const onDiscardMBA = (e) => {
    e.persist();
    setStateHome((state) => ({ ...state, mbas: home.mbas }));
  };

  const onSaveMBA = (e) => {
    e.persist();
    const newMBAS = _.cloneDeep(stateHome.mbas);
    mbaPromiseHandler(
      homeService.updatePageSection({ mbas: newMBAS }),
      "actualizado"
    );
  };

  const onAddProfesor = (e) => {
    e.persist();
    if (stateHome.profesores.length === 3) {
      return addToast("Solo se permiten tres profesores en el home.", {
        appearance: "warning",
      });
    }
    setStateHome((state) => ({
      ...state,
      profesores: [...state.profesores, ""],
    }));
  };

  const onChangeProfesor = (e, index) => {
    e.persist();
    setStateHome((state) => {
      const newProfesores = [...state.profesores];
      newProfesores[index] = e.target.value;
      return { ...state, profesores: newProfesores };
    });
  };

  const onDeleteProfesor = (e, index) => {
    e.persist();
    setStateHome((state) => {
      const newProfesores = [...state.profesores];
      newProfesores.splice(index, 1);
      return { ...state, profesores: newProfesores };
    });
  };

  const onDiscardProfesor = (e) => {
    e.persist();
    setStateHome((state) => ({ ...state, profesores: home.profesores }));
  };

  const onSaveProfesor = (e) => {
    e.persist();
    const newProfesores = _.cloneDeep(stateHome.profesores);
    profesoresPromiseHandler(
      homeService.updatePageSection({ profesores: newProfesores }),
      "actualizado"
    );
  };

  const onChangeTestimonio = (e, index) => {
    e.persist();
    setStateHome((state) => {
      const newTestimonio = [...state.testimonios];
      newTestimonio[index][e.target.name] = e.target.value;
      return { ...state, testimonios: newTestimonio };
    });
  };

  const onAddTestimonio = (e) => {
    e.persist();
    const newTestimonios = [...stateHome.testimonios];
    newTestimonios.push({ nombre: "", profesion: "", testimonio: "" });
    testimonioPromiseHandler(
      homeService.updatePageSection({ testimonios: newTestimonios }),
      "creado"
    );
  };

  const onDeleteTestimonio = (e, index) => {
    e.persist();
    const newTestimonios = [...stateHome.testimonios];
    newTestimonios.splice(index, 1);
    testimonioPromiseHandler(
      homeService.updatePageSection({ testimonios: newTestimonios }),
      "eliminado"
    );
  };

  const onDiscardTestimonio = (e, index) => {
    e.persist();
    setStateHome((state) => {
      const newTestimonios = [...state.testimonios];
      const newTestimonio = _.cloneDeep(home.testimonios[index]);
      newTestimonios[index] = { ...newTestimonio };
      return { ...state, testimonios: newTestimonios };
    });
  };

  const onSaveTestimonio = (e, index) => {
    e.persist();
    const newTestimonios = [...stateHome.testimonios];
    testimonioPromiseHandler(
      homeService.updatePageSection({ testimonios: newTestimonios }),
      "eliminado"
    );
  };

  const onAddCategoria = (e) => {
    e.persist();

    categoriasPromiseHandler(
      homeService.updatePageSection({
        categorias: [...stateHome.categorias, { nombre: "", imagen: "" }],
      }),
      "creada"
    );
  };

  const onDeleteCategoria = (index) => {
    const newCategorias = [...stateHome.categorias];
    newCategorias.splice(index, 1);

    categoriasPromiseHandler(
      homeService.updatePageSection({ categorias: newCategorias }),
      "eliminada"
    );
  };

  const onDiscardCategoria = (e, index) => {
    e.persist();
    const oldCategorias = [...home.categorias];
    const newCategorias = [...stateHome.categorias];
    newCategorias[index] = _.cloneDeep(oldCategorias[index]);

    setStateHome((state) => ({ ...state, categorias: newCategorias }));
  };

  const onSaveCategoria = (index, categoria) => {
    const newCategorias = [...stateHome.categorias];
    newCategorias[index] = categoria;
    categoriasPromiseHandler(
      homeService.updatePageSection({ categorias: newCategorias }),
      "actualizada"
    );
  };

  useEffect(() => {
    if (!mbaCollection.initialFetch) {
      fetchCollection("mbaCollection");
    }
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
    if (!home.initialFetch) {
      fetchPage("home");
    }
    if (fetchTriggered || fetchCollectionTriggered) {
      setStateHome(() => _.cloneDeep(home));
      setFetchTriggered(() => false);
      setFetchCollectionTriggered(() => false);
    }
  }, [
    mbaCollection.initialFetch,
    profesores.initialFetch,
    fetchCollection,
    fetchPage,
    setStateHome,
    home,
    fetchTriggered,
    setFetchTriggered,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <div className="admin-home">
      <span className="admin-home__headline headline3">Home</span>
      <CollectionListSelect
        title="Posgrados"
        className="admin-home__mbas"
        disableSave={isFetching}
        onClickAddItem={onAddMBA}
        onChange={onChangeMBA}
        onDelete={onDeleteMBA}
        onSaveChanges={onSaveMBA}
        onDiscardChanges={onDiscardMBA}
        values={stateHome.mbas}
        options={mbaCollection.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
      />
      <LineBreak />
      <section className="admin-home__categorias">
        <span className="admin-home__categorias--title headline3">
          Categorías
        </span>
        {stateHome.categorias.map((data, index) => (
          <CategoriasCard
            key={index}
            id={index}
            data={data}
            disableSave={isFetching}
            onDiscard={onDiscardCategoria}
            onDelete={onDeleteCategoria}
            onSave={onSaveCategoria}
          />
        ))}
        <BotonAgregar
          onClick={onAddCategoria}
          className="admin-home__categorias--add"
        />
      </section>
      <LineBreak />
      <CollectionListSelect
        title="Profesores"
        disableSave={isFetching}
        onClickAddItem={onAddProfesor}
        onChange={onChangeProfesor}
        onDelete={onDeleteProfesor}
        onSaveChanges={onSaveProfesor}
        onDiscardChanges={onDiscardProfesor}
        values={stateHome.profesores}
        options={profesores.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
      />
      <LineBreak />
      <span className="admin-home__categorias--title headline3">
        Testimonios
      </span>
      <CollectionTestimonios
        className="admin-home__testimonios"
        disableSave={isFetching}
        collection={stateHome.testimonios}
        onChangeInput={onChangeTestimonio}
        onAddTestimonio={onAddTestimonio}
        onDeleteTestimonio={onDeleteTestimonio}
        onDiscardChanges={onDiscardTestimonio}
        onSave={onSaveTestimonio}
      />
    </div>
  );
};
export default AdminHome;
