import React from "react";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";

import { auth } from "../../firebase";
import { useAdminModal, useAuth } from "../context/context";
import {
  ALTA_ADMIN,
  BAJA,
  BAJA_ADMIN,
  CHANGE_PASSWORD,
} from "../context/state/AdminModalState";

import "./AdminPerfil.scss";

const AdminPerfil = ({ isAdmin, usuarios, openModal }) => {
  const currentUser = useAuth();
  const { setModalState } = useAdminModal();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const deleteUser = (uidToRemove) =>
    fetch("/.netlify/functions/auth", {
      method: "POST",
      body: JSON.stringify({
        action: "deleteUser",
        payload: {
          uid: currentUser.uid,
          uidToRemove,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.users) {
            queryClient.setQueryData(["adminQuery", currentUser.uid], {
              admin: true,
              usuarios: res.users,
            });
          }
          addToast(
            !res.error
              ? "Usuario eliminado"
              : res.error === "user/non-admin"
              ? "Error: no tienes privilegios para eliminar usuarios"
              : res.error === "user/deleting-super"
              ? "Error: no tienes privilegios para eliminar a un superusuario"
              : "",
            { appearance: !res.error ? "success" : "error" }
          );
        }
      })
      .catch((e) => {
        addToast("Error al eliminar, intente más tarde", {
          appearance: "warning",
        });
      });

  const removeAdmin = (uidToRemove, adminStatus) =>
    fetch("/.netlify/functions/auth", {
      method: "POST",
      body: JSON.stringify({
        action: "modifyAdmin",
        payload: {
          uid: currentUser.uid,
          uidToRemove,
          admin: adminStatus,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          if (res.users) {
            queryClient.setQueryData(["adminQuery", currentUser.uid], {
              admin: true,
              usuarios: res.users,
            });
          }
          addToast(
            !res.error
              ? `Admin ${!adminStatus ? "removido" : "agregado"}`
              : res.error === "user/non-admin"
              ? "Error: no tienes privilegios para alterar admin"
              : res.error === "user/deleting-super"
              ? "Error: no tienes privilegios para alterar admin a un superusuario"
              : "",
            { appearance: !res.error ? "success" : "error" }
          );
        }
      })
      .catch((e) => {
        console.log(e);

        addToast("Error al remover admin, intente más tarde", {
          appearance: "warning",
        });
      });

  const onClickRemoveAdmin = (e, uidToRemove, adminStatus) => {
    e.persist();

    setModalState(() => ({
      type: !adminStatus ? ALTA_ADMIN : BAJA_ADMIN,
      callback: () => removeAdmin(uidToRemove, !adminStatus),
    }));

    openModal();
  };

  const onClickDeleteUser = (e, uidToRemove) => {
    e.persist();

    setModalState(() => ({
      type: BAJA,
      callback: () => deleteUser(uidToRemove),
    }));

    openModal();
  };

  const onClickChangePassword = (e) => {
    e.persist();

    setModalState((x) => ({ ...x, type: CHANGE_PASSWORD }));
    openModal();
  };

  const logOut = (e) => {
    e.persist();
    auth
      .signOut()
      .then(() => {
        addToast("Sesion finalizada", { appearance: "success" });
      })
      .catch(() => {
        addToast("Error al cerrar sesión, intente nuevamente", {
          appearance: "error",
        });
      });
  };

  return (
    <main className="admin-perfil">
      <section className="admin-perfil__card">
        <span className="admin-perfil__title">
          {!isAdmin ? "Perfil:" : "Perfil Administrativo:"}
        </span>
        <div className="admin-perfil__field mb">
          <span className="admin-perfil__fieldLabel">Nombre:</span>
          <span className="admin-perfil__fieldContent">
            {currentUser.nombre}
          </span>
        </div>
        <div className="admin-perfil__field">
          <span className="admin-perfil__fieldLabel">Correo electrónico:</span>
          <span className="admin-perfil__fieldContent">
            {currentUser.correo}
          </span>
        </div>
        <div className="admin-perfil__buttons">
          <button
            className="admin-perfil__button"
            onClick={onClickChangePassword}
          >
            CAMBIAR CONTRASEÑA
          </button>
          <button onClick={logOut} className="admin-perfil__button --white">
            CERRAR SESIÓN
          </button>
        </div>
      </section>
      {isAdmin && (
        <>
          <span className="admin-perfil__title --standalone">
            Lista de usuarios:
          </span>
          {usuarios
            ?.filter(
              (filteredUser) =>
                !filteredUser.super && filteredUser.id !== currentUser.uid
            )
            ?.map(({ nombre, correo, id, admin }, index) => (
              <section className="admin-perfil__card" key={index}>
                <div className="admin-perfil__field mb">
                  <span className="admin-perfil__fieldLabel">Nombre:</span>
                  <span className="admin-perfil__fieldContent">{nombre}</span>
                </div>
                <div className="admin-perfil__field">
                  <span className="admin-perfil__fieldLabel">
                    Correo electrónico:
                  </span>
                  <span className="admin-perfil__fieldContent">{correo}</span>
                </div>
                <div className="admin-perfil__buttons">
                  <button
                    className="admin-perfil__button"
                    onClick={(e) => onClickRemoveAdmin(e, id, admin)}
                  >
                    {admin ? "REMOVER ADMIN" : "HACER ADMIN"}
                  </button>
                  <button
                    className="admin-perfil__button --transparent"
                    onClick={(e) => onClickDeleteUser(e, id)}
                  >
                    Eliminar
                  </button>
                </div>
              </section>
            ))}
        </>
      )}
    </main>
  );
};

export default AdminPerfil;
