import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import {
  CollectionListSelect,
  LineBreak,
  CollectionItemHeader,
  CollectionItemBulletList,
  CollectionTitle,
} from "../../components/admin/misc";
import { useCollection } from "../context/context";
import { mMBA } from "../lib/models/mMBA";
import usePromiseHandler from "../lib/hooks/usePromiseHandler";
import useInputChange from "../lib/hooks/useInputChange";
import CursosService from "../lib/services/cursos.service";

import "./AdminCursos.scss";

const AdminCursosPage = () => {
  const { idCurso } = useParams();
  const {
    cursosCollection,
    talleresCollection,
    profesores,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  } = useCollection();
  const [stateCurso, setStateCurso] = useState(
    () =>
      cursosCollection.collection.find((x) => x?.path === idCurso) ||
      mMBA.init()
  );
  const [isFetching, setFetching] = useState(false);
  const cursosService = useMemo(() => new CursosService(), []);
  const currentCurso = useMemo(
    () => cursosCollection.collection.find((x) => x?.path === idCurso),
    [cursosCollection.collection, idCurso]
  );
  const cursoPromiseHandler = usePromiseHandler("Curso", setFetching);
  const profesorPromiseHandler = usePromiseHandler("Profesor", setFetching);
  const enfoquePromiseHandler = usePromiseHandler("Enfoque", setFetching);
  const handleInputChange = useInputChange(setStateCurso);

  const onSaveHeaderChanges = (e) => {
    e.persist();
    cursoPromiseHandler(
      cursosService.updateCursoHeader(currentCurso.id, stateCurso),
      "actualizado"
    );
  };

  const onDiscardHeaderChanges = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      ...mMBA.header(_.cloneDeep(currentCurso)),
    }));
  };

  const onAddProfesor = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      profesores: [...state.profesores, ""],
    }));
  };

  const onChangeProfesor = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newProfesores = [...state.profesores];
      newProfesores[index] = e.target.value;
      return { ...state, profesores: newProfesores };
    });
  };

  const onDeleteProfesor = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newProfesores = [...state.profesores];
      newProfesores.splice(index, 1);
      return { ...state, profesores: newProfesores };
    });
  };

  const onDiscardProfesor = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      profesores: currentCurso.profesores || [],
    }));
  };

  const onSaveProfesor = (e) => {
    e.persist();
    profesorPromiseHandler(
      cursosService.updateCursoProfesores(currentCurso.id, stateCurso),
      "actualizado"
    );
  };

  const onAddOtroMBA = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      otros: { ...state.otros, coleccion: [...state.otros.coleccion, ""] },
    }));
  };

  const onChangeOtroMBA = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newColeccion = [...state.otros.coleccion];
      newColeccion[index] = e.target.value;
      return { ...state, otros: { ...state.otros, coleccion: newColeccion } };
    });
  };

  const onDeleteOtroMBA = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newColeccion = [...state.otros.coleccion];
      newColeccion.splice(index, 1);
      return { ...state, otros: { ...state.otros, coleccion: newColeccion } };
    });
  };

  const onDiscardOtroMBA = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      otros: _.cloneDeep(
        currentCurso?.otros || {
          titulo: "",
          descripcion: "",
          coleccion: [],
        }
      ),
    }));
  };

  const onSaveOtroMBA = (e) => {
    e.persist();
    cursoPromiseHandler(
      cursosService.updateCursoOtros(currentCurso.id, {
        otros: {
          ...currentCurso.otros,
          coleccion: stateCurso.otros.coleccion,
        },
      }),
      "actualizado"
    );
  };

  const onChangeInputOtrosTitle = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      otros: { ...state.otros, [e.target.name]: e.target.value },
    }));
  };

  const onDiscardInputOtrosTitle = (e) => {
    e.persist();
    setStateCurso((state) => ({
      ...state,
      otros: {
        ...state.otros,
        titulo: currentCurso.otros.titulo,
        descripcion: currentCurso.otros.descripcion,
      },
    }));
  };

  const onSaveInputOtrosTitle = (e) => {
    e.persist();
    cursoPromiseHandler(
      cursosService.updateCursoOtros(currentCurso.id, {
        otros: {
          ...currentCurso.otros,
          titulo: stateCurso.otros.titulo,
          descripcion: stateCurso.otros.descripcion,
        },
      }),
      "actualizado"
    );
  };

  const onChangeCollectionTitle = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.titulo = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onChangeBulletSubtitle = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].subtitulo = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onClickAddBullet = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista.push("");
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onChangeBulletItem = (e, index, indexCollection) => {
    e.persist();
    setStateCurso((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista[indexCollection] = e.target.value;
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onDeleteBulletItem = (e, index, indexCollection) => {
    e.persist();
    setStateCurso((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index].lista.splice(indexCollection, 1);
      return { ...state, enfoques: newEnfoques };
    });
  };

  const onClickAddBulletSection = (e) => {
    e.persist();
    const newEnfoques = _.cloneDeep(stateCurso.enfoques);
    newEnfoques.coleccion.push(mMBA.initEnfoque());
    enfoquePromiseHandler(
      cursosService.updateCursoEnfoques(currentCurso.id, newEnfoques),
      "creado"
    );
  };

  const onClickDeleteBulletSection = (e, index) => {
    e.persist();
    const newEnfoques = _.cloneDeep(stateCurso.enfoques);
    newEnfoques.coleccion.splice(index, 1);
    enfoquePromiseHandler(
      cursosService.updateCursoEnfoques(currentCurso.id, newEnfoques),
      "eliminado"
    );
  };

  const onSaveBulletClick = (e, index) => {
    e.persist();
    const enfoquesToSave = _.cloneDeep(currentCurso.enfoques);
    enfoquesToSave.titulo = stateCurso.enfoques.titulo;
    enfoquesToSave.coleccion[index] = _.cloneDeep(
      stateCurso.enfoques.coleccion[index]
    );
    enfoquePromiseHandler(
      cursosService.updateCursoEnfoques(currentCurso.id, enfoquesToSave),
      "actualizado"
    );
  };

  const onDiscardBulletClick = (e, index) => {
    e.persist();
    setStateCurso((state) => {
      const newEnfoques = _.cloneDeep(state.enfoques);
      newEnfoques.coleccion[index] = _.cloneDeep(
        currentCurso.enfoques.coleccion[index]
      );
      return { ...state, enfoques: newEnfoques };
    });
  };

  useEffect(() => {
    if (!cursosCollection.initialFetch) {
      fetchCollection("cursosCollection");
    }
    if (!talleresCollection.initialFetch) {
      fetchCollection("talleresCollection");
    }
    if (!profesores.initialFetch) {
      fetchCollection("profesores");
    }
    if (fetchCollectionTriggered) {
      setStateCurso(
        () =>
          cursosCollection.collection.find((x) => x?.path === idCurso) ||
          mMBA.init()
      );
      setFetchCollectionTriggered(() => false);
    }
  }, [
    cursosCollection.collection,
    talleresCollection.initialFetch,
    cursosCollection.initialFetch,
    profesores.initialFetch,
    setStateCurso,
    idCurso,
    fetchCollection,
    fetchCollectionTriggered,
    setFetchCollectionTriggered,
  ]);

  return (
    <section className="diplomados-page collection-item">
      <CollectionItemHeader
        values={mMBA.header(stateCurso)}
        placeHolderNombre="Nombre del diplomado"
        onChangeInput={handleInputChange}
        disableSave={isFetching}
        onSaveChanges={onSaveHeaderChanges}
        onDiscardChanges={onDiscardHeaderChanges}
      />
      <LineBreak />
      <CollectionItemBulletList
        placeholderTitle="Enfoques del curso"
        titleBullet="Enfoque"
        collectionTitle={stateCurso.enfoques.titulo}
        collection={stateCurso.enfoques.coleccion}
        disableSave={isFetching}
        onChangeTitle={onChangeCollectionTitle}
        onChangeSubtitle={onChangeBulletSubtitle}
        onClickAddBullet={onClickAddBullet}
        onClickAddSection={onClickAddBulletSection}
        onClickDeleteSection={onClickDeleteBulletSection}
        onChangeBullet={onChangeBulletItem}
        onDeleteBullet={onDeleteBulletItem}
        saveBulletClick={onSaveBulletClick}
        discardBulletClick={onDiscardBulletClick}
      />
      <LineBreak />
      <CollectionListSelect
        title="Profesores"
        disableSave={isFetching}
        onClickAddItem={onAddProfesor}
        onChange={onChangeProfesor}
        onDelete={onDeleteProfesor}
        onSaveChanges={onSaveProfesor}
        onDiscardChanges={onDiscardProfesor}
        values={stateCurso.profesores}
        options={profesores.collection}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
      />
      <LineBreak />
      <CollectionTitle
        data={{
          titulo: stateCurso.otros.titulo,
          descripcion: stateCurso.otros.descripcion,
        }}
        disableSave={isFetching}
        onChangeInput={onChangeInputOtrosTitle}
        onDiscardChanges={onDiscardInputOtrosTitle}
        onSave={onSaveInputOtrosTitle}
      />
      <CollectionListSelect
        title="Otros Cursos o Talleres"
        className="diplomados-page__listSelect"
        disableSave={isFetching}
        values={stateCurso.otros.coleccion}
        options={[
          ...cursosCollection.collection,
          ...talleresCollection.collection,
        ]}
        optionValueFn={(x) => x.id}
        optionDisplayFn={(x) => x.nombre}
        onClickAddItem={onAddOtroMBA}
        onChange={onChangeOtroMBA}
        onDelete={onDeleteOtroMBA}
        onSaveChanges={onSaveOtroMBA}
        onDiscardChanges={onDiscardOtroMBA}
      />
    </section>
  );
};

export default AdminCursosPage;
