import React from "react";

import { CommonTextInput, ManejarCambios } from "./misc";

const CollectionTitle = ({
  className,
  data,
  disableSave,
  onSave,
  onDiscardChanges,
  onChangeInput,
}) => {
  return (
    <div className={`card ${className}`}>
      <CommonTextInput
        onChange={onChangeInput}
        value={data.titulo}
        name="titulo"
        placeholder={"Título"}
      />
      <textarea
        className="card__textarea"
        rows="5"
        placeholder="Descripción"
        value={data.descripcion}
        name="descripcion"
        onChange={onChangeInput}
      ></textarea>
      <ManejarCambios
        noMarginBottom
        disableSave={disableSave}
        saveClick={onSave}
        discardClick={onDiscardChanges}
      />
    </div>
  );
};

export default CollectionTitle;
