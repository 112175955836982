import { useQuery } from "react-query";
import { firestore } from "../../firebase";

const useCursos = () => {
  return useQuery(
    "getCursos",
    () =>
      firestore
        .collection("paginas")
        .doc("cursos")
        .get()
        .then((doc) => {
          return doc.data();
        }),
    {
      retry: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
    }
  );
};

export default useCursos;
