import React from "react";
import AdminModalState from "../context/state/AdminModalState";
import AuthState from "../context/state/AuthState";
import CollectionState from "../context/state/CollectionState";
import PagesState from "../context/state/PagesState";

const AdminContextLayer = ({ children }) => {
  return (
    <AuthState>
      <PagesState>
        <CollectionState>
          <AdminModalState>{children}</AdminModalState>
        </CollectionState>
      </PagesState>
    </AuthState>
  );
};

export default AdminContextLayer;
