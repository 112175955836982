import React from "react";

import BotonAgregar from "./BotonAgregar";
import PageCollectionListItem from "./PageCollectionListItem";

const PageCollectionListSelect = ({
  items,
  disableSave,
  onChangeValue,
  onDeleteItem,
  onSaveItem,
  onDiscardItem,
  onClickAddItem,
  routerPathFn,
  routerPathError,
  getValueFn,
  className,
  withImage,
  onEditImage,
}) => {
  return (
    <section className={`${className} collection-item`}>
      <div className="collection-item__list">
        {items.map((data, index) => (
          <PageCollectionListItem
            key={index}
            index={index}
            itemData={data}
            disableSave={disableSave}
            onChangeValue={onChangeValue}
            onDeleteItem={onDeleteItem}
            onSaveItem={onSaveItem}
            onDiscardItem={onDiscardItem}
            routerPathFn={routerPathFn}
            routerPathError={routerPathError}
            getValueFn={getValueFn}
            withImage={withImage}
            onEditImage={onEditImage}
          />
        ))}
      </div>
      <BotonAgregar
        disableSave={disableSave}
        className="collection-item__add"
        onClick={onClickAddItem}
      />
    </section>
  );
};

export default PageCollectionListSelect;
