import React, { useState } from "react";
import { firestore } from "../../../firebase";
import { CollectionContext } from "../context";

const CollectionState = ({ children }) => {
  const [
    {
      mbaCollection,
      profesores,
      diplomadosCollection,
      cursosCollection,
      talleresCollection,
    },
    setCollection,
  ] = useState({
    mbaCollection: {
      collection: [],
    },
    profesores: {
      collection: [],
    },
    diplomadosCollection: {
      collection: [],
    },
    cursosCollection: {
      collection: [],
    },
    talleresCollection: {
      collection: [],
    },
  });

  const [fetchCollectionTriggered, setFetchCollectionTriggered] = useState(
    false
  );
  const [fetches, setFetches] = useState({
    home: false,
    mbaCollection: false,
    profesores: false,
    diplomadosCollection: false,
    cursosCollection: false,
    talleresCollection: false,
  });

  const fetchCollection = (key) => {
    firestore
      .collection("colecciones")
      .doc("admin")
      .collection(key)
      .onSnapshot((docs) => {
        const newCollection = [];
        docs.forEach((doc) =>
          newCollection.push({ id: doc.id, ...doc.data() })
        );
        setCollection((state) => ({
          ...state,
          [key]: { collection: newCollection },
        }));
        setFetchCollectionTriggered(() => true);
      });

    setFetches((state) => ({ ...state, [key]: true }));
  };

  return (
    <CollectionContext.Provider
      value={{
        fetchCollection,
        profesores: {
          ...profesores,
          initialFetch: fetches.profesores,
        },
        mbaCollection: {
          ...mbaCollection,
          initialFetch: fetches.mbaCollection,
        },
        diplomadosCollection: {
          ...diplomadosCollection,
          initialFetch: fetches.diplomadosCollection,
        },
        cursosCollection: {
          ...cursosCollection,
          initialFetch: fetches.cursosCollection,
        },
        talleresCollection: {
          ...talleresCollection,
          initialFetch: fetches.talleresCollection,
        },
        fetchCollectionTriggered,
        setFetchCollectionTriggered,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

export default CollectionState;
